<template>
  <li class="tree-item">
    <div
      class="tree-item-content"
      @mouseover="showActions"
      @mouseleave="hideActions"
      @click="toggle"
    >
      <span v-if="hasChildren" @click="toggle" class="toggle-icon">
        <img v-if="isOpen" src="@/images/next_n.png" alt="Collapse" style="width: 10px;" />
        <img v-else src="@/images/prev_n.png" alt="Expand" style="width: 10px;" />
      </span>
      <span v-if="!isEditing" >{{ department.deptName }}</span>
      <input
        v-if="isEditing"
        v-model="editName"
        @keyup.enter="saveEdit"
        @blur="cancelEdit"
      />

      <div class="tree-item-actions" :class="{ show: actionsVisible }">
        <img
          v-if="department.type === 'D' || department.type === 'W'"
          @click="addItem"
          src="@/images/add.png"
          class="tree-icon"
          alt="Add"
        />
        <img
          v-if="department.type === 'D'"
          @click="editItem"
          src="@/images/edit.png"
          class="tree-icon"
          alt="Edit"
        />
        <img
          v-if="department.type === 'D'"
          @click="deleteItem"
          src="@/images/minus.png"
          class="tree-icon"
          alt="Delete"
        />
      </div>
    </div>
    <ul v-if="isOpen && hasChildren" class="tree-children">
      <tree-item
        v-for="child in department.children"
        :key="child.deptId"
        :department="child"
        @select="$emit('select', $event)"
        @add-child="$emit('add-child', $event)"
        @edit-department="$emit('edit-department', $event)"
        @delete-department="$emit('delete-department', $event)"
      ></tree-item>
    </ul>
  </li>
</template>

<script>
import { ref, nextTick, onUnmounted, watch, computed } from 'vue';
import apiClient from '@/apiClient';

export default {
  props: {
    department: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false);
    const editName = ref(props.department.deptName);
    const originalName = ref(props.department.deptName);
    const isOpen = ref(false);
    const actionsVisible = ref(false);

    watch(
      () => props.department.deptName,
      (newName) => {
        if (!isEditing.value) {
          editName.value = newName;
          originalName.value = newName;
        }
      },
      { immediate: true }
    );

    const hasChildren = computed(() => props.department.children && props.department.children.length > 0);

    const selectDepartment = () => {
      emit('select', props.department);
    };

    const toggle = () => {
      isOpen.value = !isOpen.value;
      selectDepartment();
    };

    const editItem = () => {
      if (isEditing.value) {
        saveEdit();
      } else {
        startEdit();
      }
    };

    const startEdit = () => {
      isEditing.value = true;
      editName.value = props.department.deptName;
      originalName.value = props.department.deptName;
      nextTick(() => {
        const input = document.querySelector('input');
        if (input) input.focus();
      });
      document.addEventListener('click', handleClickOutside);
    };

    const saveEdit = async () => {
      if (editName.value.trim() !== originalName.value) {
        try {
          const response = await apiClient.post("/api/Department/admin-depart-edit", {
            DeptId: props.department.deptId,
            DeptName: editName.value.trim(),
            LoginUserId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            emit('edit-department', { deptId: props.department.deptId, deptName: editName.value.trim() });
            isOpen.value = true;
          }
        } catch (error) {
          console.error(`Error Dept Edit: ${error}`);
        }
      }
      isEditing.value = false;
      document.removeEventListener('click', handleClickOutside);
    };

    const cancelEdit = () => {
      isEditing.value = false;
      editName.value = originalName.value;
      document.removeEventListener('click', handleClickOutside);
    };

    const addItem = async () => {
      try {
        const response = await apiClient.post("/api/Department/admin-depart-add", {
          DeptName: '새 항목',
          ParentDeptId: props.department.deptId ?? 0,
          DeptCompanyId: props.department.deptCompanyId,
          DeptWorkplaceId: props.department.deptWorkplaceId,
          Level: props.department.level,
          Type: props.department.type,
          LoginUserId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          const returnData = response.data;

          const newDepartment = {
            deptId: returnData.department.DeptId,
            deptName: '새 항목',
            parentDeptId: returnData.department.ParentDeptId,
            deptCompanyId: returnData.department.DeptCompanyId,
            deptWorkplaceId: returnData.department.DeptWorkplaceId,
            level: returnData.department.Level,
            type: 'D',
          };

          emit('add-child', newDepartment);
          isOpen.value = true;
        }
      } catch (error) {
        console.error(`Error Dept Add: ${error}`);
      }
    };

    const deleteItem = async () => {
      try {
        const response = await apiClient.post("/api/Department/admin-depart-del", {
          DeptId: props.department.deptId,
          LoginUserId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          emit('delete-department', props.department);
        }
      } catch (error) {
        console.error(`Error Dept Delete: ${error}`);
      }
    };

    const handleClickOutside = (event) => {
      if (!event.target.closest('.tree-item-content')) {
        cancelEdit();
      }
    };

    const showActions = () => {
      actionsVisible.value = true;
    };

    const hideActions = () => {
      actionsVisible.value = false;
    };

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      isEditing,
      editName,
      originalName,
      isOpen,
      hasChildren,
      toggle,
      editItem,
      startEdit,
      saveEdit,
      addItem,
      deleteItem,
      actionsVisible,
      showActions,
      hideActions,
    };
  },
};
</script>

<style scoped>
.tree-item {
  list-style-type: none;
  margin: 5px 0;
  padding-left: 20px;
  position: relative;
}

.tree-item-content {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.tree-item-content:hover {
  background-color: #e3e3e3;
}

.tree-item-content span {
  margin-right: 10px;
}

.toggle-icon {
  margin-right: 10px;
  cursor: pointer;
}

.tree-item-actions {
  display: flex;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.tree-item-actions.show {
  opacity: 1;
}

.tree-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  transition: opacity 0.2s ease-in-out;
}

.tree-icon:hover {
  opacity: 0.7;
}

.tree-children {
  padding-left: 20px;
  margin-top: 5px;
}

input[type="text"] {
  font-size: 14px;
  padding: 2px 4px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
}
</style>
