<template>
  <div class="body_right">
    <div class="right_container">
      <!-- 입실 전 상태 -->
      <div class="right_title" style="display: none">
        <div class="title">공용시설 예약 상세보기</div>
        <div class="description">
          예약 정보를 조회하고 수정합니다.
          <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">예약정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option>전체보기</option>
                      <option>{회사}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option>전체보기</option>
                      <option>{사업장}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상품명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option>{상품명1}</option>
                      <option>{상품명2}</option>
                      <option>{상품명3}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>공용시설<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="공용시설 명을 입력해주세요"
                    value="인텔트 - 장바구니 관련의 건"
                  />
                </td>
              </tr>
              <tr>
                <th>예약시간<span class="text_red">*</span></th>
                <td><input class="w_250" type="datetime-local" /></td>
              </tr>
              <tr>
                <th>참석자</th>
                <td>
                  <div class="inner_td open_list">
                    <!-- '추가' 클릭 시 id="Modal" 나타남 -->
                    <button type="button">추가</button>
                    <!-- img 클릭 시 열림 -->
                    <ul class="attendee_add" style="display: none">
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                      <li>
                        <div>
                          <span>홍길동</span>
                          <span class="text_grey">/ 기획팀</span>
                        </div>
                        <a href="###">⨉</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">예약자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="reservationUserName"
                    ref="reservationUserName"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="phonePrefix">
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="tel"
                      class="w_55"
                      maxlength="4"
                      v-model="phoneMiddle"
                      @input="filterNumeric($event, 'phoneMiddle')"
                    />
                    -
                    <input
                      type="tel"
                      class="w_55"
                      maxlength="4"
                      v-model="phoneSuffix"
                      @input="filterNumeric($event, 'phoneSuffix')"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" class="on">저장</a>
            <a href="###">삭제</a>
            <a @click="goToPublicRoomReservationList">목록</a>
          </div>
        </article>
      </div>
      <!-- //입실 전 상태 -->

      <!-- 입실 후 상태 -->
      <div class="right_title">
        <div class="title">공용시설 예약 상세보기</div>
        <div class="description">공용시설 예약 정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">예약정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>예약일</th>
                <td>{{ this.reservationDate }}</td>
              </tr>
              <tr>
                <th>회사명</th>
                <td>{{ this.CompanyName }}</td>
              </tr>
              <tr>
                <th>사업장</th>
                <td>{{ this.WorkplaceName }}</td>
              </tr>
              <tr>
                <th>상품명</th>
                <td>{{ this.publicroomName }}</td>
              </tr>
              <tr>
                <th>공용시설명</th>
                <td>{{ this.publicName }}</td>
              </tr>
              <tr>
                <th>예약시간</th>
                <td>
                  {{ this.reservationDate }} ( {{ this.reservationDatetime }} )
                </td>
              </tr>
              <tr>
                <th>참석자</th>
                <td>
                  <div class="open_list attendee_list">
                    <div class="inner_td">
                      <!-- 참석자가 없는 경우 '참석자 없음'을 표시 -->
                      <span v-if="attendUser.length === 0">참석자 없음</span>

                      <!-- 참석자가 있을 경우 이름을 표시 -->
                      <span v-else>
                        {{ attendUser[0]?.attendUserName }}
                        <template v-if="attendUser.length > 1">
                          외 {{ attendUser.length - 1 }}명
                        </template>
                      </span>

                      <!-- 화살표 이미지: 참석자가 있을 때만 표시 -->
                      <img
                        src="@/images/down_icon_s.png"
                        v-if="attendUser.length > 0 && !isListOpen"
                        @click="isListOpen = !isListOpen"
                      />
                      <img
                        src="@/images/up_icon_s.png"
                        v-if="attendUser.length > 0 && isListOpen"
                        @click="isListOpen = !isListOpen"
                      />
                    </div>

                    <!-- 참석자 목록을 접었다 펼쳤다 할 수 있도록 v-if를 사용 -->
                    <ul v-if="isListOpen && attendUser.length > 0">
                      <li v-for="(user, index) in attendUser" :key="index">
                        {{ user.attendUserName }} / {{ user.attendDeptName }}
                        <!-- 마지막 참석자 뒤에는 콤마를 추가하지 않음 -->
                        <span v-if="index !== attendUser.length - 1">,</span>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>

              <tr>
                <th>상태</th>
                <td>
                  <b>
                    <span
                      v-if="this.reservationStatus === ''"
                      class="text_green"
                      >이용가능</span
                    >
                    <span
                      v-if="this.reservationStatus === 'I'"
                      class="text_blue"
                      >사용중</span
                    >
                    <span
                      v-else-if="this.reservationStatus === 'O'"
                      class="text_grey"
                      >만료</span
                    >
                    <span
                      v-else-if="this.reservationStatus === 'C'"
                      class="text_red"
                      >예약취소</span
                    >
                    <span
                      v-else-if="this.reservationStatus === 'N'"
                      class="text_orange"
                      >노쇼</span
                    >
                    <span
                      v-else-if="this.reservationStatus === 'M'"
                      class="text_lightgrey"
                      >양도 (양도인 : {{ this.transfereeName }})</span
                    >
                  </b>
                </td>
              </tr>
              <tr>
                <th>권한 시작일</th>
                <td>{{ this.reservationStartDtm }}</td>
              </tr>
              <tr>
                <th>권한 만료일</th>
                <td>
                  <span v-if="this.reservationEndDtm">{{
                    this.reservationEndDtm
                  }}</span>
                  <span v-else class="text_grey"><b>시스템 만료 처리</b></span>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">예약자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>예약자</th>
                <td>{{ this.reservationUserName }}</td>
              </tr>

              <tr>
                <th>휴대폰번호</th>
                <td>{{ this.reservationUserPhone }}</td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <!-- <a href="###">삭제</a> -->
            <a @click="goToPublicRoomReservationList">목록</a>
          </div>
        </article>
      </div>
      <!-- //입실 후 상태 -->
    </div>
  </div>
  <!-- modal -->
  <div id="Modal" class="d_pop" style="display: none">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">직원 선택</div>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select>
                      <option>이름</option>
                      <option>소속</option>
                      <option>직책</option>
                    </select>
                    <input
                      tyoe="text"
                      class="w_200"
                      placeholder="분류명을 입력해주세요"
                    />
                    <button type="button">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 7%" />
              <col style="width: 20%" />
              <col style="width: 15%" />
              <col style="width: 18%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                <th>이름</th>
                <th>소속</th>
                <th>직책</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>김유경</td>
                <td>기획팀</td>
                <td>대리</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>김유경</td>
                <td>기획팀</td>
                <td>대리</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>김유경</td>
                <td>기획팀</td>
                <td>대리</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>김유경</td>
                <td>기획팀</td>
                <td>대리</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>김유경</td>
                <td>기획팀</td>
                <td>대리</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a href="###"
              ><img src="@/images/front_n.png" alt="처음페이지"
            /></a>
            <a href="###"><img src="@/images/prev_n.png" alt="이전페이지" /></a>
            <a href="###" class="on">1</a>
            <a href="###">2</a>
            <a href="###">3</a>
            <a href="###"><img src="@/images/next_n.png" alt="다음페이지" /></a>
            <a href="###"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on">선택완료</button>
        <button type="button">취소</button>
      </div>
    </div>
  </div>
  <!-- //modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "ResrvationPublicroomStatusView",
  data() {
    return {
      reservationDate: "", // 예약일
      CompanyName: "", // 선택된 회사명
      WorkplaceName: "", // 선택된 사업장명
      CompanyId: 0, // 선택된 회사 ID
      WorkplaceId: 0, // 선택된 사업장 ID
      publicroomName: "", // 상품명
      publicName: "", // 공용시설명
      reservationDatetime: "", // 예약시간
      reservationStatus: "", // 상태
      reservationStartDtm: "", // 권한 시작일시
      reservationEndDtm: "", // 권한 만료일시
      attendUser: [], // 참석자
      reservationUserName: "", // 예약자
      reservationUserPhone: "", // 휴대폰 번호
      transfereeName: "", // 양도인
      isListOpen: false, // 참석자 목록이 열려 있는지 여부를 저장하는 상태 변수
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    userId() {
      return this.$store.getters.getUserid;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchReservationPublicroomData();
  },
  methods: {
    goToPublicRoomReservationList() {
      this.$router.push({ path: "/reservation/publicroom/status/list" });
    },
    filterNumeric(event, model) {
      const input = event.target.value;
      // 숫자가 아닌 문자 제거
      const numericValue = input.replace(/\D/g, "");

      // v-model로 연결된 데이터에 필터링된 값 반영
      this[model] = numericValue;

      // 입력 필드 자체의 값을 직접 수정하여 필터링된 값만 남도록 처리
      event.target.value = numericValue;
    },
    async fetchReservationPublicroomData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/PublicRoomReservation/publicroom-reservation-info",
          {
            ResId: this.id,
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.reservationInfo = Array.isArray(data.data) ? data.data : [];

          this.reservationDate = this.reservationInfo[0].reservationDate;
          this.CompanyId = this.reservationInfo[0].companyId;
          this.CompanyName = this.reservationInfo[0].companyName;
          this.WorkplaceId = this.reservationInfo[0].workplaceId;
          this.WorkplaceName = this.reservationInfo[0].workplaceName;

          this.publicroomName = this.reservationInfo[0].publicRoomName;
          this.publicName = this.reservationInfo[0].publicName;
          this.reservationDatetime = this.reservationInfo[0].totalTime;

          this.reservationStatus = this.reservationInfo[0].activeStatus;

          this.reservationStartDtm =
            this.reservationInfo[0].reservationStartDtm;

          if (this.reservationStatus === "O")
            this.reservationEndDtm = this.reservationInfo[0].outTime;
          else
            this.reservationEndDtm = this.reservationInfo[0].reservationEndDtm;

          // 양도인
          this.transfereeName = this.reservationInfo[0].assignUserName;

          // 참석자 데이터가 배열인지 확인하고 처리
          this.attendUser = Array.isArray(this.reservationInfo[0].attendUser)
            ? this.reservationInfo[0].attendUser
            : [];

          this.reservationUserName =
            this.reservationInfo[0].reservationUserName;
          this.reservationUserPhone =
            this.reservationInfo[0].reservationUserPhone;
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
  },
};
</script>

<style scoped></style>
