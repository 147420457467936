<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회사 상세보기</div>
        <div class="description">
          회사 정보를 조회하고 수정합니다.
          <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="회사명을 입력해주세요"
                    v-model="companyName"
                    ref="companyName"
                  />
                </td>
              </tr>
              <tr>
                <th>대표자명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="대표자명을 입력해주세요"
                    v-model="companyOwner"
                    ref="companyOwner"
                  />
                </td>
              </tr>
              <tr>
                <th>사업자등록번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="사업자등록번호를 입력해주세요"
                      v-model="companyRegistrationNo"
                      @input="formatResistrationNo"
                      ref="companyRegistrationNo"
                      maxlength="12"
                    />
                    <p
                      class="text_red"
                      v-if="isRegistrationNoAvailable === false"
                    >
                      사용 불가능
                    </p>
                    <p
                      class="text_green"
                      v-if="isRegistrationNoAvailable === true"
                    >
                      사용 가능
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>대표번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="regionCode">
                      <option>02</option>
                      <option>031</option>
                      <option>032</option>
                      <option>033</option>
                      <option>041</option>
                      <option>042</option>
                      <option>043</option>
                      <option>044</option>
                      <option>051</option>
                      <option>052</option>
                      <option>053</option>
                      <option>054</option>
                      <option>055</option>
                      <option>061</option>
                      <option>062</option>
                      <option>063</option>
                      <option>064</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="middlePhoneNumber"
                      maxlength="4"
                      ref="middlePhoneNumber"
                    />
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="lastPhoneNumber"
                      maxlength="4"
                      ref="lastPhoneNumber"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소<span class="text_red">*</span></th>
                <td class="td_address">
                  <div>
                    <input
                      type="text"
                      class="w_150"
                      placeholder="우편번호"
                      v-model="postalCode"
                      ref="postalCode"
                      readonly
                    />
                    <button type="button" @click="openPostcodePopup">
                      주소찾기
                    </button>
                  </div>
                  <input
                    type="text"
                    class="w_300"
                    placeholder="주소"
                    v-model="address"
                    ref="address"
                  />
                  <input
                    type="text"
                    class="w_300"
                    placeholder="상세주소"
                    v-model="detailedAddress"
                    ref="detailedAddress"
                  />
                </td>
              </tr>
              <tr>
                <th>아이디<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      :class="['w_250', 'readonly-input']"
                      placeholder="아이디를 입력해주세요"
                      v-model="companyAdminId"
                      ref="companyAdminId"
                      readonly
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="w_250"
                    placeholder="비밀번호를 입력해주세요"
                    v-model="companyAdminPw"
                    ref="companyAdminPw"
                  />
                  <label>
                    <input type="checkbox" v-model="showPassword" />
                    비밀번호 보이기
                  </label>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">담당자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="companyManagerName"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="companyManagerPhoneFirstPart">
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="companyManagerPhoneSecondPart"
                      ref="companyManagerPhoneSecondPart"
                    />
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="companyManagerPhoneThirdPart"
                      ref="companyManagerPhoneThirdPart"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>이메일주소</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이메일을 입력해주세요"
                    v-model="companyManagerEmail"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveCompany">저장</button>
            <button type="button" @click="DeleteCheck">삭제</button>
            <button type="button" @click="goToCompanyList">목록</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import debounce from "lodash.debounce"; // lodash의 debounce 함수 사용
import { useToast } from "vue-toastification"; 

export default {
  name: "CompanyView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companyInfo: [],
      companyId: this.id,
      companyName: "",
      companyOwner: "",
      companyRegistrationNo: "",
      regionCode: "02", // 지역번호 선택값
      middlePhoneNumber: "", // 대표번호 중간 입력값
      lastPhoneNumber: "", // 대표번호 마지막 입력값
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      companyAdminId: "", // 관리자 아이디
      companyAdminPw: "", // 관리자 비밀번호
      companyManagerName: "", // 담당자 이름
      companyManagerPhoneFirstPart: "010", // 담당자 휴대폰번호 첫 번째 부분
      companyManagerPhoneSecondPart: "", // 담당자 휴대폰번호 두 번째 부분
      companyManagerPhoneThirdPart: "", // 담당자 휴대폰번호 세 번째 부분
      companyManagerEmail: "", // 담당자 이메일주소
      isRegistrationNoAvailable: null, // 사업자등록번호 사용가능 여부
      isFetchingData: false, // 데이터를 불러오는 중인지 여부
      isInitialLoad: true, // 초기 로드 상태
      showPassword: false, // 비밀번호 보이기 설정
    };
  },
  watch: {
    companyRegistrationNo: debounce(function (newcompanyRegistrationNo) {
      if (newcompanyRegistrationNo && !this.isInitialLoad) {
        this.checkCompanyRNAvailability(newcompanyRegistrationNo);
      } else {
        this.isRegistrationNoAvailable = null;
      }
    }, 500), // 0.5초 간격으로 디바운스 적용
  },
  // 파라미터를 받으려면 routes/index.js 파일에 :id 를 path 에 추가해줘야 함
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.isRegistrationNoAvailable = null; // 초기화
    this.fetchCompanyData();
  },
  methods: {
    goToCompanyList() {
      this.$router.push({ path: "/company/list" });
    },
    async checkCompanyRNAvailability(companyRegistrationNo) {
      try {
        const response = await apiClient.post(
          "/api/Company/check-company-registration-no-detail",
          {
            Id: this.id,
            CompanyRegistrationNo: companyRegistrationNo,
          }
        );

        if (response.status === 200) {
          if (response.data.message == "F")
            this.isRegistrationNoAvailable = false;
          else this.isRegistrationNoAvailable = true;
        }
      } catch (error) {
        console.error(
          "Error checking company resistrationNO availability:",
          error
        );
        this.isRegistrationNoAvailable = false;
      }
    },
    formatResistrationNo() {
      // 숫자만 추출
      let numbers = this.companyRegistrationNo.replace(/\D/g, "");

      if (numbers.length > 3) {
        numbers = numbers.slice(0, 3) + "-" + numbers.slice(3);
      }
      if (numbers.length > 6) {
        numbers = numbers.slice(0, 6) + "-" + numbers.slice(6);
      }

      this.companyRegistrationNo = numbers;
    },
    handleRegistrationNoInput() {
      if (this.isFetchingData) return;
      this.isRegistrationNoAvailable = null; // 사용 가능 여부 초기화
    },
    async fetchCompanyData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Company/company-info", {
          Id: this.id,
        });
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.companyInfo = Array.isArray(data.data) ? data.data : [];
          console.log(this.companyInfo[0]);
          console.log(this.companyInfo[0].companyName);
          // 회사정보 뿌려오기
          this.companyName = this.companyInfo[0].companyName;
          this.companyOwner = this.companyInfo[0].companyOwner;
          this.companyRegistrationNo =
            this.companyInfo[0].companyRegistrationNo;

          // 전화번호 분할
          const phoneParts = this.companyInfo[0].companyTelephone.split("-");
          if (phoneParts.length === 3) {
            this.regionCode = phoneParts[0];
            this.middlePhoneNumber = phoneParts[1];
            this.lastPhoneNumber = phoneParts[2];
          }

          this.postalCode = this.companyInfo[0].companyZipCode;
          this.address = this.companyInfo[0].companyAddr1;
          this.detailedAddress = this.companyInfo[0].companyAddr2;

          this.companyAdminId = this.companyInfo[0].companyAdminId;
          this.companyAdminPw = this.companyInfo[0].companyAdminPw;

          this.companyManagerName = this.companyInfo[0].companyManager;

          // 담당자 휴대폰 번호 분할
          const managerphoneParts =
            this.companyInfo[0].companyManagerPhone.split("-");

          if (managerphoneParts.length === 3) {
            this.companyManagerPhoneFirstPart = managerphoneParts[0];
            this.companyManagerPhoneSecondPart = managerphoneParts[1];
            this.companyManagerPhoneThirdPart = managerphoneParts[2];
          }

          this.companyManagerEmail = this.companyInfo[0].companyManagerEmail;

          // 사업자등록번호 사용 가능 여부 초기화
          this.isRegistrationNoAvailable = null;
          this.isInitialLoad = false; // 초기 로드 완료
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    DeleteCheck() {
      if (confirm("해당 회사를 삭제하시겠습니까?")) {
        this.DeleteCompany();
      }
    },
    async saveCompany() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post("/api/Company/company-edit", {
            Id: this.id,
            CompanyName: this.companyName,
            CompanyOwner: this.companyOwner,
            CompanyRegistrationNo: this.companyRegistrationNo,
            CompanyTelephone: `${this.regionCode}-${this.middlePhoneNumber}-${this.lastPhoneNumber}`,
            CompanyZipCode: this.postalCode,
            CompanyAddr1: this.address,
            CompanyAddr2: this.detailedAddress,
            CompanyAdminId: this.companyAdminId,
            CompanyAdminPw: this.companyAdminPw,
            CompanyManager: this.companyManagerName,
            CompanyManagerPhone: `${this.companyManagerPhoneFirstPart}-${this.companyManagerPhoneSecondPart}-${this.companyManagerPhoneThirdPart}`,
            CompanyManagerEmail: this.companyManagerEmail,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          });
          if (response.status === 200) {  
            this.toast.success("회사 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });		
		
            this.goToCompanyList();
          }
        } catch (error) {
          console.error("Error saving user data:", error);          		
          this.toast.error("회사 정보가 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },

    checkFields() {
      if (!this.companyName) {
		    this.toast.error("회사명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        this.$refs.companyName.focus();
        return false;
      }

      if (!this.companyOwner) {        		
		    this.toast.error("대표자명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.companyOwner.focus();
        return false;
      }

      if (!this.companyRegistrationNo) {	
		    this.toast.error("사업자 등록번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.companyRegistrationNo.focus();
        return false;
      } else {
        // 숫자만 추출
        const numbers = this.companyRegistrationNo.replace(/[^0-9]/g, "");
        if (numbers.length < 10) {
		    this.toast.error("사업자 등록번호는 10자리 숫자여야 합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
          this.$refs.companyRegistrationNo.focus();
          return false;
        }
      }

      if (this.isRegistrationNoAvailable === false) {
		    this.toast.error("사용 불가능한 사업자 등록번호입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.companyRegistrationNo.focus();
        return false;
      }

      if (!this.regionCode) {
		    this.toast.error("지역번호를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.regionCode.focus();
        return false;
      }
      if (!this.middlePhoneNumber || this.middlePhoneNumber.length < 3) {
		    this.toast.error("대표번호 중간 번호 3자리 이상 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.middlePhoneNumber.focus();
        return false;
      }
      if (!this.lastPhoneNumber || this.lastPhoneNumber.length !== 4) {
		    this.toast.error("대표번호 마지막 번호 4자리를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.lastPhoneNumber.focus();
        return false;
      }

      if (!this.postalCode) {
		    this.toast.error("우편번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.postalCode.focus();
        return false;
      }
      if (!this.address) {
		    this.toast.error("주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.address.focus();
        return false;
      }
      if (!this.detailedAddress) {
		    this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.detailedAddress.focus();
        return false;
      }

      if (this.companyManagerEmail && !this.isValidEmail(this.companyManagerEmail)) {
		    this.toast.error("유효한 이메일 주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.companyManagerEmail.focus();
        return false;
      }

      return true;
    },
    isValidEmail(email) {
      // 이메일 유효성 검사 정규식
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    async DeleteCompany() {
      try {
        const response = await apiClient.post("/api/Company/company-delete", {
          Id: this.id,
          LoginId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
        this.toast.success("회사 정보가 성공적으로 삭제되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
		
          this.goToCompanyList();
        }
      } catch (error) {
        console.error("Error deleting companies:", error);		
		    this.toast.error("회사 삭제 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
};
</script>

<style scoped>
.readonly-input {
  background-color: #f5f5f5; /* 연한 회색 배경 */
  border: 1px solid #ccc; /* 연한 회색 테두리 */
  color: #333; /* 어두운 텍스트 색상 */
  cursor: not-allowed; /* 커서 모양 변경 */
}
</style>
