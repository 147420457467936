<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">태블릿 관리</div>
        <div class="description">설치된 태블릿들을 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        삭제
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        미삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="deviceName">장치이름</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalTablets }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>개씩</span>
            </div>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 15%" />
                  <col />
                  <col style="width: 22%" />
                  <col style="width: 22%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회사명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">사업장</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">장치이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회의실</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상태</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody v-if="tabletList.length > 0">
                  <tr
                    v-for="tablet in paginatedTablets"
                    :key="tablet.deviceId"
                    class="no_move"
                    :data-device-id="tablet.deviceId"
                    :data-company-id="tablet.companyId"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(tablet.deviceId)"
                        :checked="
                          selectedTablets.includes(tablet.deviceId)
                        "
                      />
                    </td>
                    <td class="no_mobile">{{ tablet.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ tablet.companyName }}</span>
                    </td>
                    <td class="seat inner_td o4r">
                      <span class="only_mobile">사업장 : </span>
                      <p class="long_p">{{ tablet.workplaceName }}</p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <router-link
                          :to="{
                            name: 'SettingTabletView',
                            params: { id: tablet.deviceId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ tablet.deviceName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">상태 : </span>
                      <span>{{ tablet.roomId == null || tablet.roomId === 0 ? '미정' : tablet.roomName }}</span>
                    </td>
                    <td class="num o_stat">
                      <div
                        class="stat"
                        :class="{
                          back_red: tablet.delYn === 'Y',
                          back_green: tablet.delYn === 'N',
                        }"
                      >
                        <span v-if="tablet.delYn === 'Y'"
                          >삭제</span
                        >
                        <span v-else-if="tablet.delYn === 'N'"
                          >미삭제</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="no_data">
                    <td colspan="7">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="list_bottom">
            <div>
              <button type="button" @click.prevent="clickUpdateDelYn('Y')">
                삭제
              </button>
              <button type="button" @click.prevent="clickUpdateDelYn('N')">
                미삭제
              </button>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingTabletList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      tabletList: [], // 태블릿 목록 데이터를 저장할 배열
      selectedTablets: [], // 선택된 태블릿 목록의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "deviceName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalTablets: 0, // 총 태블릿 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
    };
  },
  watch: {},
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    paginatedTablets() {
      if (!Array.isArray(this.tabletList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.tabletList);
      console.log(
        "Paginated tabletList: ",
        this.tabletList.slice(start, end)
      );
      return this.tabletList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.totalTablets / this.itemsPerPage);
    },
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    clickUpdateDelYn(type) {
      if (this.selectedTablets.length === 0) {
        const action = type === 'Y' ? '삭제' : '미삭제';	
		    this.toast.error(`${action}를 원하시는 태블릿을 선택해주세요.`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      this.updateDelYn(this.selectedTablets, type);
    },
    async updateDelYn(selectedDeviceIds, type) {
      try {
        const promises = selectedDeviceIds.map(async (deviceId) => {
          return apiClient.post("/api/Tablet/tablet-delyn-update", {
            DeviceId: deviceId,
            DelYn: type,
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to tablet-delyn-update for ID ${selectedDeviceIds[index]}`
            );
          }
        });
        
        this.selectedTablets = [];
        this.allSelected = false;
        this.fetchData();
      } catch (error) {
        console.error("Error-tablet-delyn-status-update:", error);
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedTablets = this.tabletList.map((tablet) => tablet.deviceId);
      } else {
        this.selectedTablets = [];
      }
    },
    toggleSelection(deviceId) {
      const isSelected = this.selectedTablets.includes(deviceId);

      if (isSelected) {
        this.selectedTablets = this.selectedTablets.filter(
          (id) => id !== deviceId
        );
      } else {
        this.selectedTablets.push(deviceId);
      }

      this.allSelected = this.selectedTablets.length === this.tabletList.length;
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.tabletList = [];
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.tabletList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.tabletList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.tabletList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.tabletList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.tabletList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.tabletList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.tabletList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId = this.selectedCompany === "0" ? "" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId;
          v_workplaceId = this.workplaceId;
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchStatusType: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Tablet/all-tablet-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.tabletList = Array.isArray(data.data) ? data.data : [];
          this.totalTablets = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching requestjoinList data:", error);
      }
    },
  },
  mounted() {
    if (this.userType === "C") {
      const companyId = this.companyId;
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = this.companyId; 
      const workplaceId = this.workplaceId;

      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      this.fetchData();
    } else {
      this.fetchCompanies();
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
