<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회의실 등록</div>
        <div class="description">
          회의실을 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      :value="selectedCompanyName"
                      readonly
                    />
                    <button @click="handleCompanySelection">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    :value="selectedWorkplaceName"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th>진열상태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: displaystatusSelect === 'Y' }"
                      @click="setDisplayStatusSelect('Y')"
                    >
                      진열
                    </button>
                    <button
                      type="button"
                      :class="{ on: displaystatusSelect === 'N' }"
                      @click="setDisplayStatusSelect('N')"
                    >
                      미진열
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상품분류<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td open_list">
                    <!-- '선택하기' 클릭 시 id="Modal" 나타남 -->
                    <button type="button" @click="showModal2">+추가</button>
                    <ul
                      class="attendee_add group_choice"
                      v-if="selectedParentName"
                    >
                      <li>
                        <div>
                          <span>{{ selectedParentName }}</span>
                        </div>
                        <a href="###" @click="clearParentCategory">⨉</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상품명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상품명을 입력해주세요 ex) 마이즈 1 회의실"
                      v-model="meetingRoomName"
                      ref="meetingRoomName"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <div class="inner_td">
                    <textarea
                      placeholder="상세설명을 입력하세요. ex) 운영시간 등.."
                      v-model="detailDesc"
                      ref="detailDesc"
                    ></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  이미지<span class="text_red">*</span>
                  <br />
                  (최대 5장까지 가능)
                </th>
                <td class="td_address">
                  <!-- 이미지 미리보기 -->
                  <div class="image-preview">
                    <img
                      v-for="(image, index) in selectedImages"
                      :key="index"
                      :src="image.url"
                      :alt="image.name"
                      class="preview-img"
                    />
                  </div>
                  <!-- 이미지 파일명 리스트 -->
                  <div>
                    <ul class="filesbox">
                      <li
                        v-for="(image, index) in selectedImages"
                        :key="index"
                        class="selectedfile"
                      >
                        {{ image.name }}
                        <SvgIcons
                          icon="close"
                          @click="removeImage(index)"
                          width="15px"
                          height="15px"
                        />
                      </li>
                    </ul>
                  </div>
                  <!-- 파일 선택 -->
                  <div>
                    <input
                      type="file"
                      class="w_250"
                      placeholder="선택된 파일 없음"
                      multiple
                      @change="handleImageChange"
                      ref="fileInput"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">상세정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <!-- 시간단위는 없어짐 무조건 30분 단위로 -->
              <tr style="display: none">
                <th>시간단위<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option selected>선택해주세요</option>
                      <option>30분</option>
                      <option>1시간</option>
                      <option>1시간30분</option>
                      <option>2시간</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>수용인원<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="number"
                      class="w_100"
                      v-model="capacity"
                      ref="capacity"
                      min="1"
                      placeholder="1"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>운영시간<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 시작시간 선택 -->
                    <select
                      v-model="startTime"
                      ref="startTime"
                      class="w_100"
                      @change="updateEndTimeOptions"
                    >
                      <option
                        v-for="time in timeOptions"
                        :key="time"
                        :value="time"
                      >
                        {{ time }}
                      </option>
                    </select>
                    ~
                    <!-- 종료시간 선택 -->
                    <select v-model="endTime" ref="endTime" class="w_100">
                      <option
                        v-for="time in filteredEndTimeOptions"
                        :key="time"
                        :value="time"
                      >
                        {{ time }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>옵션</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="option_content">
                      <p class="w_150">시간</p>
                      <p>활성화여부</p>
                    </div>
                    <div
                      class="td_toggle"
                      v-for="(time, index) in filteredTimeOptions"
                      :key="index"
                    >
                      <input :class="'w_150'" :value="time" readonly />
                      <input
                        type="checkbox"
                        :id="'toggle' + index"
                        class="toggle"
                        hidden
                        v-model="activeTimes[time]"
                      />
                      <label :for="'toggle' + index" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a class="on" @click="saveMeetingRoom">등록</a>
            <a @click="goToMeetingRoomList">취소</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사/사업장 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">회사/사업장 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="SearchType">
                      <option value="companyName">회사명</option>
                      <option value="workplaceName">사업장명</option>
                    </select>
                    <input
                      type="text"
                      class="w_200"
                      v-model="SearchKey"
                      placeholder="검색어를 입력해주세요"
                      @keyup.enter="searchCompanies"
                    />
                    <button type="button" @click="searchCompanies">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>회사명</th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="companyworkplace in filteredCompanyWorkplaces"
                :key="companyworkplace.workplaceId"
              >
                <td>{{ companyworkplace.companyName }}</td>
                <!-- paginatedCompanyWorkplaces -->
                <td>
                  {{ companyworkplace.workplaceName }}
                </td>
                <!-- workplaceProductType -->
                <td>
                  <button
                    class="keep"
                    @click="selectCompanyWorkplace(companyworkplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //회사/사업장 선택 modal -->
  <!-- 상위분류 선택 modal -->
  <div id="Modal2" class="d_pop" v-if="isModal2Visible">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">상위분류 선택</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'A' }"
                      @click="setModal2Status('A')"
                    >
                      전체
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'Y' }"
                      @click="setModal2Status('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'N' }"
                      @click="setModal2Status('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      tyoe="text"
                      class="w_200"
                      v-model="SearchKey2"
                      placeholder="분류명을 입력해주세요"
                      @keyup.enter="searchProductcategorys"
                    />
                    <button type="button" @click="searchProductcategorys">
                      검색
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>분류명</th>
                <th>표시상태</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="productCategory in filteredProductCategorys"
                :key="productCategory.id"
              >
                <td>{{ productCategory.productName }}</td>
                <td>
                  {{ productCategory.activeStatus === "Y" ? "표시" : "미표시" }}
                </td>
                <td>
                  <button
                    class="keep"
                    @click="selectParentCategory(productCategory)"
                  >
                    선택
                  </button>
                </td>
              </tr>
              <!-- <tr>
                <td>27층</td>
                <td>표시</td>
                <td><button class="keep">선택</button></td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup2"
              @click.prevent="prevPageGroup2"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage2" @click.prevent="prevPage2">
              <img src="@/images/prev_n.png" alt="이전페이지" />
            </a>
            <a
              v-for="page2 in pageGroup2"
              :key="page2"
              href="###"
              :class="{ on: currentPage2 === page2 }"
              @click.prevent="goToPage2(page2)"
            >
              {{ page2 }}
            </a>
            <a href="###" v-if="hasNextPage2" @click.prevent="nextPage2">
              <img src="@/images/next_n.png" alt="다음페이지" />
            </a>
            <a
              href="###"
              v-if="hasNextPageGroup2"
              @click.prevent="nextPageGroup2"
            >
              <img src="@/images/end_n.png" alt="마지막페이지" />
            </a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //상위분류 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import SvgIcons from "@/components/SvgIcons.vue";
import { useToast } from "vue-toastification"; 

export default {
  name: "ProductMeetingroomAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedImages: [], // 선택된 이미지를 저장할 배열
      maxImageCount: 5, // 최대 이미지 선택 개수

      startTime: "09:00", // 초기 시작 시간
      endTime: "18:00", // 초기 종료 시간
      timeOptions: [], // 30분 단위의 시간 옵션 리스트
      activeTimes: {}, // 각 시간에 대한 활성화 상태 저장
      filteredEndTimeOptions: [], // 시작시간에 따라 필터된 종료시간 옵션 리스트

      selectedParentId: null, // 선택된 상위분류 ID
      selectedParentName: "", // 선택된 상위분류 이름
      isModalVisible: false, // 회사 선택 모달
      isModal2Visible: false, // 상위분류 선택 모달 상태
      modal2Status: "A", // 상위분류 모달의 상태 기본값을 '전체'로 설정
      companyworkplaces: [], // API 에서 가져온 회사-사업장 목록 데이터를 저장할 배열
      productcategorys: [], // API 에서 가져온 상품분류 목록 데이터를 저장할 배열
      displaystatusSelect: "Y", // 진열상태 '진열-Y', '미진열-N'

      totalCompanyWorkplaces: 0, // 총 회사-사업자 수
      totalProductCategorys: 0, // 총 상위분류 수

      selectedCompanyName: "", // 선택된 회사명
      selectedWorkplaceName: "", // 선택된 사업장명
      selectedCompanyId: 0, // 선택된 회사 ID
      selectedWorkplaceId: 0, // 선택된 사업장 ID
      selectedWorkplaceProductType: "", // 선택된 사업장의 상품유형

      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "companyName", // 기본값은 'companyName'으로 설정
      SearchKey: "", // 검색어를 입력받기 위한 변수
      filteredCompanyWorkplaces: [], // 검색된 결과를 저장할 배열

      currentPage2: 1, // 현재 페이지
      itemsPerPage2: 5, // 페이지 당 항목 수
      pageGroupSize2: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType2: "productName",
      SearchKey2: "", // 검색어를 입력받기 위한 변수
      filteredProductCategorys: [], // 검색된 결과를 저장할 배열
    };
  },
  components: {
    SvgIcons, // 여기서 제대로 등록되어야 합니다.
  },
  created() {
    this.generateTimeOptions(); // 컴포넌트가 생성될 때 시간 옵션 생성
    this.updateEndTimeOptions(); // 종료시간 옵션을 초기화
  },
  computed: {
    filteredTimeOptions() {
      // startTime과 endTime 사이의 시간만 필터링
      return this.timeOptions.filter(
        (time) => time >= this.startTime && time <= this.endTime
      );
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      console.log("Company ID:", this.$store.getters.getCompanyid);
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      console.log("Workplace ID:", this.$store.getters.getWorkplaceid);
      return this.$store.getters.getWorkplaceid;
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalCompanyWorkplaces / this.itemsPerPage);
    },
    // 총 페이지 수 계산
    totalPages2() {
      return Math.ceil(this.totalProductCategorys / this.itemsPerPage2);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    // 현재 페이지 그룹 계산
    pageGroup2() {
      const start =
        Math.floor((this.currentPage2 - 1) / this.pageGroupSize2) *
          this.pageGroupSize2 +
        1;
      const end = Math.min(start + this.pageGroupSize2 - 1, this.totalPages2);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup2() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage2 > 1;
    },
    hasNextPageGroup2() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage2 < this.totalPages2;
    },
    hasPrevPage2() {
      return this.currentPage2 > 1;
    },
    hasNextPage2() {
      return this.currentPage2 < this.totalPages2;
    },
  },
  mounted() {
    this.loadWorkplaces();
    this.loadProductcategorys();
  },
  methods: {
    handleImageChange(event) {
      const files = event.target.files;
      const validExtensions = ["jpg", "jpeg", "png"]; // 허용할 확장자
      const validFiles = [];
      const invalidFiles = [];
      const duplicateFiles = [];
      let validFileCount = 0; // 유효한 파일 수를 추적

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.split(".").pop().toLowerCase();

        // 파일 확장자가 유효한지 확인
        if (!validExtensions.includes(extension)) {
          invalidFiles.push(file.name); // 유효하지 않은 파일 목록에 추가
          continue;
        }

        // 동일한 이름의 파일이 이미 선택된 경우
        const duplicate = this.selectedImages.some(
          (image) => image.name === file.name
        );
        if (duplicate) {
          duplicateFiles.push(file.name);
          continue; // 중복된 파일은 추가하지 않음
        }

        validFileCount++; // 유효한 파일 수를 증가

        const reader = new FileReader();

        reader.onload = (e) => {
          validFiles.push({
            name: file.name,
            url: e.target.result,
            file: file, // 파일 객체를 추가
          });

          // 모든 유효한 파일 처리가 끝난 후 유효한 파일만 추가
          if (validFiles.length === validFileCount) {
            this.processValidFiles(validFiles, duplicateFiles, invalidFiles);
          }
        };

        reader.readAsDataURL(file);
      }

      // 파일 입력 요소를 초기화하여 같은 파일을 다시 선택할 수 있도록 합니다.
      this.resetFileInput(event);
    },

    processValidFiles(validFiles, duplicateFiles, invalidFiles) {
      // 중복된 파일 알림
      if (duplicateFiles.length > 0) {
		    this.toast.error(`이미 선택된 파일입니다: ${duplicateFiles.join(", ")}`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }

      // 유효하지 않은 파일 알림
      if (invalidFiles.length > 0) {
		    this.toast.error(`허용되지 않는 파일 형식입니다 (허용 확장자: jpg, jpeg, png)\n\n${invalidFiles.join(
            ", "
          )} `, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }

      // 유효한 파일만 추가
      this.selectedImages = [...this.selectedImages, ...validFiles];

      // 최대 이미지 수를 초과하면 추가로 선택된 파일은 무시
      if (this.selectedImages.length > this.maxImageCount) {
		    this.toast.error(`최대 ${this.maxImageCount} 장의 이미지만 업로드 할 수 있습니다.`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.selectedImages = this.selectedImages.slice(0, this.maxImageCount); // 초과된 파일은 제거
      }
    },
    removeImage(index) {
      const imageName = this.selectedImages[index].name; // 선택한 이미지의 이름 가져오기
      const confirmed = confirm(`이미지 "${imageName}"를 삭제하시겠습니까?`);
      if (confirmed) {
        this.selectedImages.splice(index, 1);

        // 이미지를 삭제한 후 파일 입력 요소를 초기화합니다.
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          // fileInput.value = ""; // 선택된 파일을 초기화
        }
      }
    },
    resetFileInput(event) {
      event.target.value = ""; // 파일 입력 요소를 초기화
    },
    generateTimeOptions() {
      const times = [];
      let hour = 0;
      let minute = 0;

      while (hour < 24 || (hour === 23 && minute === 30)) {
        let formattedHour = hour < 10 ? "0" + hour : hour;
        let formattedMinute = minute < 10 ? "0" + minute : minute;
        const time = `${formattedHour}:${formattedMinute}`;
        times.push(time);
        this.activeTimes[time] = true; // Vue 3에서는 이렇게 사용 // 기본값을 true 면 선택된거고 false 면 선택안된거야
        //console.log("this.activeTimes---------------------" + this.activeTimes);
        minute += 30;
        if (minute === 60) {
          minute = 0;
          hour++;
        }
      }

      this.timeOptions = times;
    },
    updateEndTimeOptions() {
      // 시작시간 이후의 시간만 종료시간 옵션에 포함
      this.filteredEndTimeOptions = this.timeOptions.filter(
        (time) => time > this.startTime
      );

      // 만약 종료시간이 필터된 옵션에 포함되지 않으면 초기화
      if (!this.filteredEndTimeOptions.includes(this.endTime)) {
        this.endTime = this.filteredEndTimeOptions[0];
      }
    },
    searchCompanies() {
      this.loadWorkplaces();
    },
    searchProductcategorys() {
      this.loadProductcategorys();
    },
    clearParentCategory() {
      this.selectedParentId = null;
      this.selectedParentName = "";
    },
    selectParentCategory(category) {
      this.selectedParentId = category.id;
      this.selectedParentName = category.productName;
      this.hideModal2(); // 모달 닫기
    },
    setModal2Status(status) {
      this.modal2Status = status;
      this.loadProductcategorys();
    },
    goToPage(page) {
      this.currentPage = page;
      this.companyworkplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    goToPage2(page) {
      this.currentPage2 = page;
      this.productcategorys = []; // 페이지 변경 시 목록 초기화
      this.loadProductcategorys();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      if (this.hasNextPageGroup) {
        this.currentPage = this.pageGroup[this.pageGroup.length - 1] + 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup() {
      if (this.hasPrevPageGroup) {
        this.currentPage = this.pageGroup[0] - 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPage2() {
      if (this.currentPage2 < this.totalPages2) {
        this.currentPage2++;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage2() {
      if (this.currentPage2 > 1) {
        this.currentPage2--;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup2() {
      if (this.hasNextPageGroup2) {
        this.currentPage2 = this.pageGroup2[this.pageGroup.length - 1] + 1;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup2() {
      if (this.hasPrevPageGroup2) {
        this.currentPage2 = this.pageGroup2[0] - 1;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    selectCompanyWorkplace(companyworkplace) {
      this.selectedCompanyName = companyworkplace.companyName;
      this.selectedWorkplaceName = companyworkplace.workplaceName;
      this.selectedCompanyId = companyworkplace.companyId;
      this.selectedWorkplaceId = companyworkplace.workplaceId;
      this.selectedWorkplaceProductType = companyworkplace.workplaceProductType; // 선택된 사업장의 상품유형 저장
      this.hideModal();
    },
    goToMeetingRoomList() {
      this.$router.push({ path: "/product/meetingroom/list" });
    },
    handleCompanySelection() {
      this.showModal();
    },
    showModal() {
      this.isModalVisible = true;
      this.SearchKey = ""; // 검색어 초기화
      this.filteredCompanyWorkplaces = [...this.companyworkplaces]; // 검색 결과 초기화
    },
    hideModal() {
      this.isModalVisible = false;
    },
    showModal2() {
      this.isModal2Visible = true;
      this.SearchKey2 = ""; // 검색어 초기화
      this.filteredProductCategorys = [...this.productcategorys]; // 검색 결과 초기화
    },
    hideModal2() {
      this.isModal2Visible = false;
    },
    async saveMeetingRoom() {
      if (this.checkFields()) {
        if (this.uploadImages()) {
          const timeSettings = this.timeOptions.map((time) => {
            return {
              Time: time,
              IsActive: this.activeTimes[time] || false,
            };
          });

          console.log("Time Settings:", timeSettings);

          // 여기에 API 호출을 추가하여 데이터를 서버에 전송
          try {
            console.log("CompanyId---" + this.selectedCompanyId);
            console.log("WorkplaceId---" + this.selectedWorkplaceId);
            console.log("ActiveStatus---" + this.displaystatusSelect);
            console.log("ParentId---" + this.selectedParentId);
            console.log("MeetingRoomName---" + this.meetingRoomName);
            console.log("MeetingRoomDetailDesc---" + this.detailDesc);
            console.log("MeetingRoomLimitPeople---" + this.capacity);
            console.log("StartDtm---" + this.startTime);
            console.log("EndDtm---" + this.endTime);

            // 파일명만 추출하여 쉼표로 연결된 문자열로 변환
            const imageNames = this.selectedImages
              .map((image) => image.name)
              .join(",");

            console.log("ThumNail---" + imageNames);

            const response = await apiClient.post(
              "/api/MeetingRoom/meetingroom-add",
              {
                CompanyId: this.selectedCompanyId,
                WorkplaceId: this.selectedWorkplaceId,
                ActiveStatus: this.displaystatusSelect, // 표시상태 '진열-Y', '미진열-N'
                ParentId: this.selectedParentId, // 선택된 상위분류 ID 추가
                MeetingRoomName: this.meetingRoomName, // 상품명
                MeetingRoomDetailDesc: this.detailDesc, // 상품 상세설명
                MeetingRoomLimitPeople: this.capacity, // 수용인원
                StartDtm: this.startTime,
                EndDtm: this.endTime,
                ThumNail: imageNames, // 선택한 이미지 이름들
                LoginId: localStorage.getItem("userid"),
                TimeSettings: timeSettings, // 모든 시간대 데이터를 포함한 TimeSettings 추가
                // 필요한 다른 필드 추가
              }
            );

            if (response.status === 200) {
              this.toast.success("회의실이 성공적으로 등록되었습니다.", {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
              this.goToMeetingRoomList();
            }
          } catch (error) {
            console.error("Error saving meetingroom:", error);
            this.toast.error("회의실 등록 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        }
      }
    },
    uploadImages() {
      try {
        const formData = new FormData();
        // 선택한 이미지를 FormData에 추가

        this.selectedImages.forEach((image) => {
          formData.append(image.name, image.file);
        });

        // 서버로 업로드 요청
        const response = apiClient.post(
          "/api/MeetingRoom/meetingroom-file-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          this.toast.success("이미지가 성공적으로 업로드되었습니다!", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          console.log(response.data);
        }
        return true;
      } catch (error) {
        console.error("이미지 업로드 중 오류 발생:", error);
		    this.toast.error("이미지 업로드 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
    },
    checkFields() {
      if (this.selectedCompanyId === 0 || this.selectedWorkplaceId === 0) {
        // 회사가 선택되지 않은 경우
		    this.toast.error("회사 및 사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.isModalVisible = true;
        return;
      }
      if (this.selectedParentId === null || this.selectedParentId === 0) {
		    this.toast.error("상품분류를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.isModal2Visible = true;
        return;
      }
      if (!this.meetingRoomName) {
		    this.toast.error("상품명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.meetingRoomName.focus();
        return false;
      }
      // 이미지 선택 여부 확인
      if (this.selectedImages.length === 0) {
		    this.toast.error("최소 한 개의 이미지를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
    async loadProductcategorys() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: "",
          SearchActiveStatus: this.modal2Status, // 상태별 값 추가
          SearchType: this.SearchType2,
          SearchKey: this.SearchKey2,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        console.log("★★★★----" + searchParam.Page);
        console.log("★★★★----" + searchParam.PageSize);
        console.log("★★★★----" + searchParam.OrderType);
        console.log("★★★★----" + searchParam.SearchActiveStatus);
        console.log("★★★★----" + searchParam.SearchType2);
        console.log("★★★★----" + searchParam.SearchKey2);
        console.log("★★★★----" + searchParam.CompanyId);
        console.log("★★★★----" + searchParam.WorkplaceId);

        const response = await apiClient.post(
          "/api/Product/all-product-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.productcategorys = Array.isArray(data.data) ? data.data : [];
          this.totalProductCategorys = data.meta.totalCount || 0;
          this.filteredProductCategorys = this.productcategorys;
        }
      } catch (error) {
        console.error("Error loading productcategorys:", error);
      }
    },
    async loadWorkplaces() {
      let v_companyId = "";
      let v_workplaceId = "";
      // 슈퍼관리자면 회사 선택 골라야 하고
      // 회사관리자면 본인 회사 관련된 사업장 리스트만 나와야 하고
      // 사업장관리자면 선택 버튼 자체가 안보여야 함
      if (this.userType === "S") {
        v_companyId = "";
      }

      if (this.userType === "C") {
        v_companyId = this.companyId;
      }

      if (this.userType === "W") {
        v_companyId = this.companyId;
        v_workplaceId = this.workplaceId;
      }

      try {
        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          SearchActiveStatus: "Y", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정 //workplaceProductType
          WorkplaceId: v_workplaceId,
        };

        console.log(searchParam.SearchType);
        console.log(searchParam.SearchKey);
        console.log(searchParam.SearchActiveStatus);
        console.log(searchParam.OrderType);
        console.log(searchParam.PageSize);
        console.log(searchParam.Page);
        console.log(searchParam.CompanyId);

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.companyworkplaces = Array.isArray(data.data) ? data.data : [];
          this.totalCompanyWorkplaces = data.meta.totalCount || 0;
          this.filteredCompanyWorkplaces = this.companyworkplaces;
          console.log(this.filteredCompanyWorkplaces[0]);
        }
      } catch (error) {
        console.error("Error loading workplaces:", error);
      }
    },
    setDisplayStatusSelect(status) {
      this.displaystatusSelect = status;
    },
  },
  watch: {
    startTime() {
      // startTime이 변경될 때마다 filteredTimeOptions 갱신
      this.updateEndTimeOptions();
    },
    endTime() {
      // endTime이 변경될 때마다 filteredTimeOptions 갱신
      this.updateEndTimeOptions();
    },
  },
};
</script>

<style scoped>
.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.preview-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
}
</style>
