<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">내 정보</div>
        <div class="description">내 정보를 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 15%" class="colspan25" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>
                    <div class="inner_td validation">
                      <input type="text" v-model="userName" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>아이디</th>
                  <td>
                    <div class="inner_td validation">
                      <input
                        type="text"
                        v-model="userId"
                        readonly
                        class="readonly-input"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>패스워드</th>
                  <td>
                    <div class="inner_td validation passwordtoggle">
                      <input
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                      />
                      <div class="inner_td">
                        <input
                          type="checkbox"
                          id="showPassword"
                          v-model="showPassword"
                        />
                        <label for="showPassword">비밀번호 보이기</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveUser">저장</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "MyInfoEdit",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      userId: "", // 아이디 입력값
      userName: "", // 이름 입력값
      password: "", // 패스워드 입력값
      showPassword: false, // 비밀번호 보이기 체크박스 상태
    };
  },
  methods: {
    async saveUser() {
      try {
        const user = {
          UserId: this.userId,
          Password: this.password,
          AdminName: this.userName,
        };
        const response = await apiClient.post(
          "/api/User/admin-user-my-edit",
          user
        );
        if (response.status === 200) {
		    this.toast.success("내 정보가 수정되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
		
        }
      } catch (error) {
        console.error("Error saving user data:", error);
		    this.toast.error("내 정보 수정 처리 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
      }
    },
  },
  async mounted() {
    // 로컬스토리지에서 userid를 가져와서 userId에 설정
    this.userId = localStorage.getItem("userid");

    if (this.userId) {
      const user = {
        UserId: this.userId,
      };

      // API 호출
      if (user.UserId) {
        try {
          const response = await apiClient.post(
            "/api/User/admin-user-my-info",
            user
          );

          if (response.status === 200) {
            this.userName = response.data.user.AdminName;
            this.password = response.data.user.Password;
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    } else {		
		  this.toast.error("로그인이 필요합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
      });
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.readonly-input {
  background-color: #f5f5f5; /* 연한 회색 배경 */
  border: 1px solid #ccc; /* 연한 회색 테두리 */
  color: #333; /* 어두운 텍스트 색상 */
  cursor: not-allowed; /* 커서 모양 변경 */
}
</style>
