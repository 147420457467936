<template>
  <div class="body_left">
    <div class="left_top no_mobile">내 정보</div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/myinfo/management"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
          >
            내 정보 수정
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Myinfo_Left",
  data() {
    return {
      selectedMenu: null,
    };
  },
  methods: {
    selectMenu(index) {
      this.selectedMenu = index;
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (path === "/myinfo/management") {
        this.selectedMenu = 0;
      } else {
        this.selectedMenu = null;
      }
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
