<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사업장 상세보기</div>
        <div class="description">사업장 정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      placeholder="회사명을 선택해주세요"
                      v-model="companyName"
                      ref="companyName"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업자명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="사업자명을 선택해주세요"
                    v-model="workplaceName"
                    ref="workplaceName"
                  />
                </td>
              </tr>
              <tr>
                <th>대표자명<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <input
                    type="text"
                    class="w_250"
                    placeholder="대표자명을 입력해주세요"
                    v-model="workplaceOwner"
                    ref="workplaceOwner"
                  />
                </td>
              </tr>
              <tr>
                <th>사업자등록번호<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="사업자등록번호를 입력해주세요"
                      v-model="workplaceRegistrationNo"
                      @input="formatResistrationNo"
                      ref="workplaceRegistrationNo"
                      maxlength="12"
                    />
                    <p
                      class="text_red"
                      v-if="isRegistrationNoAvailable === false"
                    >
                      사용 불가능
                    </p>
                    <p
                      class="text_green"
                      v-if="isRegistrationNoAvailable === true"
                    >
                      사용 가능
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>대표번호<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <div class="inner_td mobile_number">
                    <select v-model="regionCode">
                      <option>02</option>
                      <option>031</option>
                      <option>032</option>
                      <option>033</option>
                      <option>041</option>
                      <option>042</option>
                      <option>043</option>
                      <option>044</option>
                      <option>051</option>
                      <option>052</option>
                      <option>053</option>
                      <option>054</option>
                      <option>055</option>
                      <option>061</option>
                      <option>062</option>
                      <option>063</option>
                      <option>064</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="middlePhoneNumber"
                      maxlength="4"
                      ref="middlePhoneNumber"
                    />
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="lastPhoneNumber"
                      maxlength="4"
                      ref="lastPhoneNumber"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소<span class="text_red">*</span></th>
                <td class="td_address">
                  <div>
                    <input
                      type="text"
                      class="w_150"
                      placeholder="우편번호"
                      v-model="postalCode"
                      ref="postalCode"
                      readonly
                    />
                    <button type="button" @click="openPostcodePopup">
                      주소찾기
                    </button>
                  </div>
                  <input
                    type="text"
                    class="w_300"
                    placeholder="주소"
                    v-model="address"
                    ref="address"
                  />
                  <input
                    type="text"
                    class="w_300"
                    placeholder="상세주소"
                    v-model="detailedAddress"
                    ref="detailedAddress"
                  />
                </td>
              </tr>
              <tr v-if="userType === 'S'">
                <th>상품유형<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="meetingroom"
                        v-model="meetingRoom"
                        @change="updateProductType"
                      />
                      <label for="meetingroom">회의실</label>
                    </div>
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="seat"
                        v-model="seat"
                        @change="updateProductType"
                      />
                      <label for="seat">자율좌석</label>
                    </div>
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="publicRoom"
                        v-model="publicRoom"
                        @change="updateProductType"
                      />
                      <label for="publicRoom">공용시설</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>아이디<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      placeholder="아이디를 입력해주세요"
                      v-model="workplaceAdminId"
                      ref="workplaceAdminId"
                      readonly
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="w_250"
                    placeholder="비밀번호를 입력해주세요"
                    v-model="workplaceAdminPw"
                    ref="workplaceAdminPw"
                  />
                  <label>
                    <input type="checkbox" v-model="showPassword" />
                    비밀번호 보이기
                  </label>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">담당자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="workplaceManagerName"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="workplaceManagerPhoneFirstPart">
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="workplaceManagerPhoneSecondPart"
                      ref="workplaceManagerPhoneSecondPart"
                    />
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="workplaceManagerPhoneThirdPart"
                      ref="workplaceManagerPhoneThirdPart"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>이메일주소</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이메일을 입력해주세요"
                    v-model="workplaceManagerEmail"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveWorkplace">
              저장
            </button>
            <button type="button" @click="DeleteCheck">삭제</button>
            <button type="button" @click="goToWorkplaceList">목록</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import debounce from "lodash.debounce"; // lodash의 debounce 함수 사용
import { useToast } from "vue-toastification"; 

export default {
  name: "WorkplaceView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companyId: "", // 회사아이디 입력값
      companyName: "", //회사이름
      workplaceId: "", // 사업장 아이디 입력값
      workplaceOwner: "", // 대표자명 입력값
      workplaceRegistrationNo: "", // 사업자등록번호 입력값
      regionCode: "02", // 지역번호 선택값
      middlePhoneNumber: "", // 대표번호 중간 입력값
      lastPhoneNumber: "", // 대표번호 마지막 입력값
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      workplaceProductType: "", // 상품유형 초기화
      meetingRoom: false, // 회의실 체크박스 상태
      seat: false, // 자율좌석 체크박스 상태
      publicRoom: false, // 공용시설 체크박스 상태
      workplaceAdminId: "", // 관리자 아이디
      workplaceAdminPw: "", // 관리자 비밀번호
      workplaceManagerName: "", // 담당자 이름
      workplaceManagerPhoneFirstPart: "010", // 담당자 휴대폰번호 첫 번째 부분
      workplaceManagerPhoneSecondPart: "", // 담당자 휴대폰번호 두 번째 부분
      workplaceManagerPhoneThirdPart: "", // 담당자 휴대폰번호 세 번째 부분
      workplaceManagerEmail: "", // 담당자 이메일주소
      isAdminIdNoAvailable: null,
      isRegistrationNoAvailable: null, // 사업자등록번호 사용가능 여부
      showPassword: false, // 비밀번호 보이기 설정
    };
  },
  watch: {
    workplaceRegistrationNo: debounce(function (newworkplaceRegistrationNo) {
      if (newworkplaceRegistrationNo) {
        this.checkWorkplaceRNAvailability(newworkplaceRegistrationNo);
      } else {
        this.isRegistrationNoAvailable = null;
      }
    }, 500), // 0.5초 간격으로 디바운스 적용
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.isRegistrationNoAvailable = null; // 초기화
    this.fetchWorkplaceData();
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
  },
  methods: {
    goToWorkplaceList() {
      this.$router.push({ path: "/workplace/list" });
    },
    updateProductType() {
      let types = [];
      if (this.meetingRoom) {
        types.push("M");
      }
      if (this.seat) {
        types.push("S");
      }
      if (this.publicRoom) {
        types.push("P");
      }
      this.workplaceProductType = types.join("^");
    },
    async fetchWorkplaceData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Workplace/workplace-info", {
          WorkplaceId: this.id,
        });
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.workplaceInfo = Array.isArray(data.data) ? data.data : [];

          // 회사정보 뿌려오기
          this.companyName = this.workplaceInfo[0].companyName;
          // 사업장 정보 뿌려오기
          this.workplaceName = this.workplaceInfo[0].workplaceName;
          this.workplaceOwner = this.workplaceInfo[0].workplaceOwner;
          this.workplaceRegistrationNo =
            this.workplaceInfo[0].workplaceRegistrationNo;

          // 전화번호 분할
          const phoneParts =
            this.workplaceInfo[0].workplaceTelephone.split("-");
          if (phoneParts.length === 3) {
            this.regionCode = phoneParts[0];
            this.middlePhoneNumber = phoneParts[1];
            this.lastPhoneNumber = phoneParts[2];
          }

          this.postalCode = this.workplaceInfo[0].workplaceZipCode;
          this.address = this.workplaceInfo[0].workplaceAddr1;
          this.detailedAddress = this.workplaceInfo[0].workplaceAddr2;

          // workplaceProductType
          const productType = this.workplaceInfo[0].workplaceProductType;
          this.workplaceProductType =
            this.workplaceInfo[0].workplaceProductType;
          this.meetingRoom = productType.includes("M");
          this.seat = productType.includes("S");
          this.publicRoom = productType.includes("P");

          this.workplaceAdminId = this.workplaceInfo[0].workplaceAdminId;
          this.workplaceAdminPw = this.workplaceInfo[0].workplaceAdminPw;

          this.workplaceManagerName = this.workplaceInfo[0].workplaceManager;

          // 담당자 휴대폰 번호 분할
          const managerphoneParts =
            this.workplaceInfo[0].workplaceManagerPhone.split("-");

          if (managerphoneParts.length === 3) {
            this.workplaceManagerPhoneFirstPart = managerphoneParts[0];
            this.workplaceManagerPhoneSecondPart = managerphoneParts[1];
            this.workplaceManagerPhoneThirdPart = managerphoneParts[2];
          }

          this.workplaceManagerEmail =
            this.workplaceInfo[0].workplaceManagerEmail;

          // 사업자등록번호 사용 가능 여부 초기화
          this.isRegistrationNoAvailable = null;
          this.isInitialLoad = false; // 초기 로드 완료
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    async checkWorkplaceRNAvailability(workplaceRegistrationNo) {
      try {
        const response = await apiClient.post(
          "/api/Workplace/check-workplace-registration-no-detail",
          {
            WorkplaceId: this.id,
            WorkplaceRegistrationNo: workplaceRegistrationNo,
          }
        );

        if (response.status === 200) {
          if (response.data.message == "F")
            this.isRegistrationNoAvailable = false;
          else this.isRegistrationNoAvailable = true;
        }
      } catch (error) {
        console.error(
          "Error checking company resistrationNO availability:",
          error
        );
        this.isRegistrationNoAvailable = false;
      }
    },
    formatResistrationNo() {
      // 숫자만 추출
      let numbers = this.workplaceRegistrationNo.replace(/\D/g, "");

      if (numbers.length > 3) {
        numbers = numbers.slice(0, 3) + "-" + numbers.slice(3);
      }
      if (numbers.length > 6) {
        numbers = numbers.slice(0, 6) + "-" + numbers.slice(6);
      }

      this.workplaceRegistrationNo = numbers;
    },
    DeleteCheck() {
      if (confirm("해당 사업장을 삭제하시겠습니까?")) {
        this.DeleteWorkplace();
      }
    },
    async DeleteWorkplace() {
      try {
        const response = await apiClient.post(
          "/api/Workplace/workplace-delete",
          {
            WorkplaceId: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          this.toast.success("사업장 정보가 성공적으로 삭제되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.goToWorkplaceList();
        }
      } catch (error) {
        console.error("Error deleting companies:", error);
		    this.toast.error("사업자 정보 삭제 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },

    async saveWorkplace() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post(
            "/api/Workplace/workplace-edit",
            {
              WorkPlaceId: this.id,
              WorkPlaceName: this.workplaceName,
              WorkPlaceOwner: this.workplaceOwner,
              WorkPlaceRegistrationNo: this.workplaceRegistrationNo,
              WorkplaceTelephone: `${this.regionCode}-${this.middlePhoneNumber}-${this.lastPhoneNumber}`,
              WorkPlaceZipCode: this.postalCode,
              WorkPlaceAddr1: this.address,
              WorkPlaceAddr2: this.detailedAddress,
              WorkplaceProductType: this.workplaceProductType,
              WorkplaceAdminId: this.workplaceAdminId,
              WorkplaceAdminPw: this.workplaceAdminPw,
              WorkplaceManager: this.workplaceManagerName,
              WorkplaceManagerPhone: `${this.workplaceManagerPhoneFirstPart}-${this.workplaceManagerPhoneSecondPart}-${this.workplaceManagerPhoneThirdPart}`,
              WorkplaceManagerEmail: this.workplaceManagerEmail,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
            }
          );
          if (response.status === 200) {
            this.toast.success("사업자 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToWorkplaceList();
          }
        } catch (error) {
          console.error("Error saving user data:", error);
          this.toast.error("사업자 정보 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.workplaceName) {
		    this.toast.error("사업장명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceName.focus();
        return false;
      }
      if (!this.workplaceOwner) {
		    this.toast.error("대표자명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceOwner.focus();
        return false;
      }
      if (!this.workplaceRegistrationNo) {
		    this.toast.error("사업자 등록번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceRegistrationNo.focus();
        return false;
      } else {
        // 숫자만 추출
        const numbers = this.workplaceRegistrationNo.replace(/[^0-9]/g, "");
        if (numbers.length < 10) {
          this.toast.error("사업자 등록번호는 10자리 숫자여야 합니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.$refs.workplaceRegistrationNo.focus();
          return false;
        }
      }
      if (this.isRegistrationNoAvailable === false) {
		    this.toast.error("사용 불가능한 사업자 등록번호입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceRegistrationNo.focus();
        return false;
      }
      if (!this.regionCode) {
		    this.toast.error("지역번호를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.regionCode.focus();
        return false;
      }
      if (!this.middlePhoneNumber || this.middlePhoneNumber.length < 3) {
		    this.toast.error("대표번호 중간 번호 3자리 이상 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.middlePhoneNumber.focus();
        return false;
      }
      if (!this.lastPhoneNumber || this.lastPhoneNumber.length !== 4) {
		    this.toast.error("대표번호 마지막 번호 4자리를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.lastPhoneNumber.focus();
        return false;
      }
      if (!this.postalCode) {
		    this.toast.error("우편번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.postalCode.focus();
        return false;
      }
      if (!this.address) {
		    this.toast.error("주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.address.focus();
        return false;
      }
      if (!this.detailedAddress) {	
		    this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.detailedAddress.focus();
        return false;
      }
      if (this.userType === "S" && !this.workplaceProductType) {
		    this.toast.error("상품유형을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.workplaceAdminPw) {
		    this.toast.error("비밀번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceAdminPw.focus();
        return false;
      }
      if (
        this.workplaceManagerEmail &&
        !this.isValidEmail(this.workplaceManagerEmail)
      ) {
		    this.toast.error("유효한 이메일 주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceManagerEmail.focus();
        return false;
      }
      return true;
    },
    isValidEmail(email) {
      // 이메일 유효성 검사 정규식
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
};
</script>

<style scoped></style>
