<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 등록</div>
        <div class="description">이용약관을 등록합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>현행여부<span class="text_red">*</span></th>
                <td>
                  <input type="checkbox" id="isCurrent" v-model="isCurrent" />
                  <label for="isCurrent"> 현행</label>
                </td>
              </tr>
              <tr>
                <th>표시상태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      @click="setActiveStatus('Y')"
                      :class="{ on: activeStatus === 'Y' }"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      @click="setActiveStatus('N')"
                      :class="{ on: activeStatus === 'N' }"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>시행일<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="date"
                      class="w_250"
                      v-model="effectiveDate"
                      ref="effectiveDate"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td textarea_full">
                    <textarea
                      class="h_400"
                      v-model="termsContents"
                      ref="termsContents"
                    ></textarea>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveTermofuse">
              등록
            </button>
            <button type="button" @click="goToTermofuseList">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingTermsofuseAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      termsContents: "",
      activeStatus: "", // 표시상태 값
      effectiveDate: "", // 시행일 값
      isCurrent: true, // 현행여부 기본값
    };
  },
  methods: {
    goToTermofuseList() {
      this.$router.push({ path: "/setting/termsofuse/list" });
    },
    setActiveStatus(status) {
      this.activeStatus = status;
    },
    async saveTermofuse() {
      if (this.checkFields()) {
        try {
          const currentStatus = this.isCurrent ? "Y" : "N";

          const response = await apiClient.post("/api/TermsofUse/terms-add", {
            ActiveStatus: this.activeStatus,
            SelDate: this.effectiveDate,
            TermsContents: this.termsContents,
            CurrentYN: currentStatus, // 현행여부 추가
            LoginId: localStorage.getItem("userid"),
          });
          if (response.status === 200) {
            this.toast.success("이용 약관이 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToTermofuseList();
          }
        } catch (error) {
          console.error("Error saving user data:", error);
		      this.toast.error("이용 약관 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.activeStatus) {
		    this.toast.error("표시상태를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.effectiveDate) {
		    this.toast.error("시행일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.termsContents) {
		    this.toast.error("내용을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.termsContents.focus();
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.date-input {
  cursor: pointer;
  pointer-events: auto;
}

.date-input::-webkit-calendar-picker-indicator {
  opacity: 1;
  position: absolute;
}
</style>
