<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">직원 목록</div>
        <div class="description">직원 내역을 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>재직상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        재직
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        퇴사
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- 일단 인증수단별은 패스 (출입통제쪽이니) -->
                <tr style="display: none">
                  <th>인증수단별</th>
                  <td>
                    <div class="inner_td">
                      <button type="button" class="on">전체보기</button>
                      <button type="button">카드(QR)</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="userName">이름</option>
                        <option value="userDeptName">소속</option>
                        <option value="userPosition">직책</option>
                        <option value="userMobile">휴대폰번호</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalEmployees }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
            <button type="button" @click="goToEmployeeAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="employeeList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 15%" />
                  <col />
                  <col style="width: 12%" />
                  <col style="width: 14%" />
                  <col style="width: 10%" />
                  <col style="width: 12%" />
                  <col style="width: 8%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회사명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">사업장</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">소속</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>직책</th>
                    <th>휴대폰번호</th>
                    <th>노쇼</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="employee in paginatedEmployees"
                    :key="employee.requestJoinId"
                    class="no_move"
                    :data-user-id="employee.userId"
                    :data-company-id="employee.companyId"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(employee.requestJoinId)"
                        :checked="
                          selectedEmployees.includes(employee.requestJoinId)
                        "
                      />
                    </td>
                    <td class="no_mobile">{{ employee.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ employee.companyName }}</span>
                    </td>
                    <td class="seat inner_td o4r">
                      <span class="only_mobile">사업장 : </span>
                      <p class="long_p">{{ employee.workplaceName }}</p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <router-link
                          :to="{
                            name: 'Employeeview',
                            params: { id: employee.requestJoinId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ employee.userName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">소속 : </span>
                      <span>{{ employee.deptName }}</span>
                    </td>
                    <td class="phone o6r">
                      <span class="only_mobile">직책 : </span>
                      <span>{{ employee.position }}</span>
                    </td>
                    <td class="num phone o7">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>{{ employee.userPhone }}</span>
                    </td>
                    <td class="num phone o8r">
                      <span class="only_mobile">노쇼 : </span>
                      <span
                        :style="
                          employee.noShowCnt > 0
                            ? { color: 'red', fontWeight: 'bold' }
                            : {}
                        "
                        >{{ employee.noShowCnt }}</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 15%" />
                  <col />
                  <col style="width: 12%" />
                  <col style="width: 14%" />
                  <col style="width: 10%" />
                  <col style="width: 12%" />
                  <col style="width: 8%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회사명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">사업장</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">소속</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>직책</th>
                    <th>휴대폰번호</th>
                    <th>노쇼</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <button type="button" @click.prevent="withDrawSelected">
                강제탈퇴
              </button>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "EmployeeList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      employeeList: [], // 직원 목록 데이터를 저장할 배열
      selectedEmployees: [], // 선택된 직원 목록의 requestjoinid를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "userName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalEmployees: 0, // 총 직원 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
    };
  },
  watch: {},
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    paginatedEmployees() {
      if (!Array.isArray(this.employeeList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.employeeList);
      console.log(
        "Paginated employeeList: ",
        this.employeeList.slice(start, end)
      );
      return this.employeeList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalEmployees / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    withDrawSelected() {
      if (this.selectedEmployees.length === 0) {        		
		    this.toast.error("탈퇴 처리를 원하시는 직원 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return;
      }

      const message =
        "선택된 직원을 강제탈퇴 처리하시겠습니까?\n\n" +
        "( 사업장을 여러개 가지고 있는 직원이라면\n " +
        "다른 사업장 또한 사용 불가가 됩니다. )";

      if (confirm(message)) {
        this.UpdateWithdraw(this.selectedEmployees);
      }
    },
    async UpdateWithdraw(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Employee/employee-withdraw-update", {
            RequestJoinId: id,
            LoginId: localStorage.getItem("userid"),
            // ApprovalStatus: "Y",
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to employee-withdraw-update for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedEmployees = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error request-join-status-update:", error);
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedEmployees = this.employeeList.map(
          (employee) => employee.requestJoinId
        );
      } else {
        this.selectedEmployees = [];
      }
    },
    toggleSelection(requestJoinId) {
      // 현재 선택된 직원 정보를 가져옵니다.
      const selectedEmployee = this.employeeList.find(
        (employee) => employee.requestJoinId === requestJoinId
      );

      if (!selectedEmployee) return;

      // 선택된 직원과 동일한 user_id와 company_id를 가진 행을 찾습니다.
      const relatedEmployees = this.employeeList.filter(
        (employee) =>
          employee.userId === selectedEmployee.userId &&
          employee.companyId === selectedEmployee.companyId
      );

      // 현재 행이 선택된 상태인지 확인합니다.
      const isSelected = this.selectedEmployees.includes(requestJoinId);

      if (isSelected) {
        // 선택 해제: 관련된 모든 행을 선택 해제합니다.
        relatedEmployees.forEach((employee) => {
          const index = this.selectedEmployees.indexOf(employee.requestJoinId);
          if (index > -1) {
            this.selectedEmployees.splice(index, 1);
          }
        });
      } else {
        // 선택: 관련된 모든 행을 선택합니다.
        relatedEmployees.forEach((employee) => {
          if (!this.selectedEmployees.includes(employee.requestJoinId)) {
            this.selectedEmployees.push(employee.requestJoinId);
          }
        });
      }

      // const index = this.selectedEmployees.indexOf(requestJoinId);

      // if (index > -1) {
      //   this.selectedEmployees.splice(index, 1);
      // } else {
      //   this.selectedEmployees.push(requestJoinId); // 기존 코드를 이 줄로 수정
      // }
    },
    goToEmployeeAdd() {
      this.$router.push({ path: "/employee/add" });
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.employeeList = []; // 회사 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.employeeList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.employeeList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.employeeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.employeeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.employeeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.employeeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.employeeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Employee/all-employee-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.employeeList = Array.isArray(data.data) ? data.data : [];
          this.totalEmployees = data.meta.totalCount || 0;

          // 기존 데이터와 새로운 데이터를 비교하여 계속 새로운 데이터를 불러와야 할때 아래처럼 하면 됨
          // if (JSON.stringify(this.noticeList) !== JSON.stringify(data.data)) {
          //   this.noticeList = []; // 기존 데이터 비움
          //   this.$nextTick(() => {
          //     this.noticeList = Array.isArray(data.data) ? data.data : [];
          //     this.totalNotices = data.meta.totalCount || 0;
          //   });
          // } else {
          //   this.noticeList = Array.isArray(data.data) ? data.data : [];
          //   this.totalNotices = data.meta.totalCount || 0;
          // }
        }
      } catch (error) {
        console.error("Error fetching requestjoinList data:", error);
      }
    },
  },
  mounted() {
    if (this.userType === "C") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      const workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");

      // 회사 및 사업장 정보를 설정
      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      // 데이터를 바로 가져옴
      this.fetchData();
    } else {
      this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
