<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">상품분류 상세보기</div>
        <div class="description">
          상품분류를 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      :value="selectedCompanyName"
                      readonly
                    />
                    <!-- <button type="button" class="keep">선택</button> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    :value="selectedWorkplaceName"
                    readonly
                  />
                </td>
              </tr>
              <!-- 회사, 사업장 선택 시 나타남 -->
              <tr>
                <th>상품유형<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('M')"
                      :class="{
                        on:
                          productTypeSelect === 'M' ||
                          selectedWorkplaceProductType === 'M',
                      }"
                      @click="setProductTypeSelect('M')"
                    >
                      회의실
                    </button>
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('S')"
                      :class="{
                        on:
                          productTypeSelect === 'S' ||
                          selectedWorkplaceProductType === 'S',
                      }"
                      @click="setProductTypeSelect('S')"
                    >
                      자율좌석
                    </button>
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('P')"
                      :class="{
                        on:
                          productTypeSelect === 'P' ||
                          selectedWorkplaceProductType === 'P',
                      }"
                      @click="setProductTypeSelect('P')"
                    >
                      공용시설
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>분류이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="분류이름을 입력해주세요"
                    v-model="productCategoryName"
                    ref="productCategoryName"
                  />
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <div class="inner_td">
                    <textarea
                      placeholder="상세 설명을 입력해주세요"
                      v-model="detailDesc"
                      ref="detailDesc"
                    ></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상위분류<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td open_list">
                    <!-- '선택하기' 클릭 시 id="Modal" 나타남 -->
                    <button
                      type="button"
                      v-if="this.userId === getcreateUserID"
                      @click="showModal2"
                    >
                      선택하기
                    </button>
                    <ul class="attendee_add group_choice">
                      <li>
                        <div>
                          <span v-if="selectedParentName">{{
                            selectedParentName
                          }}</span>
                          <span v-else>없음</span>
                        </div>
                        <a
                          href="###"
                          v-if="selectedParentName"
                          @click="clearParentCategory"
                          >⨉</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">진열정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: activeStatusSelect === 'Y' }"
                      @click="setActiveStatusSelect('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: activeStatusSelect === 'N' }"
                      @click="setActiveStatusSelect('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>정렬옵션</th>
                <td>
                  <div class="inner_td">
                    <select v-model="sortOption" class="w_250">
                      <option value="recent_desc">최근 등록상품이 위로</option>
                      <option value="recent_asc">최근 등록상품이 아래로</option>
                      <option value="modified_desc">
                        최근 수정상품이 위로
                      </option>
                      <option value="modified_asc">
                        최근 수정상품이 아래로
                      </option>
                      <option value="name_asc">상품명 가나다순</option>
                      <option value="name_desc">상품명 가나다역순</option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <!-- <a class="on" @click="saveProductcategory">저장</a>
            <a @click="DeleteCheck">삭제</a> -->
            <!-- 저장 버튼 -->
            <a
              v-if="this.userId === getcreateUserID"
              class="on"
              @click="saveProductcategory"
              >저장</a
            >

            <!-- 삭제 버튼 -->
            <a v-if="this.userId === getcreateUserID" @click="DeleteCheck"
              >삭제</a
            >

            <a @click="goToProductCategoryList">목록</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 상위분류 선택 modal -->
  <div id="Modal2" class="d_pop" v-if="isModal2Visible">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">상위분류 선택</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'A' }"
                      @click="setModal2Status('A')"
                    >
                      전체
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'Y' }"
                      @click="setModal2Status('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'N' }"
                      @click="setModal2Status('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      tyoe="text"
                      class="w_200"
                      v-model="SearchKey2"
                      placeholder="분류명을 입력해주세요"
                      @keyup.enter="searchProductcategorys"
                    />
                    <button type="button" @click="searchProductcategorys">
                      검색
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>분류명</th>
                <th>표시상태</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="productCategory in filteredProductCategorys"
                :key="productCategory.id"
              >
                <td>{{ productCategory.productName }}</td>
                <td>
                  {{ productCategory.activeStatus === "Y" ? "표시" : "미표시" }}
                </td>
                <td>
                  <button
                    class="keep"
                    @click="selectParentCategory(productCategory)"
                  >
                    선택
                  </button>
                </td>
              </tr>
              <!-- <tr>
                <td>27층</td>
                <td>표시</td>
                <td><button class="keep">선택</button></td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup2"
              @click.prevent="prevPageGroup2"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage2" @click.prevent="prevPage2"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page2 in pageGroup2"
              :key="page2"
              href="###"
              :class="{ on: currentPage2 === page2 }"
              @click.prevent="goToPage2(page2)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage2" @click.prevent="nextPage2"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a
              href="###"
              v-if="hasNextPageGroup2"
              @click.prevent="nextPageGroup2"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //상위분류 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "ProductCategoryView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedParentId: null, // 선택된 상위분류 ID
      selectedParentName: "", // 선택된 상위분류 이름
      sortOption: "", // 정렬 옵션의 기본값 설정
      isModalVisible: false, // 회사 선택 모달
      isModal2Visible: false, // 상위분류 선택 모달 상태
      modal2Status: "A", // 상위분류 모달의 상태 기본값을 '전체'로 설정
      companyworkplaces: [], // API 에서 가져온 회사-사업장 목록 데이터를 저장할 배열
      productcategorys: [], // API 에서 가져온 상품분류 목록 데이터를 저장할 배열
      productTypeSelect: "", // 상품유형 '회의실-Meeting','자율좌석-Seat'
      activeStatusSelect: "", // 표시상태 '표시-Y', '미표시-N'
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      totalCompanyWorkplaces: 0, // 총 회사-사업자 수
      selectedCompanyName: "", // 선택된 회사명
      selectedWorkplaceName: "", // 선택된 사업장명
      selectedCompanyId: 0, // 선택된 회사 ID
      selectedWorkplaceId: 0, // 선택된 사업장 ID
      selectedWorkplaceProductType: "", // 선택된 사업장의 상품유형
      getcreateUserID: "",
      SearchType2: "productName",
      SearchKey2: "", // 검색어를 입력받기 위한 변수
      filteredProductCategorys: [], // 검색된 결과를 저장할 배열
      pageGroupSize2: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentPage2: 1, // 현재 페이지
      itemsPerPage2: 5, // 페이지 당 항목 수
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    userId() {
      return this.$store.getters.getUserid;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchProductCategoryData();
    this.loadProductcategorys();
  },
  methods: {
    searchProductcategorys() {
      this.loadProductcategorys();
    },
    selectParentCategory(category) {
      this.selectedParentId = category.id;
      this.selectedParentName = category.productName;
      this.hideModal2(); // 모달 닫기
    },
    setModal2Status(status) {
      this.modal2Status = status;
      this.loadProductcategorys();
    },
    goToPage2(page) {
      this.currentPage2 = page;
      this.productcategorys = []; // 페이지 변경 시 목록 초기화
      this.loadProductcategorys();
    },
    showModal2() {
      this.isModal2Visible = true;
      this.SearchKey2 = ""; // 검색어 초기화
      this.filteredProductCategorys = [...this.productcategorys]; // 검색 결과 초기화
    },
    hideModal2() {
      this.isModal2Visible = false;
    },
    clearParentCategory() {
      this.selectedParentId = null;
      this.selectedParentName = "";
    },
    goToProductCategoryList() {
      this.$router.push({ path: "/product/category/list" });
    },
    DeleteCheck() {
      if (confirm("해당 상품분류를 삭제하시겠습니까?")) {
        this.DeleteProductCategory();
      }
    },
    async DeleteProductCategory() {
      try {
        const response = await apiClient.post("/api/Product/product-delete", {
          Id: this.id,
          LoginId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          this.toast.success("상품 분류가 성공적으로 삭제되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
		
          this.goToProductCategoryList();
        }
      } catch (error) {
        console.error("Error deleting products:", error);
		    this.toast.error("상품 분류 삭제 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    async loadProductcategorys() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: "",
          SearchActiveStatus: this.modal2Status, // 상태별 값 추가
          SearchType: this.SearchType2,
          SearchKey: this.SearchKey2,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Product/all-product-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.productcategorys = Array.isArray(data.data) ? data.data : [];
          this.totalProductCategorys = data.meta.totalCount || 0;
          this.filteredProductCategorys = this.productcategorys;
        }
      } catch (error) {
        console.error("Error loading productcategorys:", error);
      }
    },
    async fetchProductCategoryData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Product/product-info", {
          Id: this.id,
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.productcategoryInfo = Array.isArray(data.data) ? data.data : [];

          this.selectedCompanyId = this.productcategoryInfo[0].companyId;
          this.selectedCompanyName = this.productcategoryInfo[0].companyName;
          this.selectedWorkplaceId = this.productcategoryInfo[0].workplaceId;
          this.selectedWorkplaceName = this.productcategoryInfo[0].workplaceName;
          this.selectedWorkplaceProductType = this.productcategoryInfo[0].productType;
          this.productCategoryName = this.productcategoryInfo[0].productName;
          this.detailDesc = this.productcategoryInfo[0].productDesc;
          this.sortOption = this.productcategoryInfo[0].orderOption;
          this.activeStatusSelect = this.productcategoryInfo[0].activeStatus;
          this.selectedParentId = this.productcategoryInfo[0].parentId;
          this.selectedParentName = this.productcategoryInfo[0].parentName;
          this.getcreateUserID = this.productcategoryInfo[0].createUserID;
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    async saveProductcategory() {
      try {
        const response = await apiClient.post("/api/Product/product-edit", {
          Id: this.id,
          ProductType: this.selectedWorkplaceProductType, // 상품유형 '회의실-Meeting','자율좌석-Seat'
          ProductName: this.productCategoryName,
          ProductDesc: this.detailDesc,
          ParentId: this.selectedParentId, // 선택된 상위분류 ID 추가
          ActiveStatus: this.activeStatusSelect, // 표시상태 '표시-Y', '미표시-N'
          OrderOption: this.sortOption,
          LoginId: localStorage.getItem("userid"),
          // 필요한 다른 필드 추가
        });
        if (response.status === 200) {
          this.toast.success("상품 분류가 성공적으로 수정되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
		
          this.goToProductCategoryList();
        }
      } catch (error) {
        console.error("Error saving products data:", error);
		    this.toast.error("상품 분류 수정 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    setProductTypeSelect(status) {
      this.productTypeSelect = status;
    },
    setActiveStatusSelect(status) {
      this.activeStatusSelect = status;
    },
  },
};
</script>

<style scoped></style>
