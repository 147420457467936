<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사업장 등록</div>
        <div class="description">
          사업장을 등록합니다. <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250"
                      placeholder="회사명을 선택해주세요"
                      v-model="selectedCompanyName"
                      readonly
                    />
                    <button type="button" class="keep" @click="showModal">
                      선택
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업자명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="사업자명을 입력해주세요"
                    v-model="workplaceName"
                    ref="workplaceName"
                  />
                </td>
              </tr>
              <tr>
                <th>대표자명<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <input
                    type="text"
                    class="w_250"
                    placeholder="대표자명을 입력해주세요"
                    v-model="workplaceOwner"
                    ref="workplaceOwner"
                  />
                </td>
              </tr>
              <tr>
                <th>사업자등록번호<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="사업자등록번호를 입력해주세요"
                      v-model="workplaceRegistrationNo"
                      @input="formatResistrationNo"
                      ref="workplaceRegistrationNo"
                      maxlength="12"
                    />
                    <p
                      class="text_red"
                      v-if="isRegistrationNoAvailable === false"
                    >
                      사용 불가능
                    </p>
                    <p
                      class="text_green"
                      v-if="isRegistrationNoAvailable === true"
                    >
                      사용 가능
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>대표번호<span class="text_red">*</span></th>
                <td>
                  <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                  <div class="inner_td mobile_number">
                    <select v-model="regionCode">
                      <option>02</option>
                      <option>031</option>
                      <option>032</option>
                      <option>033</option>
                      <option>041</option>
                      <option>042</option>
                      <option>043</option>
                      <option>044</option>
                      <option>051</option>
                      <option>052</option>
                      <option>053</option>
                      <option>054</option>
                      <option>055</option>
                      <option>061</option>
                      <option>062</option>
                      <option>063</option>
                      <option>064</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="middlePhoneNumber"
                      maxlength="4"
                      ref="middlePhoneNumber"
                    />
                    -
                    <input
                      type="text"
                      class="w_80"
                      v-model="lastPhoneNumber"
                      maxlength="4"
                      ref="lastPhoneNumber"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소<span class="text_red">*</span></th>
                <td class="td_address">
                  <div>
                    <input
                      type="text"
                      class="w_150"
                      placeholder="우편번호"
                      v-model="postalCode"
                      ref="postalCode"
                      readonly
                    />
                    <button
                      type="button"
                      class="keep"
                      @click="openPostcodePopup"
                    >
                      주소찾기
                    </button>
                  </div>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="주소"
                    v-model="address"
                    ref="address"
                  />
                  <input
                    type="text"
                    class="w_250"
                    placeholder="상세주소"
                    v-model="detailedAddress"
                    ref="detailedAddress"
                  />
                </td>
              </tr>
              <tr v-if="userType === 'S'">
                <th>상품유형<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="meetingroom"
                        v-model="meetingRoom"
                        @change="updateProductType"
                      />
                      <label for="meetingroom">회의실</label>
                    </div>
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="seat"
                        v-model="seat"
                        @change="updateProductType"
                      />
                      <label for="seat">자율좌석</label>
                    </div>
                    <div class="inner_td">
                      <input
                        type="checkbox"
                        id="publicRoom"
                        v-model="publicRoom"
                        @change="updateProductType"
                      />
                      <label for="publicRoom">공용시설</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>아이디<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="아이디를 입력해주세요"
                      v-model="workplaceAdminId"
                      ref="workplaceAdminId"
                    />
                    <p class="text_red" v-if="isAdminIdNoAvailable === false">
                      사용 불가능
                    </p>
                    <p class="text_green" v-if="isAdminIdNoAvailable === true">
                      사용 가능
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="w_250"
                    placeholder="비밀번호를 입력해주세요"
                    v-model="workplaceAdminPw"
                    ref="workplaceAdminPw"
                  />
                  <label>
                    <input type="checkbox" v-model="showPassword" />
                    비밀번호 보이기
                  </label>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">담당자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="workplaceManagerName"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="workplaceManagerPhoneFirstPart">
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="workplaceManagerPhoneSecondPart"
                    />
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      v-model="workplaceManagerPhoneThirdPart"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>이메일주소</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이메일을 입력해주세요"
                    v-model="workplaceManagerEmail"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveWorkplace">
              등록
            </button>
            <button type="button" @click="goToWorkplaceList">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사/사업장 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box" style="width: 800px">
      <div class="d_pop_top">
        <div class="title">회사 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="SearchType">
                      <option value="companyName">회사명</option>
                      <option value="companyOwner">대표자명</option>
                      <option value="companyAddr1">주소</option>
                    </select>
                    <input
                      tyoe="text"
                      class="w_200"
                      placeholder="검색어를 입력해주세요"
                      v-model="SearchKey"
                      @keyup.enter="searchCompany"
                    />
                    <button type="button" @click="searchCompany">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 30%" />
              <col />
              <col style="width: 10%" />
            </colgroup>
            <thead>
              <tr>
                <th>회사명</th>
                <th>대표자명</th>
                <th>주소</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="company in paginatedCompanies" :key="company.id">
                <td>{{ company.companyName }}</td>
                <td>{{ company.companyOwner }}</td>
                <td class="long_txt">
                  {{ company.companyAddr1 }}
                </td>
                <td>
                  <button @click="selectCompany(company)" class="keep">
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //회사/사업장 선택 modal -->
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import debounce from "lodash.debounce"; // lodash의 debounce 함수 사용
import { useToast } from "vue-toastification"; 

export default {
  name: "WorkplaceAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companyId: "", // 회사아이디 입력값
      workplaceId: "", // 사업장 아이디 입력값
      workplaceOwner: "", // 대표자명 입력값
      workplaceRegistrationNo: "", // 사업자등록번호 입력값
      regionCode: "02", // 지역번호 선택값
      middlePhoneNumber: "", // 대표번호 중간 입력값
      lastPhoneNumber: "", // 대표번호 마지막 입력값
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      workplaceProductType: "", // 상품유형 초기화
      meetingRoom: false, // 회의실 체크박스 상태
      seat: false, // 자율좌석 체크박스 상태
      publicRoom: false, // 공용시설 체크박스 상태
      workplaceAdminId: "", // 관리자 아이디
      workplaceAdminPw: "", // 관리자 비밀번호
      workplaceManagerName: "", // 담당자 이름
      workplaceManagerPhoneFirstPart: "010", // 담당자 휴대폰번호 첫 번째 부분
      workplaceManagerPhoneSecondPart: "", // 담당자 휴대폰번호 두 번째 부분
      workplaceManagerPhoneThirdPart: "", // 담당자 휴대폰번호 세 번째 부분
      workplaceManagerEmail: "", // 담당자 이메일주소
      isAdminIdNoAvailable: null,
      isRegistrationNoAvailable: null, // 사업자등록번호 사용가능 여부
      showPassword: false, // 비밀번호 보이기 설정
      isModalVisible: false, // 모달 표시 상태
      companies: [], // 모달에 표시할 회사 목록
      totalCompanys: 0, // 총 회사 수
      SearchType: "companyName", // 검색 기준
      SearchKey: "", // 검색 키워드
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
    };
  },
  mounted() {
    this.loadCompanies();
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalCompanys / this.itemsPerPage);
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedCompanies() {
      if (!Array.isArray(this.companies)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log("Paginated Companies: ", this.companies.slice(start, end));
      return this.companies.slice(start, end);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  watch: {
    workplaceRegistrationNo: debounce(function (newworkplaceRegistrationNo) {
      if (newworkplaceRegistrationNo) {
        this.checkWorkplaceRNAvailability(newworkplaceRegistrationNo);
      } else {
        this.isRegistrationNoAvailable = null;
      }
    }, 500), // 0.5초 간격으로 디바운스 적용
    workplaceAdminId: debounce(function (newworkplaceAdminId) {
      if (newworkplaceAdminId) {
        this.checkWorkplaceAdminIdvailability(newworkplaceAdminId);
      } else {
        this.isAdminIdNoAvailable = null;
      }
    }, 500), // 0.5초 간격으로 디바운스 적용
  },
  methods: {
    updateProductType() {
      let types = [];
      if (this.meetingRoom) {
        types.push("M");
      }
      if (this.seat) {
        types.push("S");
      }
      if (this.publicRoom) {
        types.push("P");
      }
      this.workplaceProductType = types.join("^");
    },
    async loadCompanies() {
      try {
        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          OrderType: this.OrderType,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
        };
        console.log(searchParam);
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
          this.totalCompanys = data.meta.totalCount || 0;
          console.log(this.totalCompanys);
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    goToWorkplaceList() {
      this.$router.push({ path: "/workplace/list" });
    },
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    selectCompany(company) {
      this.companyId = company.id;
      this.selectedCompanyName = company.companyName;
      this.hideModal();
    },
    async checkWorkplaceAdminIdvailability(workplaceAdminId) {
      try {
        const response = await apiClient.post(
          "/api/Workplace/check-workplace-adminid",
          {
            WorkplaceAdminId: workplaceAdminId,
          }
        );

        if (response.status === 200) {
          if (response.data.message == "F") this.isAdminIdNoAvailable = false;
          else this.isAdminIdNoAvailable = true;
        }
      } catch (error) {
        console.error(
          "Error checking company resistrationNO availability:",
          error
        );
        this.isAdminIdNoAvailable = false;
      }
    },
    async checkWorkplaceRNAvailability(workplaceRegistrationNo) {
      try {
        const response = await apiClient.post(
          "/api/Workplace/check-workplace-registration-no",
          {
            WorkplaceRegistrationNo: workplaceRegistrationNo,
          }
        );

        if (response.status === 200) {
          if (response.data.message == "F")
            this.isRegistrationNoAvailable = false;
          else this.isRegistrationNoAvailable = true;
        }
      } catch (error) {
        console.error(
          "Error checking company resistrationNO availability:",
          error
        );
        this.isRegistrationNoAvailable = false;
      }
    },
    async saveWorkplace() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post(
            "/api/Workplace/workplace-add",
            {
              CompanyId: this.companyId,
              WorkplaceName: this.workplaceName,
              WorkplaceOwner: this.workplaceOwner,
              WorkplaceRegistrationNo: this.workplaceRegistrationNo,
              WorkplaceTelephone: `${this.regionCode}-${this.middlePhoneNumber}-${this.lastPhoneNumber}`,
              WorkplaceZipCode: this.postalCode,
              WorkplaceAddr1: this.address,
              WorkplaceAddr2: this.detailedAddress,
              WorkplaceProductType: this.workplaceProductType,
              WorkplaceAdminId: this.workplaceAdminId,
              WorkplaceAdminPw: this.workplaceAdminPw,
              WorkplaceManager: this.workplaceManagerName,
              WorkplaceManagerPhone: `${this.workplaceManagerPhoneFirstPart}-${this.workplaceManagerPhoneSecondPart}-${this.workplaceManagerPhoneThirdPart}`,
              WorkplaceManagerEmail: this.workplaceManagerEmail,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
            }
          );
          if (response.status === 200) {
            this.toast.success("사업장 정보가 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToWorkplaceList();
          }
        } catch (error) {
          console.error("Error saving user data:", error);
		      this.toast.error("사업장 정보가 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    formatResistrationNo() {
      // 숫자만 추출
      let numbers = this.workplaceRegistrationNo.replace(/\D/g, "");

      if (numbers.length > 3) {
        numbers = numbers.slice(0, 3) + "-" + numbers.slice(3);
      }
      if (numbers.length > 6) {
        numbers = numbers.slice(0, 6) + "-" + numbers.slice(6);
      }

      this.workplaceRegistrationNo = numbers;
    },
    checkFields() {
      if (!this.companyId) {
		    this.toast.error("회사를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.isModalVisible = true;
        return false;
      }

      if (!this.workplaceName) {
		    this.toast.error("사업장명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceName.focus();
        return false;
      }

      if (!this.workplaceOwner) {
		    this.toast.error("대표자명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceOwner.focus();
        return false;
      }

      if (!this.workplaceRegistrationNo) {	
		    this.toast.error("사업자 등록번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceRegistrationNo.focus();
        return false;
      } else {
        // 숫자만 추출
        const numbers = this.workplaceRegistrationNo.replace(/[^0-9]/g, "");
        if (numbers.length < 10) {	
          this.toast.error("사업자 등록번호는 10자리 숫자여야 합니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.$refs.workplaceRegistrationNo.focus();
          return false;
        }
      }

      if (this.isRegistrationNoAvailable === false) {
		    this.toast.error("사용 불가능한 사업자 등록번호입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceRegistrationNo.focus();
        return false;
      }

      if (!this.regionCode) {	
		    this.toast.error("지역번호를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.regionCode.focus();
        return false;
      }
      if (!this.middlePhoneNumber || this.middlePhoneNumber.length < 3) {
		    this.toast.error("대표번호 중간 번호 3자리 이상 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.middlePhoneNumber.focus();
        return false;
      }
      if (!this.lastPhoneNumber || this.lastPhoneNumber.length !== 4) {
		    this.toast.error("대표번호 마지막 번호 4자리를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.lastPhoneNumber.focus();
        return false;
      }

      if (!this.postalCode) {
		    this.toast.error("우편번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.postalCode.focus();
        return false;
      }
      if (!this.address) {
		    this.toast.error("주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.address.focus();
        return false;
      }
      if (!this.detailedAddress) {
		    this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.detailedAddress.focus();
        return false;
      }

      if (this.userType === "S" && !this.workplaceProductType) {
		    this.toast.error("상품유형을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.workplaceAdminId) {
		    this.toast.error("아이디를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceAdminId.focus();
        return false;
      }
      if (!this.workplaceAdminPw) {
		    this.toast.error("비밀번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceAdminPw.focus();
        return false;
      }

      if (
        this.workplaceManagerEmail &&
        !this.isValidEmail(this.workplaceManagerEmail)
      ) {
		    this.toast.error("유효한 이메일 주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.workplaceManagerEmail.focus();
        return false;
      }

      return true;
    },
    isValidEmail(email) {
      // 이메일 유효성 검사 정규식
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadCompanies(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadCompanies(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      if (this.hasNextPageGroup) {
        this.currentPage = this.pageGroup[this.pageGroup.length - 1] + 1;
        this.loadCompanies(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup() {
      if (this.hasPrevPageGroup) {
        this.currentPage = this.pageGroup[0] - 1;
        this.loadCompanies(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    async searchCompany() {
      this.currentPage = 1;
      this.companies = []; // 페이지 변경 시 목록 초기화
      this.loadCompanies();
    },
    goToPage(page) {
      this.currentPage = page;
      this.companies = []; // 페이지 변경 시 목록 초기화
      this.loadCompanies();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.companies = []; // 페이지 변경 시 목록 초기화
      this.loadCompanies();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.companies = []; // 페이지 변경 시 목록 초기화
      this.loadCompanies();
    },
  },
};
</script>

<style scoped></style>
