<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_72.png" alt="" />사업장관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/workplace/list"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
          >
            사업장 목록
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Workplace_Left",
  data() {
    return {
      selectedMenu: null,
    };
  },
  computed: {
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", menuPath); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (path === "/workplace/management" || path === "/workplace/list") {
        this.selectedMenu = 0;
      } else if (path === "/workplace/add") {
        this.selectedMenu = 0;
      } else if (path === "/workplace/view") {
        this.selectedMenu = 0;
      }
      this.$store.dispatch("updateSelectedMenu", "/workplace/management"); // Vuex 상태 업데이트
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
