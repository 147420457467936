<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_74.png" alt="" />직원관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/employee/list"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
          >
            직원목록
          </router-link>
        </li>
        <!-- <li>
          <router-link
            to="/employee/add"
            :class="{ on: selectedMenu === 1 }"
            @click="selectMenu(1)"
          >
            직원등록
          </router-link>
        </li> -->
        <!-- <li>
          <router-link
            to="/employee/view"
            :class="{ on: selectedMenu === 2 }"
            @click="selectMenu(2)"
          >
            직원 상세보기
          </router-link>
        </li> -->
        <li>
          <router-link
            to="/employee/dept/list"
            :class="{ on: selectedMenu === 3 }"
            @click="selectMenu(3)"
          >
            소속목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/employee/requestjoin/list"
            :class="{ on: selectedMenu === 4 }"
            @click="selectMenu(4)"
          >
            가입요청 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/employee/withdraw/list"
            :class="{ on: selectedMenu === 5 }"
            @click="selectMenu(5)"
          >
            직원탈퇴 목록
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Employee_Left",
  data() {
    return {
      selectedMenu: null,
    };
  },
  computed: {
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", menuPath); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (path === "/employee/management" || path === "/employee/list") {
        this.selectedMenu = 0;
      } else if (path === "/employee/add") {
        this.selectedMenu = 0;
      } else if (path === "/employee/view") {
        this.selectedMenu = 0;
      } else if (path === "/employee/dept/list") {
        this.selectedMenu = 3;
      } else if (path === "/employee/requestjoin/list") {
        this.selectedMenu = 4;
      } else if (path === "/employee/withdraw/list") {
        this.selectedMenu = 5;
      }

      this.$store.dispatch("updateSelectedMenu", "/employee/management"); // Vuex 상태 업데이트
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
