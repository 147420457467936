<template>
  <div class="wrapper">
    <div class="container">
      <!-- 헤더 -->
      <keep-alive>
        <HeaderMenu />
      </keep-alive>
      <!-- //헤더 -->

      <!-- 바디 -->
      <div class="body">
        <div class="body_box">
          <!-- 바디레프트 -->
          <CertificationLeftMenu />
          <!-- //바디레프트 -->

          <!-- 바디라이트 -->
          <router-view />
          <!-- //바디라이트 -->
        </div>
      </div>
      <!-- //바디 -->

      <!-- 푸터 -->
      <FooterMenu />
      <!-- //푸터 -->
    </div>
  </div>
</template>

<script>
import HeaderMenu from "@/components/Layout/HeaderMenu.vue";
import CertificationLeftMenu from "@/components/Layout/Certification_Left.vue";
import FooterMenu from "@/components/Layout/FooterMenu.vue";

export default {
  name: "CertificationManagement",
  components: {
    HeaderMenu,
    CertificationLeftMenu,
    FooterMenu,
  },
};
</script>

<style scoped></style>
