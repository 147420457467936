<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">추가정보 관리 (슈퍼 관리자용)</div>
        <div class="description">추가정보를 관리합니다.</div>
        <article class="right_body">
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <div class="table_list_top count_page">
                <div class="table_list_stitle">기본정보</div>
              </div>
              <table>
                <colgroup>
                  <col style="width: 23%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                  <col />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <span>항목명</span>
                    </th>
                    <th>
                      <div class="inner_td vertical_middle relative">
                        <span>표시여부</span>
                        <div class="info-icon">
                          <SvgIcon icon="question" />
                          <div class="float_msg">
                            활성화 시 회원가입 + 내정보 화면에 표시됩니다.
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="inner_td vertical_middle relative">
                        <span>사용여부</span>
                        <div class="info-icon">
                          <SvgIcon icon="question" />
                          <div class="float_msg">
                            활성화 시 내정보 화면에 표시됩니다.
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="inner_td vertical_middle relative">
                        <span>필수여부</span>
                        <div class="info-icon">
                          <SvgIcon icon="question" />
                          <div class="float_msg">
                            필수입력 항목으로 지정합니다.
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <span>추가설정</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="left_margin o1 o_title">아이디</td>
                    <td class="o2">
                      <span class="only_mobile">표시여부 : </span>
                      <input type="checkbox" v-model="checkboxes.id_show_yn" />
                    </td>
                    <td class="o3">
                      <span class="only_mobile">사용여부 : </span>
                      <input type="checkbox" v-model="checkboxes.id_use_yn" />
                    </td>
                    <td class="o4">
                      <span class="only_mobile">필수여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.id_required_yn"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="left_margin o1 o_title">비밀번호</td>
                    <td class="o2">
                      <span class="only_mobile">표시여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.pass_show_yn"
                      />
                    </td>
                    <td class="o3">
                      <span class="only_mobile">사용여부 : </span>
                      <input type="checkbox" v-model="checkboxes.pass_use_yn" />
                    </td>
                    <td class="o4">
                      <span class="only_mobile">필수여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.pass_required_yn"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="left_margin o1 o_title">이름</td>
                    <td class="o2">
                      <span class="only_mobile">표시여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.name_show_yn"
                      />
                    </td>
                    <td class="o3">
                      <span class="only_mobile">사용여부 : </span>
                      <input type="checkbox" v-model="checkboxes.name_use_yn" />
                    </td>
                    <td class="o4">
                      <span class="only_mobile">필수여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.name_required_yn"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="left_margin o1 o_title">휴대폰번호</td>
                    <td class="o2">
                      <span class="only_mobile">표시여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.mobile_show_yn"
                      />
                    </td>
                    <td class="o3">
                      <span class="only_mobile">사용여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.mobile_use_yn"
                      />
                    </td>
                    <td class="o4">
                      <span class="only_mobile">필수여부 : </span>
                      <input
                        type="checkbox"
                        v-model="checkboxes.mobile_required_yn"
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
            <div class="h40"></div>
            <!--목록-->
            <div class="tbl_list table_m mobile_table joinitems">
              <div class="table_list_top count_page">
                <div class="table_list_stitle">추가정보</div>

                <button type="button" @click.prevent="addOption(item)">
                  항목추가
                </button>
              </div>

              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col style="width: 26%" />
                  <col style="width: 7%" />
                  <col style="width: 7%" />
                  <col style="width: 7%" />
                  <col />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>번호</th>
                    <th>항목명</th>
                    <th>표시여부</th>
                    <th>사용여부</th>
                    <th>필수여부</th>
                    <th>추가설정</th>
                  </tr>
                </thead>
                <draggable v-model="additionalInfo" @end="onDragEnd">
                  <template v-slot:item="{ element, index }">
                    <tr :key="element.itemNum">
                      <td class="no_mobile">{{ index + 1 }}</td>
                      <td class="o1 o_title">
                        <span class="only_mobile">항목명 : </span>
                        <input
                          type="text"
                          class="w_188"
                          v-model="element.itemName"
                        />
                      </td>
                      <td class="o2">
                        <span class="only_mobile">표시여부 : </span>
                        <input
                          type="checkbox"
                          :checked="element.itemShowYN === 'Y'"
                          @change="updateItemShowYN(element, $event)"
                          disabled
                          class="disabled-checkbox"
                        />
                      </td>
                      <td class="o3">
                        <span class="only_mobile">사용여부 : </span>
                        <input
                          type="checkbox"
                          :checked="element.itemUseYN === 'Y'"
                          @change="updateItemUseYN(element, $event)"
                          disabled
                          class="disabled-checkbox"
                        />
                      </td>
                      <td class="o4 o_br">
                        <span class="only_mobile">필수여부 : </span>
                        <input
                          type="checkbox"
                          :checked="element.itemRequiredYN === 'Y'"
                          @change="updateItemRequiredYN(element, $event)"
                          disabled
                          class="disabled-checkbox"
                        />
                      </td>
                      <td class="o5 o_br o_margintop">
                        <div class="between">
                          <div class="inner_td">
                            <span class="only_mobile" style="width: 100%"
                              >추가설정 :</span
                            >
                            <select v-model="element.itemType">
                              <option value="T">텍스트박스</option>
                              <option value="C">체크박스</option>
                              <option value="R">라디오버튼</option>
                            </select>
                            <input
                              type="text"
                              class="w_188"
                              v-model="element.itemOption"
                            />
                          </div>
                          <div class="inner_td bottom">
                            <button
                              class="keep"
                              @click.prevent="resetItem(index)"
                            >
                              초기화
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveData">저장</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import SvgIcon from "@/components/SvgIcons.vue";
import draggable from "vuedraggable";
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingUserJoinColumnList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    SvgIcon,
    draggable,
  },

  data() {
    return {
      checkboxes: {
        id_show_yn: false,
        id_use_yn: false,
        id_required_yn: false,
        pass_show_yn: false,
        pass_use_yn: false,
        pass_required_yn: false,
        name_show_yn: false,
        name_use_yn: false,
        name_required_yn: false,
        mobile_show_yn: false,
        mobile_use_yn: false,
        mobile_required_yn: false,
      },
      additionalInfo: [], // 추가정보 저장
    };
  },
  watch: {
    // 표시여부가 체크되면 사용여부도 자동으로 체크되게 설정
    "checkboxes.id_show_yn"(newValue) {
      if (newValue) {
        this.checkboxes.id_use_yn = true;
      }
    },
    "checkboxes.pass_show_yn"(newValue) {
      if (newValue) {
        this.checkboxes.pass_use_yn = true;
      }
    },
    "checkboxes.name_show_yn"(newValue) {
      if (newValue) {
        this.checkboxes.name_use_yn = true;
      }
    },
    "checkboxes.mobile_show_yn"(newValue) {
      if (newValue) {
        this.checkboxes.mobile_use_yn = true;
      }
    },
    // 필수여부가 체크되면 사용여부도 자동으로 체크되게 설정
    "checkboxes.id_required_yn"(newValue) {
      if (newValue) {
        this.checkboxes.id_use_yn = true;
      }
    },
    "checkboxes.pass_required_yn"(newValue) {
      if (newValue) {
        this.checkboxes.pass_use_yn = true;
      }
    },
    "checkboxes.name_required_yn"(newValue) {
      if (newValue) {
        this.checkboxes.name_use_yn = true;
      }
    },
    "checkboxes.mobile_required_yn"(newValue) {
      if (newValue) {
        this.checkboxes.mobile_use_yn = true;
      }
    },
    // 사용여부가 해제되면 표시여부도 해제되도록 설정
    "checkboxes.id_use_yn"(newValue) {
      if (!newValue) {
        this.checkboxes.id_show_yn = false;
      }
    },
    "checkboxes.pass_use_yn"(newValue) {
      if (!newValue) {
        this.checkboxes.pass_show_yn = false;
      }
    },
    "checkboxes.name_use_yn"(newValue) {
      if (!newValue) {
        this.checkboxes.name_show_yn = false;
      }
    },
    "checkboxes.mobile_use_yn"(newValue) {
      if (!newValue) {
        this.checkboxes.mobile_show_yn = false;
      }
    },
  },
  mounted() {
    const infoIcons = document.querySelectorAll(".info-icon");

    infoIcons.forEach((icon) => {
      icon.addEventListener("mouseover", () => {
        const floatMsg = icon.querySelector(".float_msg");
        if (floatMsg) {
          floatMsg.style.display = "block";
        }
      });

      icon.addEventListener("mouseout", () => {
        const floatMsg = icon.querySelector(".float_msg");
        if (floatMsg) {
          floatMsg.style.display = "none";
        }
      });
    });

    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await apiClient.post(
          "/api/UserJoinItem/join-item-info",
          {
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.checkbasicInfo = Array.isArray(data.data) ? data.data : [];

          this.checkboxes = {
            id_show_yn: this.checkbasicInfo[0].idShowYN === "Y",
            id_use_yn: this.checkbasicInfo[0].idUseYN === "Y",
            id_required_yn: this.checkbasicInfo[0].idRequiredYN === "Y",
            pass_show_yn: this.checkbasicInfo[0].passShowYN === "Y",
            pass_use_yn: this.checkbasicInfo[0].passUseYN === "Y",
            pass_required_yn: this.checkbasicInfo[0].passRequiredYN === "Y",
            name_show_yn: this.checkbasicInfo[0].nameShowYN === "Y",
            name_use_yn: this.checkbasicInfo[0].nameUseYN === "Y",
            name_required_yn: this.checkbasicInfo[0].nameRequiredYN === "Y",
            mobile_show_yn: this.checkbasicInfo[0].mobileShowYN === "Y",
            mobile_use_yn: this.checkbasicInfo[0].mobileUseYN === "Y",
            mobile_required_yn: this.checkbasicInfo[0].mobileRequiredYN === "Y",
          };

          // 추가정보 처리
          this.additionalInfo = Array.isArray(data.data.slice(1))
            ? data.data.slice(1)
            : [];
        }
      } catch (error) {
        console.error("데이터를 가져오는 중 오류 발생:", error);
      }
    },

    async saveData() {
      if (this.validateAdditionalInfo()) {
        try {
          // 기본정보 저장
          const basicInfoResponse = await apiClient.post(
            "/api/UserJoinItem/join-item-basic-save",
            {
              LoginId: localStorage.getItem("userid"),
              IdShowYN: this.checkboxes.id_show_yn == true ? "Y" : "N",
              IdUseYN: this.checkboxes.id_use_yn == true ? "Y" : "N",
              IdRequiredYN: this.checkboxes.id_required_yn == true ? "Y" : "N",

              PassShowYN: this.checkboxes.pass_show_yn == true ? "Y" : "N",
              PassUseYN: this.checkboxes.pass_use_yn == true ? "Y" : "N",
              PassRequiredYN:
                this.checkboxes.pass_required_yn == true ? "Y" : "N",

              NameShowYN: this.checkboxes.name_show_yn == true ? "Y" : "N",
              NameUseYN: this.checkboxes.name_use_yn == true ? "Y" : "N",
              NameRequiredYN:
                this.checkboxes.name_required_yn == true ? "Y" : "N",

              MobileShowYN: this.checkboxes.mobile_show_yn == true ? "Y" : "N",
              MobileUseYN: this.checkboxes.mobile_use_yn == true ? "Y" : "N",
              MobileRequiredYN:
                this.checkboxes.mobile_required_yn == true ? "Y" : "N",
            }
          );

          if (basicInfoResponse.status !== 200) {
            this.toast.error("추가정보 항목 기본 정보가 저장이 실패되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            return;
          }

          // 추가정보 저장
          for (let index = 0; index < this.additionalInfo.length; index++) {
            const item = this.additionalInfo[index];
            const additionalInfoResponse = await apiClient.post(
              "/api/UserJoinItem/join-item-add-save",
              {
                LoginId: localStorage.getItem("userid"),

                ItemName: item.itemName,
                ItemShowYN: item.itemShowYN,
                ItemUseYN: item.itemUseYN,
                ItemRequiredYN: item.itemRequiredYN,
                ItemType: item.itemType,
                ItemOption: item.itemOption,
                itemNum: item.itemNum,
                OrderNum: index + 1,
              }
            );

            if (additionalInfoResponse.status !== 200) {
              console.error(`추가정보 항목 ${item.itemNum} 저장 실패`);
              this.toast.error(`추가정보 항목 ${item.itemNum} 저장이 실패되었습니다.`, {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
            }
          }
          
          this.toast.success("추가정보 항목 정보(기본,추가)가 정상적으로 저장되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        } catch (error) {
          console.error("저장 중 오류 발생:", error);
        }
      }
    },

    validateAdditionalInfo() {
      for (let index = 0; index < this.additionalInfo.length; index++) {
        const item = this.additionalInfo[index];
        // 항목명 체크
        if (!item.itemName.trim()) {
          this.toast.error(`항목명은 필수 입력 항목입니다. 항목 번호: ${index + 1}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }

        // ItemType이 체크박스나 라디오 버튼일 때 itemOption 체크
        if (["C", "R"].includes(item.itemType) && !item.itemOption.trim()) {
          this.toast.error(`추가설정은 필수 입력 항목입니다. 항목 번호: ${index + 1}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }
      }
      return true;
    },

    resetItem(index) {
      // 추가정보 항목 초기화
      this.additionalInfo[index] = {
        itemName: "",
        itemNum: this.additionalInfo[index].itemNum,
        itemOption: "",
        itemRequiredYN: false,
        itemShowYN: false,
        itemUseYN: false,
        itemType: "T",
      };
    },

    updateItemShowYN(item, event) {
      item.itemShowYN = event.target.checked ? "Y" : "N";
    },
    updateItemUseYN(item, event) {
      item.itemUseYN = event.target.checked ? "Y" : "N";
    },
    updateItemRequiredYN(item, event) {
      item.itemRequiredYN = event.target.checked ? "Y" : "N";

      // 필수여부가 체크되면 사용여부도 자동으로 체크되도록 설정
      if (item.itemRequiredYN === "Y") {
        item.itemUseYN = "Y";
      }
    },

    addOption() {
      // 추가할 새로운 항목 객체 생성
      const newItem = {
        itemName: "",
        itemNum: this.additionalInfo.length + 1, // 번호 자동 증가
        itemOption: "",
        itemRequiredYN: "N",
        itemShowYN: "N",
        itemUseYN: "N",
        itemType: "T",
      };

      // 추가정보 배열에 새로운 항목 추가
      this.additionalInfo.push(newItem);
    },

    onDragEnd(event) {
      console.log("드래그 앤 드롭 종료", event);
      // 필요에 따라 서버에 업데이트 요청을 추가할 수 있습니다.
    },
  },
};
</script>

<style scoped>
.info-icon {
  cursor: pointer; /* 손바닥 모양 커서 */
  margin-top: 5px;
}
</style>
