<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 상세보기</div>
        <div class="description"></div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>현행여부<span class="text_red">*</span></th>
                <td>
                  <input type="checkbox" id="isCurrent" v-model="isCurrent" />
                  <label for="isCurrent"> 현행</label>
                </td>
              </tr>
              <tr>
                <th>표시상태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: activeStatus === 'Y' }"
                      @click="setActiveStatus('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: activeStatus === 'N' }"
                      @click="setActiveStatus('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>시행일<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="date"
                      class="w_250"
                      v-model="effectiveDate"
                      ref="effectiveDate"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td textarea_full">
                    <textarea
                      class="h_400"
                      v-model="termsContents"
                      ref="termsContents"
                    ></textarea>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveTermofuse">
              저장
            </button>
            <button type="button" @click="DeleteCheck">삭제</button>
            <button type="button" @click="goToTermofuseList">목록</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingTermsofuseView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      termsofuseInfo: [],
      termsofuseId: this.id,
      termsContents: "",
      activeStatus: "", // 표시상태 값
      effectiveDate: "", // 시행일 값;
      isCurrent: false, // 현행여부 기본값
    };
  },
  // 파라미터를 받으려면 routes/index.js 파일에 :id 를 path 에 추가해줘야 함
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchTermsofuseData();
  },
  methods: {
    goToTermofuseList() {
      this.$router.push({ path: "/setting/termsofuse/list" });
    },
    setActiveStatus(status) {
      this.activeStatus = status;
    },
    async fetchTermsofuseData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/TermsofUse/terms-info", {
          TermsId: this.id,
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.termsofuseInfo = Array.isArray(data.data) ? data.data : [];

          // 이용약관 정보
          this.activeStatus = this.termsofuseInfo[0].activeStatus;
          this.termsContents = this.termsofuseInfo[0].termsContents;
          this.effectiveDate = this.termsofuseInfo[0].selDate;
          // 현행 여부 설정
          this.isCurrent =
            this.termsofuseInfo[0].currentYN === "Y" ? true : false;

          this.isInitialLoad = false; // 초기 로드 완료
        }
      } catch (error) {
        console.error("Error fetching terms data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    DeleteCheck() {
      if (confirm("해당 이용약관을 삭제하시겠습니까?")) {
        this.DeleteTermofuse();
      }
    },
    async DeleteTermofuse() {
      try {
        const response = await apiClient.post("/api/TermsofUse/terms-delete", {
          TermsId: this.id,
          LoginId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          this.toast.success("이용약관 정보가 성공적으로 삭제되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.goToTermofuseList();
        }
      } catch (error) {
        console.error("Error deleting companies:", error);
		    this.toast.error("이용약관 삭제 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    async saveTermofuse() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post("/api/TermsofUse/terms-edit", {
            TermsId: this.id,
            ActiveStatus: this.activeStatus,
            SelDate: this.effectiveDate,
            TermsContents: this.termsContents,
            CurrentYN: this.isCurrent ? "Y" : "N",
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          });
          if (response.status === 200) {
            this.toast.success("이용약관 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToTermofuseList();
          }
        } catch (error) {
          console.error("Error saving user data:", error);	
          this.toast.error("이용약관 정보 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.activeStatus) {
		    this.toast.error("표시상태를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.effectiveDate) {
		    this.toast.error("시행일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.termsContents) {
		    this.toast.error("내용을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.termsContents.focus();
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
