//import Vue from "vue";
import { createStore } from "vuex";

//Vue.use(createStore);

const store = createStore({
  state() {
    return {
      userid: localStorage.getItem("userid") || "", // 초기 값을 localStorage에서 가져옴
      username: localStorage.getItem("username") || "", // 초기 값을 localStorage에서 가져옴
      usertype: localStorage.getItem("usertype") || "", // 초기 값을 localStorage에서 가져옴
      companyid: localStorage.getItem("companyid") || "X", // 초기 값을 localStorage에서 가져옴
      workplaceid: localStorage.getItem("workplaceid") || "X", // 초기 값을 localStorage에서 가져옴

      selectedMenu: "", // 현재 선택된 메뉴를 저장할 상태
    };
  },
  mutations: {
    setUserid(state, userid) {
      state.userid = userid;
      localStorage.setItem("userid", userid);
    },
    setUsername(state, username) {
      state.username = username;
      localStorage.setItem("username", username); // localStorage에 저장
    },
    setUsertype(state, usertype) {
      state.usertype = usertype;
      localStorage.setItem("usertype", usertype); // localStorage에 저장
    },
    setCompanyid(state, companyid) {
      state.companyid = companyid;
      localStorage.setItem("companyid", companyid); // localStorage에 저장
    },
    setWorkplaceid(state, workplaceid) {
      state.workplaceid = workplaceid;
      localStorage.setItem("workplaceid", workplaceid); // localStorage에 저장
    },
    setSelectedMenu(state, menu) {
      state.selectedMenu = menu; // 메뉴 상태를 업데이트하는 뮤테이션
    },
  },
  actions: {
    fetchUserId({ commit }) {
      const userid = localStorage.getItem("userid") || "이름오류";
      commit("setUserid", userid);
    },
    fetchUserName({ commit }) {
      const username = localStorage.getItem("username") || "이름오류";
      commit("setUsername", username);
    },
    fetchUsertype({ commit }) {
      const usertype = localStorage.getItem("usertype") || "X";
      commit("setUsertype", usertype);
    },
    fetchCompanyid({ commit }) {
      const companyid = localStorage.getItem("companyid") || "X";
      commit("setCompanyid", companyid);
    },
    fetchWorkplaceid({ commit }) {
      const workplaceid = localStorage.getItem("workplaceid") || "X";
      commit("setWorkplaceid", workplaceid);
    },

    updateUserId({ commit }, userid) {
      commit("setUserid", userid);
    },
    updateUserName({ commit }, username) {
      commit("setUsername", username);
    },
    updateUserType({ commit }, usertype) {
      commit("setUsertype", usertype);
    },
    updateCompanyid({ commit }, companyid) {
      commit("setCompanyid", companyid);
    },
    updateWorkplaceid({ commit }, workplaceid) {
      commit("setWorkplaceid", workplaceid);
    },
    updateSelectedMenu({ commit }, menu) {
      commit("setSelectedMenu", menu); // 메뉴 상태를 업데이트하는 액션
    },
  },
  getters: {
    getUserid(state) {
      return state.userid;
    },
    getUsername(state) {
      return state.username;
    },
    getUsertype(state) {
      return state.usertype;
    },
    getCompanyid(state) {
      return state.companyid;
    },
    getWorkplaceid(state) {
      return state.workplaceid;
    },
    getSelectedMenu(state) {
      return state.selectedMenu; // 메뉴 상태를 반환하는 getter
    },
  },
});

export default store;
