import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes";
import store from "./store/store";
import DevUI from "vue-devui";
import "vue-devui/style.css";
import "@devui-design/icons/icomoon/devui-icon.css";
import SvgIcons from "@/components/SvgIcons.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// router.beforeEach((to) => {
//   //alert("to---" + to.path);
//   //alert("from---" + from.path);

//   const layout_css = "layout-style";
//   const login_css = "login-style";

//   // 여기에서 스타일 적용시길 라우터들을 체크 하면 될듯..
//   // 1. 로그인 페이지로 이동 시 다른 css 다 버리고 login.css 입히도록 수정
//   if (to.path === "/") {
//     const existingLayoutLink = document.getElementById(layout_css);
//     if (existingLayoutLink) {
//       document.head.removeChild(existingLayoutLink);
//     }

//     const existingLoginLink = document.getElementById(login_css);
//     if (!existingLoginLink) {
//       const link = document.createElement("link");
//       link.rel = "stylesheet";
//       link.href = "/styles/layout.css";
//       link.id = login_css;
//       document.head.appendChild(link);
//     }
//   }
//   // 로그인 페이지가 아닌 이제 다른 페이지 이동시에는 login.css 버리고 layout.css 입히도록 수정
//   // 다른 else가 필요하거나 else 안에 혹시 다른 뭐..
//   // kiosk 폴더나 뭐 이런게 들어간다고 하면.. 라우터 url 기준으로 나누면됨..
//   else {
//     const existingLoginLink = document.getElementById(login_css);
//     if (existingLoginLink) {
//       document.head.removeChild(existingLoginLink);
//     }

//     const existingLayoutLink = document.getElementById(layout_css);
//     if (!existingLayoutLink) {
//       const link = document.createElement("link");
//       link.rel = "stylesheet";
//       link.href = "/styles/layout.css";
//       link.id = layout_css;
//       document.head.appendChild(link);
//     }
//   }
// });

const link = document.createElement("link");
link.rel = "stylesheet";
link.href = "/styles/layout.css";
document.head.appendChild(link);

// Vue 애플리케이션 생성 및 설정
const app = createApp(App);
app.component("SvgIcons", SvgIcons); // 전역 컴포넌트로 등록
app.use(store);
app.use(router);
app.use(DevUI);
app.use(Toast);
app.mount("#app");

// 'fetchUserName' 액션 디스패치
store.dispatch("fetchUserName");
