<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_64.png" alt="" />상품관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="(menu, index) in menuList" :key="menu.id">
          <router-link
            :to="getRoute(menu.productTypeName)"
            :class="{ on: selectedMenu === index }"
            @click="selectMenu(index, getRoute(menu.productTypeName))"
          >
            {{ menu.productTypeName }} 목록
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "Product_Left",
  data() {
    return {
      selectedMenu: null,
      menuList: [],
    };
  },
  computed: {
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    getRoute(name) {
      const routes = {
        "상품분류": "/product/category/list",
        "회의실": "/product/meetingroom/list",
        "자율좌석": "/product/freeseat/list",
        "공용시설": "/product/publicroom/list",
      };
      return routes[name] || "/";
    },
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", menuPath); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      const matchingMenu = this.menuList.find(
        (menu) => this.getRoute(menu.productTypeName?.trim()) === path
      );
      
      if (matchingMenu) {
        this.selectedMenu = this.menuList.indexOf(matchingMenu);
      } else {
        switch (path) {
          case "/product/category/add":
          case "/product/category/view":
            this.selectedMenu = 0;
            break;

          case "/product/meetingroom/add":
          case "/product/meetingroom/view":
            this.selectedMenu = 1; // 회의실
            break;
          
          case "/product/freeseat/add":
          case "/product/freeseat/view":
            this.selectedMenu = 2; // 자율좌석
            break;

          
          case "/product/publicroom/add":
          case "/product/publicroom/view":
            this.selectedMenu = 3; // 공용시설
            break;

          default:
            this.selectedMenu = 0;
            break;
        }
      }
      this.$store.dispatch("updateSelectedMenu", "/product/management"); // Vuex 상태 업데이트
    },    
    async fetchProductCategoryData() {
      try {
        const response = await apiClient.post("/api/Product/product-category-info", {
          LoginId: localStorage.getItem("userid"),
          SearchUserType: localStorage.getItem("usertype"),
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);

          const defaultMenu = {
            productTypeName: "상품분류",
            id: "0",
            index: 0,
          };
          
          const indexMapping = {
            "회의실": 3,
            "자율좌석": 4,
            "공용시설": 5,
          };

          const fetchedMenuList = (Array.isArray(data.data) ? data.data : []).map((menu) => ({
            ...menu,
            index: indexMapping[menu.productTypeName?.trim()] ?? -1,
          }));

          this.menuList = [defaultMenu, ...fetchedMenuList];
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      }
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.fetchProductCategoryData();
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
