<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">자율좌석 등록</div>
        <div class="description">
          자율좌석을 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      :value="selectedCompanyName"
                      readonly
                    />
                    <button @click="handleCompanySelection">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    :value="selectedWorkplaceName"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th>진열상태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: displaystatusSelect === 'Y' }"
                      @click="setDisplayStatusSelect('Y')"
                    >
                      진열
                    </button>
                    <button
                      type="button"
                      :class="{ on: displaystatusSelect === 'N' }"
                      @click="setDisplayStatusSelect('N')"
                    >
                      미진열
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상품분류<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td open_list">
                    <!-- '선택하기' 클릭 시 id="Modal" 나타남 -->
                    <button type="button" @click="showModal2">+추가</button>
                    <ul
                      class="attendee_add group_choice"
                      v-if="selectedParentName"
                    >
                      <li>
                        <div>
                          <span>{{ selectedParentName }}</span>
                        </div>
                        <a href="###" @click="clearParentCategory">⨉</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>좌석구역명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="좌석구역을 입력해주세요 ex) 마이즈 임원라운지"
                      v-model="freeSeatName"
                      ref="freeSeatName"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <div class="inner_td">
                    <textarea
                      placeholder="상세설명을 입력하세요. ex) 운영시간 등.."
                      v-model="detailDesc"
                      ref="detailDesc"
                    ></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이미지 (도면)<span class="text_red">*</span></th>
                <td class="td_address canvaswrapper">
                  <div style="filter: blur(0px)">
                    <div class="image-preview" ref="imageContainer">
                      <div class="zoom_tools">
                        <!-- 도면 크기 조절 버튼을 이미지 상단 왼쪽에 고정 -->
                        <button
                          v-if="selectedImage"
                          @click="toggleZoomSlider"
                          class="zoom-button-visible-area"
                        >
                          도면 크기 조절
                        </button>
                        <!-- 도면 크기 조절 슬라이더 -->
                        <div v-if="showZoomSlider" class="zoom-slider">
                          <label for="zoom">도면 크기: {{ zoomLevel }}%</label>
                          <input
                            id="zoom"
                            type="range"
                            min="10"
                            max="200"
                            v-model="zoomLevel"
                            @input="adjustImageZoom"
                          />
                        </div>
                      </div>
                      <div class="canvas_wrapper">
                        <canvas
                          v-if="selectedImage"
                          ref="canvas"
                          @mousedown="startDrag"
                          @mousemove="onDrag"
                          @mouseup="stopDrag"
                          @contextmenu.prevent
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                          "
                        />
                        <img
                          v-if="selectedImage"
                          :src="selectedImage.url"
                          :alt="selectedImage.name"
                          class="preview-img"
                          ref="previewImg"
                          style="position: relative; z-index: 1"
                          @mousedown="startDrag"
                          @contextmenu.prevent
                        />
                      </div>

                      <div
                        v-if="selectedImage"
                        style="position: absolute; top: 0; left: 0"
                      >
                        <!-- Draggable Shapes -->
                        <vue-draggable-resizable
                          v-for="(shape, index) in shapes"
                          :key="index"
                          :x="shape.x"
                          :y="shape.y"
                          :w="shape.size"
                          :h="shape.size"
                          :parent="true"
                          :resizable="true"
                          :handles="['tm', 'mr', 'bm', 'ml']"
                          @activated="startDragging(index)"
                          @deactivated="stopDragging(index)"
                          class="draggable-shape"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 3;
                          "
                        >
                          <!-- <span class="shape-number">{{ index + 1 }}</span> -->
                        </vue-draggable-resizable>
                      </div>
                    </div>
                  </div>

                  <!-- 도형 추가 버튼 -->
                  <div v-if="selectedImage">
                    <button
                      @click="addShape('rectangle')"
                      style="display: none"
                    >
                      사각형 추가
                    </button>
                    <button @click="addShape('circle')">좌석추가</button>
                    <button @click="addShape('triangle')" style="display: none">
                      삼각형 추가
                    </button>
                    <span style="margin-top: 10px; color: green"
                      >※ 좌석추가 전 도면 크기를 조절해주세요</span
                    >
                  </div>
                  <!-- 좌석 리스트 -->
                  <div v-if="selectedImage" class="seat-list">
                    <h3>좌석 리스트</h3>
                    <ul>
                      <li
                        v-for="(shape, index) in shapes"
                        :key="index"
                        :class="{ highlighted: selectedShapeIndex === index }"
                      >
                        <!-- {{ index + 1 }}. {{ shape.type }} - 위치 (x: -->
                        <input
                          type="text"
                          v-model="shape.nickname"
                          placeholder="별칭 입력"
                          class="nickname-input"
                        />
                        {{ index + 1 }}. 위치 (x: {{ shape.x.toFixed(2) }}, y:
                        {{ shape.y.toFixed(2) }})
                        <button @click="removeShape(index)">삭제</button>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <input
                      type="file"
                      class="w_250"
                      placeholder="선택된 파일 없음"
                      @change="handleImageSelection"
                      ref="fileInput"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">상세정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <!-- 시간단위는 없어짐 무조건 30분 단위로 -->
              <tr style="display: none">
                <th>시간단위<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option selected>선택해주세요</option>
                      <option>30분</option>
                      <option>1시간</option>
                      <option>1시간30분</option>
                      <option>2시간</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>수용인원<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="number"
                      class="w_100"
                      v-model="capacity"
                      ref="capacity"
                      min="1"
                      placeholder="1"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>운영시간<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 시작시간 선택 -->
                    <select
                      v-model="startTime"
                      ref="startTime"
                      class="w_100"
                      @change="updateEndTimeOptions"
                    >
                      <option
                        v-for="time in timeOptions"
                        :key="time"
                        :value="time"
                      >
                        {{ time }}
                      </option>
                    </select>
                    ~
                    <!-- 종료시간 선택 -->
                    <select v-model="endTime" ref="endTime" class="w_100">
                      <option
                        v-for="time in filteredEndTimeOptions"
                        :key="time"
                        :value="time"
                      >
                        {{ time }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>옵션</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="option_content">
                      <p class="w_150">시간</p>
                      <p>활성화여부</p>
                    </div>
                    <div
                      class="td_toggle"
                      v-for="(time, index) in filteredTimeOptions"
                      :key="index"
                    >
                      <input :class="'w_150'" :value="time" readonly />
                      <input
                        type="checkbox"
                        :id="'toggle' + index"
                        class="toggle"
                        hidden
                        v-model="activeTimes[time]"
                      />
                      <label :for="'toggle' + index" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a class="on" @click="saveFreeSeat">등록</a>
            <a @click="goToFreeSeatList">취소</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사/사업장 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">회사/사업장 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="SearchType">
                      <option value="companyName">회사명</option>
                      <option value="workplaceName">사업장명</option>
                    </select>
                    <input
                      type="text"
                      class="w_200"
                      v-model="SearchKey"
                      placeholder="검색어를 입력해주세요"
                      @keyup.enter="searchCompanies"
                    />
                    <button type="button" @click="searchCompanies">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>회사명</th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="companyworkplace in filteredCompanyWorkplaces"
                :key="companyworkplace.workplaceId"
              >
                <td>{{ companyworkplace.companyName }}</td>
                <!-- paginatedCompanyWorkplaces -->
                <td>
                  {{ companyworkplace.workplaceName }}
                </td>
                <!-- workplaceProductType -->
                <td>
                  <button
                    class="keep"
                    @click="selectCompanyWorkplace(companyworkplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //회사/사업장 선택 modal -->
  <!-- 상위분류 선택 modal -->
  <div id="Modal2" class="d_pop" v-if="isModal2Visible">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">상위분류 선택</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'A' }"
                      @click="setModal2Status('A')"
                    >
                      전체
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'Y' }"
                      @click="setModal2Status('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'N' }"
                      @click="setModal2Status('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      tyoe="text"
                      class="w_200"
                      v-model="SearchKey2"
                      placeholder="분류명을 입력해주세요"
                      @keyup.enter="searchProductcategorys"
                    />
                    <button type="button" @click="searchProductcategorys">
                      검색
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>분류명</th>
                <th>표시상태</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="productCategory in filteredProductCategorys"
                :key="productCategory.id"
              >
                <td>{{ productCategory.productName }}</td>
                <td>
                  {{ productCategory.activeStatus === "Y" ? "표시" : "미표시" }}
                </td>
                <td>
                  <button
                    class="keep"
                    @click="selectParentCategory(productCategory)"
                  >
                    선택
                  </button>
                </td>
              </tr>
              <!-- <tr>
                  <td>27층</td>
                  <td>표시</td>
                  <td><button class="keep">선택</button></td>
                </tr> -->
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup2"
              @click.prevent="prevPageGroup2"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage2" @click.prevent="prevPage2">
              <img src="@/images/prev_n.png" alt="이전페이지" />
            </a>
            <a
              v-for="page2 in pageGroup2"
              :key="page2"
              href="###"
              :class="{ on: currentPage2 === page2 }"
              @click.prevent="goToPage2(page2)"
            >
              {{ page2 }}
            </a>
            <a href="###" v-if="hasNextPage2" @click.prevent="nextPage2">
              <img src="@/images/next_n.png" alt="다음페이지" />
            </a>
            <a
              href="###"
              v-if="hasNextPageGroup2"
              @click.prevent="nextPageGroup2"
            >
              <img src="@/images/end_n.png" alt="마지막페이지" />
            </a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //상위분류 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import VueDraggableResizable from "vue-draggable-resizable";
import { useToast } from "vue-toastification";

export default {
  name: "ProductFreeseatAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showZoomSlider: false,
      zoomLevel: 100, // 기본 크기를 50%로 설정
      // 기존 데이터에 드래그 상태 추가
      shapes: [],
      selectedImage: null,
      canvasWidth: 0,
      canvasHeight: 0,
      dragging: false,
      dragStartX: 0,
      dragStartY: 0,
      draggedShapeIndex: null,
      selectedShapeIndex: null,

      selectedImages: [], // 선택된 이미지를 저장할 배열
      maxImageCount: 5, // 최대 이미지 선택 개수

      startTime: "09:00", // 초기 시작 시간
      endTime: "18:00", // 초기 종료 시간
      timeOptions: [], // 30분 단위의 시간 옵션 리스트
      activeTimes: {}, // 각 시간에 대한 활성화 상태 저장
      filteredEndTimeOptions: [], // 시작시간에 따라 필터된 종료시간 옵션 리스트

      selectedParentId: null, // 선택된 상위분류 ID
      selectedParentName: "", // 선택된 상위분류 이름
      isModalVisible: false, // 회사 선택 모달
      isModal2Visible: false, // 상위분류 선택 모달 상태
      modal2Status: "A", // 상위분류 모달의 상태 기본값을 '전체'로 설정
      companyworkplaces: [], // API 에서 가져온 회사-사업장 목록 데이터를 저장할 배열
      productcategorys: [], // API 에서 가져온 상품분류 목록 데이터를 저장할 배열
      displaystatusSelect: "Y", // 진열상태 '진열-Y', '미진열-N'

      totalCompanyWorkplaces: 0, // 총 회사-사업자 수
      totalProductCategorys: 0, // 총 상위분류 수

      selectedCompanyName: "", // 선택된 회사명
      selectedWorkplaceName: "", // 선택된 사업장명
      selectedCompanyId: 0, // 선택된 회사 ID
      selectedWorkplaceId: 0, // 선택된 사업장 ID
      selectedWorkplaceProductType: "", // 선택된 사업장의 상품유형

      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "companyName", // 기본값은 'companyName'으로 설정
      SearchKey: "", // 검색어를 입력받기 위한 변수
      filteredCompanyWorkplaces: [], // 검색된 결과를 저장할 배열

      currentPage2: 1, // 현재 페이지
      itemsPerPage2: 5, // 페이지 당 항목 수
      pageGroupSize2: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType2: "productName",
      SearchKey2: "", // 검색어를 입력받기 위한 변수
      filteredProductCategorys: [], // 검색된 결과를 저장할 배열
    };
  },
  components: {
    VueDraggableResizable,
  },
  created() {
    this.generateTimeOptions(); // 컴포넌트가 생성될 때 시간 옵션 생성
    this.updateEndTimeOptions(); // 종료시간 옵션을 초기화
  },
  computed: {
    filteredTimeOptions() {
      // startTime과 endTime 사이의 시간만 필터링
      return this.timeOptions.filter(
        (time) => time >= this.startTime && time <= this.endTime
      );
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      console.log("Company ID:", this.$store.getters.getCompanyid);
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      console.log("Workplace ID:", this.$store.getters.getWorkplaceid);
      return this.$store.getters.getWorkplaceid;
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalCompanyWorkplaces / this.itemsPerPage);
    },
    // 총 페이지 수 계산
    totalPages2() {
      return Math.ceil(this.totalProductCategorys / this.itemsPerPage2);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    // 현재 페이지 그룹 계산
    pageGroup2() {
      const start =
        Math.floor((this.currentPage2 - 1) / this.pageGroupSize2) *
          this.pageGroupSize2 +
        1;
      const end = Math.min(start + this.pageGroupSize2 - 1, this.totalPages2);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup2() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage2 > 1;
    },
    hasNextPageGroup2() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage2 < this.totalPages2;
    },
    hasPrevPage2() {
      return this.currentPage2 > 1;
    },
    hasNextPage2() {
      return this.currentPage2 < this.totalPages2;
    },
  },
  mounted() {
    this.loadWorkplaces();
    this.loadProductcategorys();

    // selectedImage가 존재할 때만 loadCanvas 호출
    if (this.selectedImage) {
      this.loadCanvas();
    }
  },
  methods: {
    toggleZoomSlider() {
      const confirmMessage =
        "도면 크기 조절 진행 시 배치한 좌석이 있을 경우 \r\n재 배치 해야 합니다. 계속 하시겠습니까?";
      if (confirm(confirmMessage)) {
        this.showZoomSlider = !this.showZoomSlider;
      }
    },
    adjustImageZoom() {
      const previewImg = this.$refs.previewImg;
      if (previewImg) {
        const scale = this.zoomLevel / 100;
        previewImg.style.transform = `scale(${scale})`;
      }
    },
    startDragging(index) {
      this.draggedShapeIndex = index;
    },
    stopDragging(index) {
      if (this.draggedShapeIndex === index) {
        this.draggedShapeIndex = null;
      }
      this.drawShapes();
    },
    drawGrid() {
      const ctx = this.$refs.canvas.getContext("2d");
      const gridSize = 20; // 격자의 간격 (20px)

      ctx.strokeStyle = "#e0e0e0"; // 격자 색상
      ctx.lineWidth = 0.5;

      // 수직선 그리기
      for (let x = 0; x < this.canvasWidth; x += gridSize) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, this.canvasHeight);
        ctx.stroke();
      }

      // 수평선 그리기
      for (let y = 0; y < this.canvasHeight; y += gridSize) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(this.canvasWidth, y);
        ctx.stroke();
      }
    },
    drawConnections(index) {
      const ctx = this.$refs.canvas.getContext("2d");
      ctx.beginPath(); // 선을 시작합니다
      ctx.strokeStyle = "blue"; // 선 색상 파란색으로 설정

      const baseShape = this.shapes[index];
      if (!baseShape) return;

      this.shapes.forEach((shape, i) => {
        if (i !== index) {
          // 자신이 아닌 다른 도형과 연결
          const dx = shape.x - baseShape.x;
          const dy = shape.y - baseShape.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < 250) {
            // 가까운 도형에만 연결
            ctx.moveTo(
              baseShape.x + baseShape.size / 2,
              baseShape.y + baseShape.size / 2
            );
            ctx.lineTo(shape.x + shape.size / 2, shape.y + shape.size / 2);
          }
        }
      });
      ctx.stroke();
    },

    onShapeClick(index) {
      this.selectedShapeIndex = index; // 클릭한 도형의 인덱스를 저장하여 리스트에서 강조
      this.highlightSelectedShape(); // 강조 색상 적용
      this.drawShapes(); // 기존 도형과 격자 다시 그리기
      this.drawConnections();
    },
    highlightSelectedShape() {
      this.shapes = this.shapes.map((shape, idx) => ({
        ...shape,
        color: idx === this.selectedShapeIndex ? "red" : "black",
      }));
    },
    startDrag(event) {
      // 왼쪽과 오른쪽 마우스 클릭 구분
      if (event.button === 2) {
        event.preventDefault();
        this.draggingImage = true;
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
        window.addEventListener("mousemove", this.onImageDrag);
        window.addEventListener("mouseup", this.stopImageDrag);
      } else if (event.button === 0) {
        const { offsetX, offsetY } = event;
        this.draggedShapeIndex = this.shapes.findIndex((shape) =>
          this.isWithinShape(shape, offsetX, offsetY)
        );
        if (this.draggedShapeIndex !== -1) {
          this.dragging = true;
          const shape = this.shapes[this.draggedShapeIndex];
          this.dragStartX = offsetX - shape.x;
          this.dragStartY = offsetY - shape.y;
        }
        this.selectedShapeIndex = this.draggedShapeIndex;
        this.highlightSelectedShape();
        this.drawShapes();
      }
    },
    onImageDrag(event) {
      if (this.draggingImage) {
        const dx = event.clientX - this.dragStartX;
        const dy = event.clientY - this.dragStartY;

        const imageContainer = this.$refs.imageContainer;
        imageContainer.scrollLeft -= dx;
        imageContainer.scrollTop -= dy;

        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
      }
    },

    stopImageDrag() {
      this.draggingImage = false;
      window.removeEventListener("mousemove", this.onImageDrag);
      window.removeEventListener("mouseup", this.stopImageDrag);
    },
    onDrag(event) {
      if (this.dragging && this.draggedShapeIndex !== null) {
        const shape = this.shapes[this.draggedShapeIndex];
        shape.x = event.offsetX - this.dragStartX;
        shape.y = event.offsetY - this.dragStartY;
        this.drawShapes();
      }
    },
    stopDrag() {
      this.draggingImage = false;
      this.dragging = false;
      this.draggedShapeIndex = null;

      window.removeEventListener("mousemove", this.onDrag);
      window.removeEventListener("mouseup", this.stopDrag);
    },
    isWithinShape(shape, x, y) {
      const halfSize = shape.size / 2;
      if (shape.type === "rectangle") {
        return (
          x >= shape.x &&
          x <= shape.x + shape.size &&
          y >= shape.y &&
          y <= shape.y + shape.size
        );
      } else if (shape.type === "circle") {
        const dx = x - (shape.x + halfSize);
        const dy = y - (shape.y + halfSize);
        return dx * dx + dy * dy <= halfSize * halfSize;
      } else if (shape.type === "triangle") {
        return (
          x >= shape.x &&
          x <= shape.x + shape.size &&
          y >= shape.y &&
          y <= shape.y + shape.size
        );
      }
      return false;
    },
    updateNumberPosition(index) {
      const shape = this.shapes[index];
      if (shape) {
        shape.numberX = shape.x + shape.size / 2;
        shape.numberY = shape.y + shape.size / 2;
      }
    },
    handleResize(index, width, height) {
      const shape = this.shapes[index];
      if (shape) {
        shape.size = Math.min(width, height); // 가로 세로 중 작은 값으로 size 업데이트
        this.drawShapes();
        //this.updateNumberPosition(index); // 크기 조절 중에도 번호 위치 업데이트
      }
    },
    handleImageSelection(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedImage = { url: e.target.result, name: file.name };

          // Vue가 DOM을 업데이트한 후 previewImg 요소에 접근할 수 있도록 합니다.
          this.$nextTick(() => {
            this.loadCanvas(); // 이미지 로드 후 캔버스 초기화
          });
        };
        reader.readAsDataURL(file);
      }
    },
    loadCanvas() {
      if (!this.selectedImage) {
        console.warn("No image selected");
        return;
      }

      const img = new Image();
      img.src = this.selectedImage.url;
      img.onload = () => {
        this.$nextTick(() => {
          const previewImg = this.$refs.previewImg;

          if (previewImg) {
            // previewImg 요소가 존재하는지 확인
            const displayedWidth = previewImg.clientWidth;
            const displayedHeight = previewImg.clientHeight;

            this.canvasWidth = displayedWidth;
            this.canvasHeight = displayedHeight;

            const canvas = this.$refs.canvas;
            canvas.width = displayedWidth;
            canvas.height = displayedHeight;

            this.drawShapes();
          } else {
            console.error("previewImg element not found");
          }
        });
      };
    },
    addShape(type) {
      if (this.$refs.canvas) {
        const imageContainer = this.$refs.imageContainer;
        const visibleX = imageContainer.scrollLeft;
        const visibleY = imageContainer.scrollTop;

        const newShape = {
          type,
          x: visibleX + imageContainer.clientWidth / 2 - 10, // 중앙에 배치
          y: visibleY + imageContainer.clientHeight / 2 - 10, // 중앙에 배치
          size: 20,
          nickname: "", // 별칭 초기값 추가
        };
        this.shapes.push(newShape);
        this.selectedShapeIndex = this.shapes.length - 1; // 추가한 도형을 선택 상태로 설정
        this.highlightSelectedShape(); // 강조 색상 적용
        this.drawShapes();
      } else {
        console.error("Canvas not loaded yet");
      }
    },

    drawShapes() {
      const ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      // 격자 그리기
      this.drawGrid();

      this.shapes.forEach((shape, index) => {
        ctx.beginPath();
        ctx.strokeStyle = shape.color || "black";

        if (shape.type === "rectangle") {
          ctx.rect(shape.x, shape.y, shape.size, shape.size);
        } else if (shape.type === "circle") {
          ctx.arc(
            shape.x + shape.size / 2,
            shape.y + shape.size / 2,
            shape.size / 2,
            0,
            2 * Math.PI
          );
        }
        ctx.stroke();

        this.updateNumberPosition(index);
        ctx.fillStyle = "black";
        ctx.font = "bold 14px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(index + 1, shape.numberX, shape.numberY);
      });

      if (this.selectedShapeIndex !== null) {
        this.drawConnections(this.selectedShapeIndex);
      }
    },
    handleDrag(index, left, top) {
      const shape = this.shapes[index];
      if (shape && this.draggedShapeIndex !== null) {
        shape.x = Math.max(0, Math.min(left, this.canvasWidth - shape.size));
        shape.y = Math.max(0, Math.min(top, this.canvasHeight - shape.size));
        this.updateNumberPosition(index); // 번호 위치 업데이트
        this.drawShapes();
      }
    },
    removeShape(index) {
      this.shapes.splice(index, 1); // 도형 삭제
      if (this.selectedShapeIndex === index) {
        this.selectedShapeIndex = null; // 선택된 도형이 삭제된 경우 초기화
      }

      this.drawShapes();
    },
    handleImageChange(event) {
      const files = event.target.files;
      const validExtensions = ["jpg", "jpeg", "png"]; // 허용할 확장자
      const validFiles = [];
      const invalidFiles = [];
      const duplicateFiles = [];
      let validFileCount = 0; // 유효한 파일 수를 추적

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.split(".").pop().toLowerCase();

        // 파일 확장자가 유효한지 확인
        if (!validExtensions.includes(extension)) {
          invalidFiles.push(file.name); // 유효하지 않은 파일 목록에 추가
          continue;
        }

        // 동일한 이름의 파일이 이미 선택된 경우
        const duplicate = this.selectedImages.some(
          (image) => image.name === file.name
        );
        if (duplicate) {
          duplicateFiles.push(file.name);
          continue; // 중복된 파일은 추가하지 않음
        }

        validFileCount++; // 유효한 파일 수를 증가

        const reader = new FileReader();

        reader.onload = (e) => {
          validFiles.push({
            name: file.name,
            url: e.target.result,
            file: file, // 파일 객체를 추가
          });

          // 모든 유효한 파일 처리가 끝난 후 유효한 파일만 추가
          if (validFiles.length === validFileCount) {
            this.processValidFiles(validFiles, duplicateFiles, invalidFiles);
          }
        };

        reader.readAsDataURL(file);
      }

      // 파일 입력 요소를 초기화하여 같은 파일을 다시 선택할 수 있도록 합니다.
      this.resetFileInput(event);
    },

    processValidFiles(validFiles, duplicateFiles, invalidFiles) {
      // 중복된 파일 알림
      if (duplicateFiles.length > 0) {	
		    this.toast.error(`이미 선택된 파일입니다: ${duplicateFiles.join(", ")}`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }

      // 유효하지 않은 파일 알림
      if (invalidFiles.length > 0) {
        this.toast.error(`허용되지 않는 파일 형식입니다 (허용 확장자: jpg, jpeg, png)\n\n${invalidFiles.join(
            ", "
          )}`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }

      // 유효한 파일만 추가
      this.selectedImages = [...this.selectedImages, ...validFiles];

      // 최대 이미지 수를 초과하면 추가로 선택된 파일은 무시
      if (this.selectedImages.length > this.maxImageCount) {
        this.toast.error(`최대 ${this.maxImageCount} 장의 이미지만 업로드 할 수 있습니다.`, {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.selectedImages = this.selectedImages.slice(0, this.maxImageCount); // 초과된 파일은 제거
      }
    },
    removeImage(index) {
      const imageName = this.selectedImages[index].name; // 선택한 이미지의 이름 가져오기
      const confirmed = confirm(`이미지 "${imageName}"를 삭제하시겠습니까?`);
      if (confirmed) {
        this.selectedImages.splice(index, 1);

        // 이미지를 삭제한 후 파일 입력 요소를 초기화합니다.
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          // fileInput.value = ""; // 선택된 파일을 초기화
        }
      }
    },
    resetFileInput(event) {
      event.target.value = ""; // 파일 입력 요소를 초기화
    },
    generateTimeOptions() {
      const times = [];
      let hour = 0;
      let minute = 0;

      while (hour < 24 || (hour === 23 && minute === 30)) {
        let formattedHour = hour < 10 ? "0" + hour : hour;
        let formattedMinute = minute < 10 ? "0" + minute : minute;
        const time = `${formattedHour}:${formattedMinute}`;
        times.push(time);
        this.activeTimes[time] = true; // Vue 3에서는 이렇게 사용 // 기본값을 true 면 선택된거고 false 면 선택안된거야
        //console.log("this.activeTimes---------------------" + this.activeTimes);
        minute += 30;
        if (minute === 60) {
          minute = 0;
          hour++;
        }
      }

      this.timeOptions = times;
    },
    updateEndTimeOptions() {
      // 시작시간 이후의 시간만 종료시간 옵션에 포함
      this.filteredEndTimeOptions = this.timeOptions.filter(
        (time) => time > this.startTime
      );

      // 만약 종료시간이 필터된 옵션에 포함되지 않으면 초기화
      if (!this.filteredEndTimeOptions.includes(this.endTime)) {
        this.endTime = this.filteredEndTimeOptions[0];
      }
    },
    searchCompanies() {
      this.loadWorkplaces();
    },
    searchProductcategorys() {
      this.loadProductcategorys();
    },
    clearParentCategory() {
      this.selectedParentId = null;
      this.selectedParentName = "";
    },
    selectParentCategory(category) {
      this.selectedParentId = category.id;
      this.selectedParentName = category.productName;
      this.hideModal2(); // 모달 닫기
    },
    setModal2Status(status) {
      this.modal2Status = status;
      this.loadProductcategorys();
    },
    goToPage(page) {
      this.currentPage = page;
      this.companyworkplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    goToPage2(page) {
      this.currentPage2 = page;
      this.productcategorys = []; // 페이지 변경 시 목록 초기화
      this.loadProductcategorys();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      if (this.hasNextPageGroup) {
        this.currentPage = this.pageGroup[this.pageGroup.length - 1] + 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup() {
      if (this.hasPrevPageGroup) {
        this.currentPage = this.pageGroup[0] - 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPage2() {
      if (this.currentPage2 < this.totalPages2) {
        this.currentPage2++;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage2() {
      if (this.currentPage2 > 1) {
        this.currentPage2--;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup2() {
      if (this.hasNextPageGroup2) {
        this.currentPage2 = this.pageGroup2[this.pageGroup.length - 1] + 1;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup2() {
      if (this.hasPrevPageGroup2) {
        this.currentPage2 = this.pageGroup2[0] - 1;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    selectCompanyWorkplace(companyworkplace) {
      this.selectedCompanyName = companyworkplace.companyName;
      this.selectedWorkplaceName = companyworkplace.workplaceName;
      this.selectedCompanyId = companyworkplace.companyId;
      this.selectedWorkplaceId = companyworkplace.workplaceId;
      this.selectedWorkplaceProductType = companyworkplace.workplaceProductType; // 선택된 사업장의 상품유형 저장
      this.hideModal();
    },
    goToFreeSeatList() {
      this.$router.push({ path: "/product/freeseat/list" });
    },
    handleCompanySelection() {
      this.showModal();
    },
    showModal() {
      this.isModalVisible = true;
      this.SearchKey = ""; // 검색어 초기화
      this.filteredCompanyWorkplaces = [...this.companyworkplaces]; // 검색 결과 초기화
    },
    hideModal() {
      this.isModalVisible = false;
    },
    showModal2() {
      this.isModal2Visible = true;
      this.SearchKey2 = ""; // 검색어 초기화
      this.filteredProductCategorys = [...this.productcategorys]; // 검색 결과 초기화
    },
    hideModal2() {
      this.isModal2Visible = false;
    },
    async saveFreeSeat() {
      if (this.checkFields()) {
        if (this.uploadImages()) {
          const timeSettings = this.timeOptions.map((time) => {
            return {
              Time: time,
              IsActive: this.activeTimes[time] || false,
            };
          });

          console.log("Time Settings:", timeSettings);

          // 여기에 API 호출을 추가하여 데이터를 서버에 전송
          try {
            console.log("CompanyId---" + this.selectedCompanyId);
            console.log("WorkplaceId---" + this.selectedWorkplaceId);
            console.log("ActiveStatus---" + this.displaystatusSelect);
            console.log("ParentId---" + this.selectedParentId);
            console.log("FreeSeatName---" + this.freeSeatName);
            console.log("FreeSeatDetailDesc---" + this.detailDesc);
            console.log("FreeSeatLimitPeople---" + this.capacity);
            console.log("StartDtm---" + this.startTime);
            console.log("EndDtm---" + this.endTime);

            // 파일명만 추출하여 쉼표로 연결된 문자열로 변환
            const imageNames = this.selectedImages
              .map((image) => image.name)
              .join(",");

            console.log("ThumNail---" + imageNames);

            const response = await apiClient.post(
              "/api/FreeSeat/freeseat-add",
              {
                CompanyId: this.selectedCompanyId,
                WorkplaceId: this.selectedWorkplaceId,
                ActiveStatus: this.displaystatusSelect, // 표시상태 '진열-Y', '미진열-N'
                ParentId: this.selectedParentId, // 선택된 상위분류 ID 추가
                FreeSeatName: this.freeSeatName, // 상품명
                FreeSeatDetailDesc: this.detailDesc, // 상품 상세설명
                FreeSeatLimitPeople: this.capacity, // 수용인원
                StartDtm: this.startTime,
                EndDtm: this.endTime,
                ThumNail: imageNames, // 선택한 이미지 이름들
                LoginId: localStorage.getItem("userid"),
                TimeSettings: timeSettings, // 모든 시간대 데이터를 포함한 TimeSettings 추가
                // 필요한 다른 필드 추가
              }
            );

            if (response.status === 200) {
              this.toast.success("자율좌석이 성공적으로 등록되었습니다.", {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
		
              this.goToFreeSeatList();
            }
          } catch (error) {
            console.error("Error saving freeseat:", error);
            this.toast.error("자율좌석 등록 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
		
          }
        }
      }
    },
    uploadImages() {
      try {
        const formData = new FormData();
        // 선택한 이미지를 FormData에 추가

        this.selectedImages.forEach((image) => {
          formData.append(image.name, image.file);
        });

        // 서버로 업로드 요청
        const response = apiClient.post(
          "/api/FreeSeat/freeseat-file-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          this.toast.success("이미지가 성공적으로 업로드되었습니다!", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
		
          console.log(response.data);
        }
        return true;
      } catch (error) {
        console.error("이미지 업로드 중 오류 발생:", error);
		    this.toast.error("이미지 업로드 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return false;
      }
    },
    checkFields() {
      if (this.selectedCompanyId === 0 || this.selectedWorkplaceId === 0) {
        // 회사가 선택되지 않은 경우
		    this.toast.error("회사 및 사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.isModalVisible = true;
        return;
      }
      if (this.selectedParentId === null || this.selectedParentId === 0) {
		    this.toast.error("상품분류를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.isModal2Visible = true;
        return;
      }
      if (!this.freeSeatName) {
		    this.toast.error("좌석구역명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.freeSeatName.focus();
        return false;
      }

      // 이미지 선택 여부 확인
      if (this.selectedImages.length === 0) {
		    this.toast.error("최소 한 개의 이미지를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return false;
      }

      return true;
    },
    async loadProductcategorys() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: "",
          SearchActiveStatus: this.modal2Status, // 상태별 값 추가
          SearchType: this.SearchType2,
          SearchKey: this.SearchKey2,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        console.log("★★★★----" + searchParam.Page);
        console.log("★★★★----" + searchParam.PageSize);
        console.log("★★★★----" + searchParam.OrderType);
        console.log("★★★★----" + searchParam.SearchActiveStatus);
        console.log("★★★★----" + searchParam.SearchType2);
        console.log("★★★★----" + searchParam.SearchKey2);
        console.log("★★★★----" + searchParam.CompanyId);
        console.log("★★★★----" + searchParam.WorkplaceId);

        const response = await apiClient.post(
          "/api/Product/all-product-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.productcategorys = Array.isArray(data.data) ? data.data : [];
          this.totalProductCategorys = data.meta.totalCount || 0;
          this.filteredProductCategorys = this.productcategorys;
        }
      } catch (error) {
        console.error("Error loading productcategorys:", error);
      }
    },
    async loadWorkplaces() {
      let v_companyId = "";
      let v_workplaceId = "";
      // 슈퍼관리자면 회사 선택 골라야 하고
      // 회사관리자면 본인 회사 관련된 사업장 리스트만 나와야 하고
      // 사업장관리자면 선택 버튼 자체가 안보여야 함
      if (this.userType === "S") {
        v_companyId = "";
      }

      if (this.userType === "C") {
        v_companyId = this.companyId;
      }

      if (this.userType === "W") {
        v_companyId = this.companyId;
        v_workplaceId = this.workplaceId;
      }

      try {
        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          SearchActiveStatus: "Y", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정 //workplaceProductType
          WorkplaceId: v_workplaceId,
        };

        console.log(searchParam.SearchType);
        console.log(searchParam.SearchKey);
        console.log(searchParam.SearchActiveStatus);
        console.log(searchParam.OrderType);
        console.log(searchParam.PageSize);
        console.log(searchParam.Page);
        console.log(searchParam.CompanyId);

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.companyworkplaces = Array.isArray(data.data) ? data.data : [];
          this.totalCompanyWorkplaces = data.meta.totalCount || 0;
          this.filteredCompanyWorkplaces = this.companyworkplaces;
          console.log(this.filteredCompanyWorkplaces[0]);
        }
      } catch (error) {
        console.error("Error loading workplaces:", error);
      }
    },
    setDisplayStatusSelect(status) {
      this.displaystatusSelect = status;
    },
  },
  watch: {
    startTime() {
      // startTime이 변경될 때마다 filteredTimeOptions 갱신
      this.updateEndTimeOptions();
    },
    endTime() {
      // endTime이 변경될 때마다 filteredTimeOptions 갱신
      this.updateEndTimeOptions();
    },
    selectedShapeIndex(newIndex) {
      console.log("selectedShapeIndex changed to:", newIndex);
    },
  },
};
</script>

<style scoped>
.image-preview {
  width: 100% !important;
  height: 400px !important;
  overflow-x: auto;
  overflow-y: auto;
}

.preview-img {
  display: block;
  z-index: 1;
}

.canvas_wrapper {
  width: max-content;
  height: max-content;
  max-width: 2000px;
  position: relative;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.vue-draggable-resizable {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.seat-list {
  margin-top: 20px;
}
.vue-draggable-resizable .drag-handle {
  cursor: move;
}

.vue-draggable-resizable .handle-tm,
.vue-draggable-resizable .handle-bm {
  cursor: ns-resize;
}

.vue-draggable-resizable .handle-ml,
.vue-draggable-resizable .handle-mr {
  cursor: ew-resize;
}
.vue-draggable-resizable .handle {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
}

.vue-draggable-resizable .handle-tm {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.vue-draggable-resizable .handle-bm {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.vue-draggable-resizable .handle-ml {
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.vue-draggable-resizable .handle-mr {
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
}
.highlighted {
  background-color: #ffdddd; /* 강조 색상 */
  border: 1px solid #ff0000; /* 테두리 강조 */
}
.shape-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  pointer-events: none;
}
</style>
