<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt="" />설정
    </div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/setting/notice/list"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 목록
          </router-link>
        </li>
        <!-- <li>
          <router-link
            to="/setting/notice/add"
            :class="{ on: selectedMenu === 1 }"
            @click="selectMenu(1)"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 등록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/notice/view"
            :class="{ on: selectedMenu === 2 }"
            @click="selectMenu(2)"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 상세보기
          </router-link>
        </li> -->
        <li>
          <router-link
            to="/setting/termsofuse/list"
            :class="{ on: selectedMenu === 3 }"
            @click="selectMenu(3)"
            v-if="userType === 'S'"
          >
            이용약관 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/privacy/list"
            :class="{ on: selectedMenu === 6 }"
            @click="selectMenu(6)"
            v-if="userType === 'S'"
          >
            개인정보처리방침 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/user/join/list"
            :class="{ on: selectedMenu === 9 }"
            @click="selectMenu(9)"
            v-if="userType === 'S'"
          >
            <!-- 회원가입항목 목록(슈퍼용) -->
            추가정보관리(슈퍼용)
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/user/join/listTwo"
            :class="{ on: selectedMenu === 10 }"
            @click="selectMenu(10)"
            v-if="userType === 'C'"
          >
            추가정보관리
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/meetingroom/access/control"
            :class="{ on: selectedMenu === 11 }"
            @click="selectMenu(11)"
            v-if="userType === 'C'"
          >
            상세 출입 설정
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/etc/noshow/control"
            :class="{ on: selectedMenu === 12 }"
            @click="selectMenu(12)"
            v-if="userType === 'C'"
          >
            노쇼 기능 설정
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/etc/tablet/list"
            :class="{ on: selectedMenu === 13 }"
            @click="selectMenu(13)"
            v-if="userType === 'C'"
          >
            태블릿 관리
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting_Left",
  data() {
    return {
      selectedMenu: null,
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", menuPath); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (path === "/setting/management" || path === "/setting/notice/list") {
        this.selectedMenu = 0;
      } else if (path === "/setting/notice/add") {
        this.selectedMenu = 0;
      } else if (path === "/setting/notice/view") {
        this.selectedMenu = 0;
      } else if (path === "/setting/termsofuse/list") {
        this.selectedMenu = 3;
      } else if (path === "/setting/termsofuse/add") {
        this.selectedMenu = 3;
      } else if (path === "/setting/termsofuse/view") {
        this.selectedMenu = 3;
      } else if (path === "/setting/privacy/list") {
        this.selectedMenu = 6;
      } else if (path === "/setting/privacy/add") {
        this.selectedMenu = 6;
      } else if (path === "/setting/privacy/view") {
        this.selectedMenu = 6;
      } else if (path === "/setting/user/join/list") {
        this.selectedMenu = 9;
      } else if (path === "/setting/user/join/list2") {
        this.selectedMenu = 10;
      } else if (path === "/setting/meetingroom/access/control") {
        this.selectedMenu = 11;
      } else if (path === "/setting/etc/noshow/control") {
        this.selectedMenu = 12;
      } else if (path === "/setting/etc/tablet/list") {
        this.selectedMenu = 13;
      }
      this.$store.dispatch("updateSelectedMenu", "/setting/management"); // Vuex 상태 업데이트
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
