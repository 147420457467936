<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 목록</div>
        <div class="description">공지사항을 조회하고 관리할 수 있습니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="chgSelectCompany"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="chgSelectWorkplace"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: searchPostYN === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: searchPostYN === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        게시
                      </button>
                      <button
                        type="button"
                        :class="{ on: searchPostYN === 'N' }"
                        @click="updateStatus('N')"
                      >
                        게시안함
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>제목별</th>
                  <td>
                    <div class="inner_td">
                      <div class="inner_td">
                        <input
                          type="checkbox"
                          id="fixedNotice"
                          v-model="isFixed"
                        />
                        <label for="fixedNotice">고정글만 표시</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="pop_text"
                        v-model="searchKey"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span class="accent_color">{{ this.totalNotices }}</span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>개씩</span>
            </div>
            <button type="button" @click="goToNoticeAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 14%" />
                  <col style="width: 18%" />
                  <col />
                  <col style="width: 16%" />
                  <col style="width: 9%" />
                  <col style="width: 9%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                        :disabled="!checkSelectAll"
                      />
                    </th>
                    <th @click="sort('company_name')" class="sortable">
                      <a href="###">회사명</a>
                      <img v-if="orderType === 'company_name asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'company_name desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplace_name')" class="sortable">
                      <a href="###">사업장명</a>
                      <img v-if="orderType === 'workplace_name asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'workplace_name desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('notice_title')" class="sortable">
                      <a href="###">제목</a>                      
                      <img v-if="orderType === 'notice_title asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'notice_title desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>작성자</th>
                    <th @click="sort('created_dtm')" class="sortable">
                      <a href="###">작성일</a>                          
                      <img v-if="orderType === 'created_dtm asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'created_dtm desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody v-if="noticeList.length > 0">
                  <tr
                    v-for="notice in paginatedNotice"
                    :key="notice.noticeDetailId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(notice.noticeDetailId)"
                        :checked="selectedNotices.includes(notice.noticeDetailId)"
                        :disabled="notice.createdUserId !== loginUserId"
                      />
                    </td>
                    <td class="seat o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ notice.companyName }}</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">사업장명 : </span>
                      <span>{{ notice.workplaceName }}</span>
                    </td>
                    <td class="detail_page_a long_txt o2 o_title o_br">
                      <div>
                        <router-link
                          :to="{
                            name: 'SettingNoticeView',
                            params: { id: notice.noticeId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold mlong_p">
                            {{ notice.fixType === 'Y' ? '[ 고정글 ] ' + notice.title : notice.title }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">작성자 : </span>
                      <span>{{ notice.createdUserName }}</span>
                    </td>

                    <td class="num phone o6r">
                      <span class="only_mobile">작성일 : </span>
                      <span>{{ notice.createdDate }}</span>
                    </td>
                    <td class="center_td o_stat">
                      <div v-if="notice.postType === 'Y'" class="stat back_green">
                        <span>게시</span>
                      </div>
                      <div v-else class="stat back_red">
                        <span>게시안함</span>
                      </div>
                    </td>
                  </tr>                  
                </tbody>
                <tbody v-else>
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <button type="button" @click.prevent="delNotice">
                삭제
              </button>
              <button type="button" @click.prevent="updatePostType('Y')">
                게시
              </button>
              <button type="button" @click.prevent="updatePostType('N')">
                게시안함
              </button>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient";
import { useToast } from "vue-toastification";

export default {
  name: "SettingNoticeList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      noticeList: [],
      companies: [],
      workplaces: [],
      selectedNotices: [],
      selectedCompany: "0",
      selectedWorkplace: "0",
      totalNotices: 0,
      currentPage: 1,
      itemsPerPage: 10,
      pageGroupSize: 5,
      searchKey: "",
      searchPostYN: "A",
      orderType: "",
      searchFixType: "N",
      isFixed: false,
      allSelected: false,
      loginUserId: "",
    };
  },
  watch: {
    isFixed(newValue) {
      this.searchFixType = newValue ? "Y" : "N";
      this.getNoticeList();
    }
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },    
    paginatedNotice() {
      if (!Array.isArray(this.noticeList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.noticeList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.totalNotices / this.itemsPerPage);
    },
    pageGroup() {
      const start = Math.floor((this.currentPage - 1) / this.pageGroupSize) * this.pageGroupSize + 1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    checkSelectAll() {
      return this.noticeList.some(notice => notice.createdUserId === this.loginUserId);
    }
  },
  methods: {    
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedNotices = this.noticeList
          .filter(notice => notice.createdUserId === this.loginUserId)
          .map(notice => notice.noticeDetailId);
      } else {
        this.selectedNotices = [];
      }
    },
    toggleSelection(noticeDetailId) {
      const selectedNotice = this.noticeList.find(
        (notice) => notice.noticeDetailId === noticeDetailId
      );

      if (!selectedNotice || selectedNotice.createdUserId !== this.loginUserId) {
        return;
      }
      
      const isSelected = this.selectedNotices.includes(noticeDetailId);

      if (isSelected) {
        const index = this.selectedNotices.indexOf(noticeDetailId);
        if (index > -1) {
          this.selectedNotices.splice(index, 1);
        }
      } else {
        this.selectedNotices.push(noticeDetailId);
      }
    },
    goToNoticeAdd() {
      this.$router.push({ path: "/setting/notice/add" });
    },
    async chgSelectCompany() {
      this.currentPage = 1;
      this.selectedWorkplace = "0";
      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany);
      }
      this.getNoticeList();
    },
    async chgSelectWorkplace() {
      this.currentPage = 1;
      this.getNoticeList();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId,
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async getNoticeList() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";
        if (this.userType === "S") {
          v_companyId = this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace;
        } else if (this.userType === "C") {
          v_companyId = this.companyId;
          v_workplaceId = this.selectedWorkplace === "0" ? "0" : this.selectedWorkplace;
        } else if (this.userType === "W") {
          v_companyId = this.selectedCompany;
          v_workplaceId = this.selectedWorkplace;
        }
        
        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.orderType,
          PostType: this.searchPostYN,
          FixType: this.searchFixType,
          LoginUserCompanyId: this.companyId,
          LoginUserWorkplaceId: this.workplaceId,
          UserType: this.userType,
          CompanyId: v_companyId,
          WorkplaceId: v_workplaceId,
          SearchKey: this.searchKey,
        };
        
        const response = await apiClient.post(
          "/api/Notice/all-notice-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.noticeList = Array.isArray(data.data) ? data.data : [];
          this.totalNotices = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching notice data:", error);
      }
    },
    updateStatus(status) {
      this.searchPostYN = status;
      this.currentPage = 1;
      this.noticeList = [];
      this.getNoticeList();
    },
    async updatePostType(type) {
      if (this.selectedNotices.length === 0) {
		    this.toast.error(type === 'Y' ? '게시를 원하시는 공지사항 리스트를 선택해주세요.' : '게시를 원하지 않는 공지사항 리스트를 선택해주세요.', {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      
      try {
        const promises = this.selectedNotices.map(async (detailId) => {
          return apiClient.post("/api/Notice/notice-postyn-update", {
            noticeDetailId: detailId,
            PostType: type,
            LoginUserId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to notice-postyn-update for ID ${this.selectedNotices[index]}`
            );
          }
        });
        
        this.selectedNotices = [];
        this.allSelected = false;        
        this.getNoticeList();
      } catch (error) {
        console.error("Error notice-postyn-update:", error);
      }
    },
    async delNotice() {
      if (this.selectedNotices.length === 0) {
		    this.toast.error("삭제를 원하시는 공지사항 리스트를 선택해주세요", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      
      try {
        const promises = this.selectedNotices.map(async (detailId) => {
          return apiClient.post("/api/Notice/notice-del", {
            noticeDetailId: detailId,
            LoginUserId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to notice-del for ID ${this.selectedNotices[index]}`
            );
          }
        });
        
        this.selectedNotices = [];
        this.allSelected = false;        
        this.getNoticeList();
      } catch (error) {
        console.error("Error notice-del:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.noticeList = [];
        this.getNoticeList();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.noticeList = [];
        this.getNoticeList();
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.noticeList = [];
      this.getNoticeList();
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.noticeList = [];
      this.getNoticeList();
    },
    goToPage(page) {
      this.currentPage = page;
      this.noticeList = [];
      this.getNoticeList();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1;
      this.noticeList = [];
      this.getNoticeList();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1;
      this.noticeList = [];
      this.getNoticeList();
    }, 
    sort(field) {
      if (this.orderType === `${field} asc`) {
        this.orderType = `${field} desc`;
      } else {
        this.orderType = `${field} asc`;
      }
      this.getNoticeList();
    },
  },
  mounted() {
    this.loginUserId = localStorage.getItem("userid");
    if (this.userType === "C") {
      this.companyId = localStorage.getItem("companyid");
      this.fetchWorkplaces(this.companyId);
      this.getNoticeList();
    } else if (this.userType === "W") {
      this.companyId =this.selectedCompany =  localStorage.getItem("companyid");
      this.workplaceId = this.selectedWorkplace =  localStorage.getItem("workplaceid");
      this.getNoticeList();
    } else {
      this.fetchCompanies();
      this.getNoticeList();
    }
  }
};
</script>

<style scoped></style>
