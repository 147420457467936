<template>
  <div class="body_right">
    <div class="right_container">
      <!-- 입실 후 상태 -->
      <div class="right_title">
        <div class="title">인증내역 상세보기</div>
        <div class="description"></div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
              <div class="part_title">인증정보</div>
              <table>
                  <colgroup>
                      <col style="width: 18%" />
                      <col />
                  </colgroup>
                  <tr>
                      <th>인증시각</th>
                      <td>2024-06-11 17:04:00</td>
                  </tr>
                  <tr>
                    <th>인증자</th>
                    <td>홍길동</td>
                </tr>
                  <tr>
                      <th>인증결과</th>
                      <td>실패 (사용자 비활성화)</td>
                  </tr>
  
              </table>
          </div>
          <div class="btn_bottom_a">
              <a href="###">목록</a>
          </div>
        </article>
      </div>
      <!-- //입실 후 상태 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CertificationView",
};
</script>

<style scoped></style>
