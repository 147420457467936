<template>
  <ul>
    <li
      v-for="(node, index) in nodes.data"
      :key="index"
      :style="{ paddingLeft: node.level * 20 + 'px' }"
    >
      <!-- 접고 펼치기 아이콘 추가 -->
      <span @click="toggleNode(index)" style="cursor: pointer">
        <!-- 접힌 상태일 때는 + 표시, 펼쳐진 상태일 때는 - 표시 -->
        <span v-if="node.children && node.children.length > 0">
          {{ isExpanded(index) ? "-" : "+" }}
        </span>
      </span>
      <!-- 부서 이름 클릭 시 selectDepartment 호출 -->
      <!-- level이 1보다 큰 경우만 클릭 허용 -->
      <span
        @click="node.level > 1 && selectDepartment(node)"
        :style="{
          cursor: node.level > 1 ? 'pointer' : 'default',
          color: node.level > 1 ? 'black' : 'gray',
        }"
      >
        {{ node.deptName }}
      </span>

      <!-- 하위 부서가 있을 경우 재귀적으로 호출, 펼쳐진 상태일 때만 표시 -->
      <department-node
        v-if="isExpanded(index) && node.children && node.children.length > 0"
        :nodes="{ data: node.children }"
        @select-department="selectDepartment"
      ></department-node>
    </li>
  </ul>
</template>

<script>
export default {
  name: "DepartmentNode",
  props: {
    nodes: {
      type: Object, // nodes는 객체이며 data 배열을 포함하고 있음
      required: true,
    },
  },
  data() {
    return {
      expandedNodes: new Set(), // 접힘/펼침 상태를 관리하는 Set
    };
  },
  methods: {
    // 노드의 접힘/펼침 상태를 토글하는 함수
    toggleNode(index) {
      if (this.expandedNodes.has(index)) {
        this.expandedNodes.delete(index); // 펼쳐진 상태에서 접기
      } else {
        this.expandedNodes.add(index); // 접힌 상태에서 펼치기
      }
    },
    // 노드가 펼쳐진 상태인지 확인하는 함수
    isExpanded(index) {
      return this.expandedNodes.has(index); // 펼쳐진 상태면 true 반환
    },
    selectDepartment(node) {
      this.$emit("select-department", node); // 선택된 부서 정보를 부모 컴포넌트로 전달
    },
  },
};
</script>

<style scoped>
/* 각 항목에 기본 스타일을 적용 */
li {
  list-style: none;
  margin: 5px 0;
}

span {
  padding: 5px;
}

span:hover {
  background-color: #f0f0f0;
  border-radius: 3px;
}
</style>
