<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">태블릿 상세보기</div>
        <div class="description">
          태블릿의 상세정보를 조회하고 수정할 수 있습니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">회사정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      placeholder="회사명을 선택해주세요"
                      v-model="companyName"
                      ref="companyName"
                    />
                    <button type="button" class="keep" style="display: none">
                      선택
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    v-model="workplaceName"
                    ref="workplaceName"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>장치이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="deviceName"
                    ref="deviceName"
                  />
                </td>
              </tr>
            </table>
          </div>          
          <div class="btn_bottom_a">
            <a href="###" class="on" @click="saveTablet">저장</a>
            <a type="button" @click="goToTabletList">목록</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingTabletView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      tabletInfo: [],
      deviceName: "",
      companyId: "",
      companyName: "",
      workplaceId: "",
      workplaceName: "",
      
    };
  },
  computed: {
    storeuserId() {
      return this.$store.getters.getUserid;
    },
    storeuserType() {
      return this.$store.getters.getUsertype;
    },
    storecompanyId() {
      return this.$store.getters.getCompanyid;
    },
    storeworkplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
  },
  mounted() {
    this.fetchTabletData();
  },
  methods: {
    async fetchTabletData() {
      try {
        const response = await apiClient.post("/api/Tablet/tablet-info", {
          deviceId: this.id,
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.tabletInfo = Array.isArray(data.data) ? data.data : [];
          this.deviceName = this.tabletInfo[0].deviceName;
          this.companyId = this.tabletInfo[0].companyId;
          this.companyName = this.tabletInfo[0].companyName;
          this.workplaceId = this.tabletInfo[0].workplaceId;
          this.workplaceName = this.tabletInfo[0].workplaceName;
        }
      } catch (error) {
        console.error("Error fetching tablet data:", error);
      }
    },
    goToTabletList() {
      this.$router.push({ path: "/setting/etc/tablet/list" });
    },
    async saveTablet() {
      if (!this.deviceName || this.deviceName.trim() === "") {
		    this.toast.error("장치이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.deviceName.focus();
        return;
      }

      try {
        const response = await apiClient.post("/api/Tablet/tablet-edit", {
          CompanyId: this.companyId,
          WorkplaceId: this.workplaceId,
          DeviceId: this.id,
          DeviceName: this.deviceName
        });
        if (response.status === 200) {
          this.toast.success("태블릿 정보가 성공적으로 수정되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.goToTabletList();
        }
      } catch (error) {
        console.error("Error saving user data:", error);
		    this.toast.error("태블릿 정보 수정 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>