<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사업장 목록</div>
        <div class="description">사업장을 조회하고 관리할 수 있습니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>진열상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'A' }"
                        @click="
                          updateStatus('A');
                          fetchData;
                        "
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'Y' }"
                        @click="
                          updateStatus('Y');
                          fetchData;
                        "
                      >
                        활성화
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'N' }"
                        @click="
                          updateStatus('N');
                          fetchData;
                        "
                      >
                        비활성화
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="workplaceName">사업장명</option>
                        <!-- <option value="workplaceRegistrationNo">
                          사업자등록번호
                        </option> -->
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalWorkplaces }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
            <button type="button" @click="goToWorkplaceAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="workplaceList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 18%" />
                  <col />
                  <col style="width: 14%" />
                  <col style="width: 14%" />
                  <col style="width: 15%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a>번호</a>
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img
                        v-if="OrderType === 'companyName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'companyName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img
                        v-if="OrderType === 'workplaceName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'workplaceName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('createDtm')" class="sortable">
                      <a>생성일</a>
                      <img
                        v-if="OrderType === 'createDtm_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'createDtm_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('lastUpdateDtm')" class="sortable">
                      <a>수정일</a>
                      <img
                        v-if="OrderType === 'lastUpdateDtm_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'lastUpdateDtm_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th>활성화상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="workplace in paginatedWorkplaces"
                    :key="workplace.workplaceId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(workplace.workplaceId)"
                        :checked="
                          selectedWorkplaces.includes(workplace.workplaceId)
                        "
                      />
                    </td>
                    <td class="no_mobile">{{ workplace.no }}</td>
                    <td class="o3 o_br">
                      <span class="only_mobile">회사명 : </span>
                      <p class="long_p">
                        <!-- {{ workplace.companyId }} -->
                        <!-- {{ workplace.workplaceId }} -->
                        {{ workplace.companyName }}
                      </p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="inner_td">
                        <router-link
                          :to="{
                            name: 'Workplaceview',
                            params: { id: workplace.workplaceId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ workplace.workplaceName }}
                          </p>
                          <img src="@/images/detail_page.png"
                        /></router-link>
                      </div>
                    </td>
                    <td class="num o4">
                      <span class="only_mobile">생성일 : </span>
                      <p class="long_p">{{ workplace.formattedCreateDtm }}</p>
                    </td>
                    <td class="num o5r">
                      <span class="only_mobile">수정일 : </span>
                      <p class="long_p">
                        {{ workplace.formattedLastUpdateDtm }}
                      </p>
                    </td>
                    <td class="center_td o_stat">
                      <div>
                        <input
                          type="checkbox"
                          :id="'toggle' + workplace.workplaceId"
                          class="toggle"
                          hidden
                          :checked="workplace.activeStatus === 'Y'"
                          @change="toggleActiveStatus(workplace)"
                        />
                        <label
                          :for="'toggle' + workplace.workplaceId"
                          class="toggleSwitch"
                        >
                          <span class="toggleButton"></span
                        ></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="7">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 14%" />
                  <col style="width: 14%" />
                  <col style="width: 15%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회사명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">사업장명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">생성일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">수정일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>활성화상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="7">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="activateSelected">활성화</a>
              <a @click.prevent="deactivateSelected">비활성화</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "WorkplaceList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      workplaceList: [], // 사업장 목록 데이터를 저장할 배열
      selectedWorkplaces: [], // 선택된 사업장들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "workplaceName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchActiveStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalWorkplaces: 0, // 총 사업장 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
    };
  },
  watch: {
    selectedWorkplaces(newVal) {
      this.allSelected = newVal.length === this.workplaceList.length;
    },
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedWorkplaces() {
      if (!Array.isArray(this.workplaceList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.workplaceList);
      //return this.workplaceList.slice(start, end);
      return this.workplaceList.slice(start, end).map((workplace) => {
        // 생성일과 수정일은 그냥 풀로 넘기고 있음 (yyyy-mm-dd 만 사용하기 위하여 아래 소스 추가)
        return {
          ...workplace,
          formattedCreateDtm: workplace.createDtm
            ? workplace.createDtm.substring(0, 10)
            : "-",
          formattedLastUpdateDtm: workplace.lastUpdateDtm
            ? workplace.lastUpdateDtm.substring(0, 10)
            : "-",
        };
      });
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalWorkplaces / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },

  methods: {
    handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "" : this.selectedCompany;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          SearchActiveStatus: this.SearchActiveStatus, // 진열상태별 값 추가
          OrderType: this.OrderType,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };
        console.log("CompanyId----" + searchParam.CompanyId);
        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaceList = Array.isArray(data.data) ? data.data : [];
          this.totalWorkplaces = data.meta.totalCount || 0;

          // 기존 데이터와 새로운 데이터를 비교하여 계속 새로운 데이터를 불러와야 할때 아래처럼 하면 됨
          // if (JSON.stringify(this.workplaceList) !== JSON.stringify(data.data)) {
          //   this.workplaceList = []; // 기존 데이터 비움
          //   this.$nextTick(() => {
          //     this.workplaceList = Array.isArray(data.data) ? data.data : [];
          //     this.totalWorkplaces = data.meta.totalCount || 0;
          //   });
          // } else {
          //   this.workplaceList = Array.isArray(data.data) ? data.data : [];
          //   this.totalWorkplaces = data.meta.totalCount || 0;
          // }
        }
      } catch (error) {
        console.error("Error fetching workplace data:", error);
      }
    },
    updateStatus(status) {
      this.SearchActiveStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.workplaceList = []; // 회사 목록 초기화
      this.fetchData();
    },
    // 비슷한 조건이 추가되면 아래처럼 추가하면됨..
    // updateStatus2(status) {
    //   this.SearchActiveStatus = status;
    //   this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
    //   this.workplaceList = []; // 회사 목록 초기화
    //   this.fetchData();
    // },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.workplaceList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.workplaceList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.workplaceList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.workplaceList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.workplaceList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.workplaceList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.workplaceList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleDateChange() {
      this.currentPage = 1; // 날짜 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    goToWorkplaceAdd() {
      this.$router.push({ path: "/workplace/add" });
    },
    async toggleActiveStatus(workplace) {
      try {
        const newStatus = workplace.activeStatus === "Y" ? "N" : "Y";
        const action = newStatus === "Y" ? "활성화" : "비활성화";

        if (confirm(`사업장을 ${action}하시겠습니까?`)) {
          const response = await apiClient.post(
            "/api/Workplace/workplace-active-update",
            {
              WorkplaceId: workplace.workplaceId,
              ActiveStatus: newStatus,
              LoginId: localStorage.getItem("userid"),
            }
          );

          if (response.status === 200) {
            workplace.activeStatus = newStatus; // 업데이트 후 상태 변경
          } else {
            console.error("Failed to update workplace status");
          }
        } else {
          // 사용자가 취소를 선택한 경우, 상태를 원래대로 유지
          // Vue가 상태를 변경하지 않도록 체크박스를 다시 원래 상태로 설정
          this.$nextTick(() => {
            const checkbox = document.getElementById(
              `toggle${workplace.workplaceId}`
            );
            if (checkbox) {
              checkbox.checked = workplace.activeStatus === "Y";
            }
          });
        }
      } catch (error) {
        console.error("Error updating workplace status:", error);
      }
    },
    async updateWorkplaceStatus(selectedIds, status) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Workplace/workplace-active-update", {
            WorkplaceId: id,
            ActiveStatus: status,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status === 200) {
            const id = selectedIds[index];
            // 선택된 회사들의 상태 업데이트
            this.workplaceList.forEach((workplace) => {
              if (workplace.workplaceId === id) {
                workplace.activeStatus = status;
              }
            });
          } else {
            console.error(
              `Failed to update workplace status for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedWorkplaces = [];
        this.allSelected = false; // thead 체크박스 해제
      } catch (error) {
        console.error("Error updating workplace status:", error);
      }
    },
    async DeleteWorkplace(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Workplace/workplace-delete", {
            WorkplaceId: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete workplace for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedWorkplaces = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting workplace:", error);
      }
    },
    activateSelected() {
      if (this.selectedWorkplaces.length === 0) {
		    this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      if (confirm("선택된 항목을 활성화하시겠습니까?")) {
        this.updateWorkplaceStatus(this.selectedWorkplaces, "Y");
      }
    },
    deactivateSelected() {
      if (this.selectedWorkplaces.length === 0) {
		    this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 비활성화하시겠습니까?")) {
        this.updateWorkplaceStatus(this.selectedWorkplaces, "N");
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedWorkplaces = this.workplaceList.map(
          (workplace) => workplace.workplaceId
        );
      } else {
        this.selectedWorkplaces = [];
      }
    },
    toggleSelection(workplaceId) {
      const index = this.selectedWorkplaces.indexOf(workplaceId);

      if (index > -1) {
        this.selectedWorkplaces.splice(index, 1);
      } else {
        this.selectedWorkplaces.push(workplaceId); // 기존 코드를 이 줄로 수정
      }
    },
    deleteSelected() {
      if (this.selectedWorkplaces.length === 0) {
		    this.toast.error("원하시는 삭제 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 삭제하시겠습니까?")) {
        this.DeleteWorkplace(this.selectedWorkplaces);
      }
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  mounted() {
    this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchData();
  },
};
</script>

<style>
.gray-shade {
  background-color: #f8f3f3; /* 연한 회색 음영 */
}
.sortable {
  cursor: pointer;
}
</style>
