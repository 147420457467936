<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">인증내역 목록</div>
        <div class="description">모든 인원의 인증 내역을 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>인증수단별</th>
                  <td>
                    <div class="inner_td">
                      <button type="button" class="on">전체보기</button>
                      <button type="button">카드(QR)</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>인증상태별</th>
                  <td>
                    <div class="inner_td">
                      <button type="button" class="on">전체보기</button>
                      <button type="button">성공</button>
                      <button type="button">실패</button>
                      <button type="button">거부</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>이름</option>
                        <option>구역명</option>
                        <option>휴대폰번호</option>
                      </select>
                      <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                      />
                      <button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span class="accent_color"> </span>
              <span>건</span>
              <select>
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
            <button type="button">엑셀 다운로드</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 20%" />
                  <col />
                  <col style="width: 15%" />
                  <col style="width: 16%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <a href="###">인증시각</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">구역명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>휴대폰번호</th>
                    <th>인증수단</th>
                    <th>인증상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="num o1 o_br">
                      <span class="only_mobile">인증시각 : </span>
                      <span>2024-06-11 17:04:00</span>
                    </td>
                    <td class="long_txt o3 o_br">
                      <span class="only_mobile">구역명 : </span>
                      <span>{구역명}</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <a href="###" class="center_td">
                          <p class="text_bold">김유경</p>
                          <img src="@/images/detail_page.png" />
                        </a>
                      </div>
                    </td>
                    <td class="num o4">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>010-1234-5678</span>
                    </td>
                    <td class="o5r">
                      <span class="only_mobile">인증수단 : </span>
                      <span>카드(QR)</span>
                    </td>
                    <td class="center_td o_stat">
                      <div class="stat back_green">
                        <span>성공</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num o1 o_br">
                      <span class="only_mobile">인증시각 : </span>
                      <span>2024-06-11 17:04:00</span>
                    </td>
                    <td class="long_txt o3 o_br">
                      <span class="only_mobile">구역명 : </span>
                      <span>{구역명}</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <a href="###" class="center_td">
                          <p class="text_bold">김유경</p>
                          <img src="@/images/detail_page.png" />
                        </a>
                      </div>
                    </td>
                    <td class="num o4">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>010-1234-5678</span>
                    </td>
                    <td class="o5r">
                      <span class="only_mobile">인증수단 : </span>
                      <span>카드(QR)</span>
                    </td>
                    <td class="center_td o_stat">
                      <div class="stat back_lightgrey">
                        <span>실패</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num o1 o_br">
                      <span class="only_mobile">인증시각 : </span>
                      <span>2024-06-11 17:04:00</span>
                    </td>
                    <td class="long_txt o3 o_br">
                      <span class="only_mobile">구역명 : </span>
                      <span>{구역명}</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <a href="###" class="center_td">
                          <p class="text_bold">김유경</p>
                          <img src="@/images/detail_page.png" />
                        </a>
                      </div>
                    </td>
                    <td class="num o4">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>010-1234-5678</span>
                    </td>
                    <td class="o5r">
                      <span class="only_mobile">인증수단 : </span>
                      <span>카드(QR)</span>
                    </td>
                    <td class="center_td o_stat">
                      <div class="stat back_red">
                        <span>거부</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="6">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CertificationList",
};
</script>

<style scoped></style>
