<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">노쇼 기능 설정</div>
        <div class="description">
          · 회의실 예약 제한을 노쇼 횟수로 설정할 수 있습니다. <br />
          · 페이지 하단 ‘허용 설정 추가’를 클릭하면, 사업장 별로 허용횟수를
          다르게 설정할 수 있습니다.
        </div>
        <article class="right_body">
          <div
            v-for="(setting, index) in settings"
            :key="setting.id"
            class="tbl_search tbl_info"
            v-show="!isAnyAllLocationsChecked || setting.allLocations"
          >
            <div class="part_title between">
              <span>허용설정 {{ index + 1 }}</span>
              <button v-if="index > 0" @click="removeSetting(index)">
                삭제
              </button>
            </div>
            <table>
              <colgroup>
                <col style="width: 20%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>경고 횟수</th>
                <td>
                  <div class="inner_td">
                    <select v-model.number="setting.selectedCautionCnt" @change="handleCautionChange(index)">
                      <option value="" disabled v-if="!setting.selectedCautionCnt">선택하세요</option>
                      <option v-for="option in cautionOptions()" :key="option" :value="option">
                        {{ option }}회
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="setting.selectedCautionCnt">
                <th>1주일 예약 불가</th>
                <td>
                  <div class="inner_td">
                    <select v-model.number="setting.selectedWeekCnt" @change="handleWeekChange(index)">
                      <option value="" disabled selected v-if="!setting.selectedWeekCnt">선택하세요</option>
                      <option v-for="option in weekOptions(setting.selectedCautionCnt)" :key="option" :value="option">
                        {{ option }}회
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="setting.selectedWeekCnt">
                <th>한달 예약 불가</th>
                <td>
                  <div class="inner_td">
                    <select v-model.number="setting.selectedMonthCnt">
                      <option value="" disabled selected v-if="!setting.selectedMonthCnt">선택하세요</option>
                      <option v-for="option in monthOptions(setting.selectedWeekCnt)" :key="option" :value="option">
                        {{ option }}회
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>적용대상</th>
                <td>
                  <div class="inner_boxs workplace">
                    <div class="inner_box_column">
                      <div class="checks innerbox_title">
                        <input
                          type="checkbox"
                          :id="'Workplace' + setting.id"
                          v-model="setting.allLocations"
                          @change="checkAllLocations(index)"
                        />
                        <label :for="'Workplace' + setting.id">
                          모든 사업장</label
                        >
                      </div>
                      <div v-show="!setting.allLocations" class="tagbox">
                        <button @click="showModal(index)">+사업장추가</button>
                        <ul class="attendee_add group_choice tags">
                          <li
                            v-for="(
                              location, locIndex
                            ) in setting.workplaceNames"
                            :key="locIndex"
                          >
                            <div>
                              <span>{{ location }}</span>
                            </div>
                            <a
                              @click="removeLocation(index, locIndex)"
                              style="cursor: pointer"
                              >⨉</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" @click.prevent="addSetting">+허용설정추가</a>
            <a href="###" class="on" @click.prevent="saveSettings">저장</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 사업장 선택 modal -->
  <div
    id="Modal2"
    class="d_pop"
    :style="{ display: isModal2Visible ? 'block' : 'none' }"
    v-if="isModal2Visible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">사업장 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_200"
                      placeholder="사업장명을 입력해주세요"
                      v-model="SearchKey"
                      @keyup.enter="searchWorkplace"
                    />
                    <button type="button" @click="searchWorkplace">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 10%" />
              <col />
              <col style="width: 19%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @change="checkSelectAll"
                  />
                </th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workplace in paginatedWorkplaces"
                :key="workplace.workplaceId"
                :class="{ disabled: isWorkplaceDisabled(workplace) }"
              >
                <td>
                  <input
                    type="checkbox"
                    v-model="workplace.selected"
                    :disabled="isWorkplaceDisabled(workplace)"
                  />
                </td>
                <td>{{ workplace.workplaceName }}</td>
                <td>
                  <button
                    @click="selectWorkplace(workplace)"
                    class="keep"
                    :disabled="isWorkplaceDisabled(workplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="confirmSelection">
          선택완료
        </button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <!-- //사업장 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingNoShowControl",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedCautionCnt: null,
      selectedWeekCnt: null,
      selectedMonthCnt: null,
      maxLimit: 20,
      settings: [], // API 로부터 가져온 데이터 설정묶음
      workplaceId: "", // 사업장 아이디 선택값
      isModal2Visible: false, // 모달 표시 상태
      workplaces: [], // 모달에 표시할 사업장 목록
      selectedSettingIndex: null, // 현재 선택된 설정의 인덱스
      selectAll: false, // 전체 선택 체크박스 상태
      totalWorkplaces: 0, // 총 사업장 수
      SearchKey: "", // 검색 키워드
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
    };
  },
  mounted() {
    this.loadSettings();
    this.loadWorkplaces();
  },
  computed: {
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalWorkplaces / this.itemsPerPage);
    },
    // 현재 페이지에 해당하는 사업장 목록을 반환
    paginatedWorkplaces() {
      if (!Array.isArray(this.workplaces)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log("Paginated Workplaces: ", this.workplaces.slice(start, end));

      return this.workplaces.slice(start, end);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    isAnyAllLocationsChecked() {
      return this.settings.some((setting) => setting.allLocations);
    },
  },
  methods: {
    cautionOptions() {
      return Array.from({ length: this.maxLimit }, (_, i) => i + 1);
    },
    weekOptions(cautionCnt) {
      if (!cautionCnt) return [];
      return Array.from({ length: this.maxLimit - cautionCnt }, (_, i) => i + cautionCnt + 1);
    },
    monthOptions(weekCnt) {
      if (!weekCnt) return [];
      return Array.from({ length: this.maxLimit - weekCnt }, (_, i) => i + weekCnt + 1);
    },
    handleCautionChange(index) {
      const setting = this.settings[index];
      if (setting.selectedWeekCnt && setting.selectedWeekCnt <= setting.selectedCautionCnt) {
        setting.selectedWeekCnt = "";
        setting.selectedMonthCnt = "";
      }
    },
    handleWeekChange(index) {
      const setting = this.settings[index];
      if (setting.selectedMonthCnt && setting.selectedMonthCnt <= setting.selectedWeekCnt) {
        setting.selectedMonthCnt = "";
      }
    },
    isWorkplaceDisabled(workplace) {
      return (
        workplace.disabled ||
        this.settings.some((setting) =>
          setting.workplaceIds.includes(workplace.workplaceId)
        )
      );
    },
    async loadSettings() {
      try {
        const searchParam = {
          CompanyId: localStorage.getItem("companyid"),
        };
        const response = await apiClient.post(
          "/api/NoShow/noshow-setting-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          if (data.data.length === 0) {
            this.settings = [this.createDefaultSetting()];
          } else {
            this.settings = data.data.map((item) => ({
              id: Date.now() + Math.random(),
              companyId: item.companyId,
              packageNum: item.packageNum,
              workplaceIds: item.workplaceId.split("^"),
              workplaceNames: item.workPlaceName.split("^"),
              cautionCnt: item.cautionCnt,
              weekCnt: item.weekCnt,
              monthCnt: item.monthCnt,
              allLocations: false,
              selectedCautionCnt: Number(item.cautionCnt),
              selectedWeekCnt: Number(item.weekCnt),
              selectedMonthCnt: Number(item.monthCnt),
            }));
          }
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    async loadWorkplaces() {
      try {
        const searchParam = {
          SearchType: "workplaceName",
          SearchKey: this.SearchKey,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: localStorage.getItem("companyid"),
        };
        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          this.totalWorkplaces = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    createDefaultSetting() {
      const newPackageNum =
          this.settings.length > 0
            ? Math.max(...this.settings.map((s) => parseInt(s.packageNum))) + 1
            : 1;

      return {
        id: Date.now() + Math.random(),
        companyId: localStorage.getItem("companyid"),
        packageNum: newPackageNum.toString(),
        workplaceIds: [],
        workplaceNames: [],
        cautionCnt: "",
        weekCnt: "",
        monthCnt: "",
        allLocations: false,
        selectedCautionCnt: "",
        selectedWeekCnt: "",
        selectedMonthCnt: "",
      };
    },
    addSetting() {
      if (this.settings.length >= 10) {
		    this.toast.error("최대 10개의 허용설정만 추가할 수 있습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } else {      
        this.settings.push(this.createDefaultSetting());
        this.settings.sort((a, b) => a.packageNum - b.packageNum);
      }
    },
    showModal(index) {
      this.selectedSettingIndex = index;
      this.updateWorkplacesDisabledState();
      this.isModal2Visible = true;
    },
    // 비활성화 상태 업데이트
    updateWorkplacesDisabledState() {
      const disabledWorkplaceIds = new Set(
        this.settings.flatMap((setting) => setting.workplaceIds)
      );

      // 모든 사업장 비활성화 상태 업데이트
      this.workplaces.forEach((workplace) => {
        workplace.disabled = disabledWorkplaceIds.has(workplace.workplaceId);
      });
    },
    hideModal() {
      this.isModal2Visible = false;
      this.workplaces.forEach((workplace) => {
        delete workplace.disabled; // 비활성화 상태 제거
      });
    },
    selectWorkplace(workplace) {
      const setting = this.settings[this.selectedSettingIndex];

      if (setting.workplaceIds.includes(workplace.workplaceId)) {
		    this.toast.error("이미 허용설정 선택된 사업장입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      setting.workplaceIds.push(workplace.workplaceId);
      setting.workplaceNames.push(workplace.workplaceName);

      this.hideModal();
    },
    confirmSelection() {
      const selectedWorkplaces = this.workplaces.filter(
        (workplace) => workplace.selected
      );
      if (selectedWorkplaces.length === 0) {
		    this.toast.error("사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      selectedWorkplaces.forEach((workplace) => {
        this.selectWorkplace(workplace);
      });

      this.hideModal();
    },
    removeSetting(index) {
      if (this.settings.length === 1) {
		    this.toast.error("최소 하나의 허용설정은 필요합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } else {
        this.settings.splice(index, 1);
      }
    },
    removeLocation(settingIndex, locIndex) {
      const confirmed = confirm("삭제하시겠습니까?");
      if (confirmed) {
        this.settings[settingIndex].workplaceIds.splice(locIndex, 1);
        this.settings[settingIndex].workplaceNames.splice(locIndex, 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      if (this.hasNextPageGroup) {
        this.currentPage = this.pageGroup[this.pageGroup.length - 1] + 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup() {
      if (this.hasPrevPageGroup) {
        this.currentPage = this.pageGroup[0] - 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    async searchWorkplace() {
      this.currentPage = 1;
      this.workplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    goToPage(page) {
      this.currentPage = page;
      this.workplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.workplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.workplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    checkSelectAll() {
      const selectAllState = this.selectAll;
      this.workplaces.forEach((workplace) => {
        workplace.selected = selectAllState;
      });
    },
    checkAllLocations(index) {
      if (this.settings[index].allLocations) {
        const confirmed = confirm(
          "모든 사업장이 선택되면 다른 허용설정은 초기화됩니다. 진행하시겠습니까?"
        );
        if (confirmed) {
          this.settings = this.settings.filter((_, i) => i === index);
        } else {
          // 확인을 누르지 않았을 경우 체크를 해제합니다.
          this.settings[index].allLocations = false;
        }
      } else {
        this.settings[index].workplaceIds = [];
        this.settings[index].workplaceNames = [];
      }
    },
    async saveSettings() {
      // 빈 설정 확인
      const emptySettings = this.settings.filter(
        (setting) => !setting.allLocations && setting.workplaceIds.length === 0
      );

      // 빈 설정이 있는 경우 경고 메시지 표시
      if (emptySettings.length > 0) {		
		    this.toast.error("설정을 하지 않은 허용설정 부분이 있습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      // 모든 사업장이 선택되지 않은 경우 경고 표시
      const totalSelectedWorkplaces = this.settings.reduce((acc, setting) => {
        if (setting.allLocations) {
          return acc + this.totalWorkplaces; // 모든 사업장이 선택된 것으로 간주
        } else {
          return acc + setting.workplaceIds.length;
        }
      }, 0);

      if (totalSelectedWorkplaces < this.totalWorkplaces) {
		    this.toast.error("선택되지 않은 사업장이 있습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      // 빈 설정을 제외하고 서버로 설정 값을 전송
      const payload = this.settings
        .filter(
          (setting) => setting.allLocations || setting.workplaceIds.length > 0
        ) // 빈 설정을 제외
        .map((setting) => ({
          companyId: setting.companyId,
          packageNum: setting.allLocations ? "1" : setting.packageNum, // '모든 사업장' 체크된 경우 packageNum을 1로 설정
          workplaceIds: setting.allLocations
            ? this.workplaces
                .map((workplace) => workplace.workplaceId)
                .join("^") // 모든 사업장 ID를 추가
            : setting.workplaceIds.join("^"),
          cautionCnt: setting.selectedCautionCnt.toString(),
          weekCnt: setting.selectedWeekCnt.toString(),
          monthCnt: setting.selectedMonthCnt.toString(),
        }));

      console.log(payload);

      try {
        // 각 payload 요소에 대해 API 호출
        const savePromises = payload.map(async (data) => {
          const response = await apiClient.post(
            "/api/NoShow/noshow-setting-save",
            {
              CompanyId: data.companyId,
              PackageNum: data.packageNum,
              WorkplaceId: data.workplaceIds,
              CautionCnt: data.cautionCnt,
              WeekCnt: data.weekCnt,
              MonthCnt: data.monthCnt,
              LoginId: localStorage.getItem("userid"),
            }
          );

          if (response.status !== 200) {
            throw new Error(
              "Error saving settings for packageNum: " + data.packageNum
            );
          }

          return response.data;
        });

        // 모든 API 호출이 완료될 때까지 기다림
        await Promise.all(savePromises);
		    this.toast.success("노쇼 기능 설정 정보가 성공적으로 저장되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } catch (error) {
        console.error("Error saving intime data:", error);	
		    this.toast.error("노쇼 기능 설정 정보가 저장 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
  },
};
</script>

<style scoped>
.warning_message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

/* 비활성화된 항목의 스타일 */
.disabled {
  background-color: #f5f5f5;
  color: #b0b0b0;
  cursor: not-allowed;
}

.disabled input,
.disabled button {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
