<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회사 목록</div>
        <div class="description">회사를 조회하고 관리할 수 있습니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>진열상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'A' }"
                        @click="
                          updateStatus('A');
                          fetchData;
                        "
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'Y' }"
                        @click="
                          updateStatus('Y');
                          fetchData;
                        "
                      >
                        활성화
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'N' }"
                        @click="
                          updateStatus('N');
                          fetchData;
                        "
                      >
                        비활성화
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="companyName">회사명</option>
                        <option value="companyRegistrationNo">
                          사업자등록번호
                        </option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalCompanys }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
            <button type="button" @click="goToCompanyAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="companyList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 14%" />
                  <col style="width: 14%" />
                  <col style="width: 15%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a>번호</a>
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img
                        v-if="OrderType === 'companyName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'companyName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('companyRegistrationNo')" class="sortable">
                      <a>사업자등록번호</a>
                      <img
                        v-if="OrderType === 'companyRegistrationNo_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'companyRegistrationNo_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('createDtm')" class="sortable">
                      <a>생성일</a>
                      <img
                        v-if="OrderType === 'createDtm_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'createDtm_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('lastUpdateDtm')" class="sortable">
                      <a>수정일</a>
                      <img
                        v-if="OrderType === 'lastUpdateDtm_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'lastUpdateDtm_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th>활성화상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="company in paginatedCompanys"
                    :key="company.id"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(company.id)"
                        :checked="selectedCompanies.includes(company.id)"
                      />
                    </td>
                    <td class="no_mobile">{{ company.no }}</td>
                    <td class="detail_page_a o2 o_title">
                      <div>
                        <router-link
                          :to="{
                            name: 'CompanyView',
                            params: { id: company.id },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ company.companyName }}
                          </p>
                          <img src="@/images/detail_page.png"
                        /></router-link>
                      </div>
                    </td>
                    <td class="num seat o3 o_br">
                      <span class="only_mobile">사업자등록번호 : </span>
                      <span>{{ company.companyRegistrationNo }}</span>
                    </td>
                    <td class="num o4">
                      <span class="only_mobile">생성일 : </span>
                      <span>{{ company.formattedCreateDtm }}</span>
                    </td>
                    <td class="num o5r">
                      <span class="only_mobile">수정일 : </span>
                      <span>{{ company.formattedLastUpdateDtm }}</span>
                    </td>
                    <td class="center_td o_stat">
                      <div>
                        <input
                          type="checkbox"
                          :id="'toggle' + company.id"
                          class="toggle"
                          hidden
                          :checked="company.activeStatus === 'Y'"
                          @change="toggleActiveStatus(company)"
                        />
                        <label
                          :for="'toggle' + company.id"
                          class="toggleSwitch"
                        >
                          <span class="toggleButton"></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 14%" />
                  <col style="width: 14%" />
                  <col style="width: 15%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회사명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">사업자등록번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">생성일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">수정일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>활성화상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="7">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="activateSelected">활성화</a>
              <a @click.prevent="deactivateSelected">비활성화</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "CompanyList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companyList: [], // 회사 목록 데이터를 저장할 배열
      selectedCompanies: [], // 선택된 회사들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "companyName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchActiveStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalCompanys: 0, // 총 회사 수
    };
  },
  watch: {
    selectedCompanies(newVal) {
      this.allSelected = newVal.length === this.companyList.length;
    },
  },
  computed: {
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedCompanys() {
      if (!Array.isArray(this.companyList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.companyList);
      //return this.companyList.slice(start, end);
      return this.companyList.slice(start, end).map((company) => {
        // 생성일과 수정일은 그냥 풀로 넘기고 있음 (yyyy-mm-dd 만 사용하기 위하여 아래 소스 추가)
        return {
          ...company,
          formattedCreateDtm: company.createDtm
            ? company.createDtm.substring(0, 10)
            : "-",
          formattedLastUpdateDtm: company.lastUpdateDtm
            ? company.lastUpdateDtm.substring(0, 10)
            : "-",
        };
      });
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalCompanys / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    async fetchData() {
      try {
        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          SearchActiveStatus: this.SearchActiveStatus, // 진열상태별 값 추가
          OrderType: this.OrderType,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
        };
        console.log(searchParam);
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.companyList = Array.isArray(data.data) ? data.data : [];
          this.totalCompanys = data.meta.totalCount || 0;

          // 기존 데이터와 새로운 데이터를 비교하여 계속 새로운 데이터를 불러와야 할때 아래처럼 하면 됨
          // if (JSON.stringify(this.companyList) !== JSON.stringify(data.data)) {
          //   this.companyList = []; // 기존 데이터 비움
          //   this.$nextTick(() => {
          //     this.companyList = Array.isArray(data.data) ? data.data : [];
          //     this.totalCompanys = data.meta.totalCount || 0;
          //   });
          // } else {
          //   this.companyList = Array.isArray(data.data) ? data.data : [];
          //   this.totalCompanys = data.meta.totalCount || 0;
          // }
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    },
    updateStatus(status) {
      this.SearchActiveStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.companyList = []; // 회사 목록 초기화
      this.fetchData();
    },
    // 비슷한 조건이 추가되면 아래처럼 추가하면됨..
    // updateStatus2(status) {
    //   this.SearchActiveStatus = status;
    //   this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
    //   this.companyList = []; // 회사 목록 초기화
    //   this.fetchData();
    // },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.companyList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.companyList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.companyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.companyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.companyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.companyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.companyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleDateChange() {
      this.currentPage = 1; // 날짜 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    goToCompanyAdd() {
      //
      this.$router.push({ path: "/company/add" });
    },
    async toggleActiveStatus(company) {
      try {
        const newStatus = company.activeStatus === "Y" ? "N" : "Y";
        const action = newStatus === "Y" ? "활성화" : "비활성화";

        if (confirm(`회사를 ${action}하시겠습니까?`)) {
          const response = await apiClient.post(
            "/api/Company/company-active-update",
            {
              Id: company.id,
              ActiveStatus: newStatus,
              LoginId: localStorage.getItem("userid"),
            }
          );

          if (response.status === 200) {
            company.activeStatus = newStatus; // 업데이트 후 상태 변경
          } else {
            console.error("Failed to update company status");
          }
        } else {
          // 사용자가 취소를 선택한 경우, 상태를 원래대로 유지
          // Vue가 상태를 변경하지 않도록 체크박스를 다시 원래 상태로 설정
          this.$nextTick(() => {
            const checkbox = document.getElementById(`toggle${company.id}`);
            if (checkbox) {
              checkbox.checked = company.activeStatus === "Y";
            }
          });
        }
      } catch (error) {
        console.error("Error updating company status:", error);
      }
    },
    async updateCompanyStatus(selectedIds, status) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Company/company-active-update", {
            Id: id,
            ActiveStatus: status,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status === 200) {
            const id = selectedIds[index];
            // 선택된 회사들의 상태 업데이트
            this.companyList.forEach((company) => {
              if (company.id === id) {
                company.activeStatus = status;
              }
            });
          } else {
            console.error(
              `Failed to update company status for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedCompanies = [];
        this.allSelected = false; // thead 체크박스 해제
      } catch (error) {
        console.error("Error updating company status:", error);
      }
    },
    async DeleteCompany(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Company/company-delete", {
            Id: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete company for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedCompanies = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting companies:", error);
      }
    },
    activateSelected() {
      if (this.selectedCompanies.length === 0) {
        this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      if (confirm("선택된 항목을 활성화하시겠습니까?")) {
        this.updateCompanyStatus(this.selectedCompanies, "Y");
      }
    },
    deactivateSelected() {
      if (this.selectedCompanies.length === 0) {
        this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 비활성화하시겠습니까?")) {
        this.updateCompanyStatus(this.selectedCompanies, "N");
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedCompanies = this.companyList.map((company) => company.id);
      } else {
        this.selectedCompanies = [];
      }
    },
    toggleSelection(companyId) {
      const index = this.selectedCompanies.indexOf(companyId);

      if (index > -1) {
        this.selectedCompanies.splice(index, 1);
      } else {
        this.selectedCompanies.push(companyId); // 기존 코드를 이 줄로 수정
      }
    },
    deleteSelected() {
      if (this.selectedCompanies.length === 0) {
        this.toast.error("원하시는 삭제 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 삭제하시겠습니까?")) {
        this.DeleteCompany(this.selectedCompanies);
      }
    },
    sort(field) {
      // companyName
      // companyRegistrationNo
      // createDtm
      // lastUpdateDtm
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
    //this.refreshInterval = setInterval(this.fetchData, 1000); // 1초마다 데이터 갱신
  },
  // beforeUnmount() {
  //   clearInterval(this.refreshInterval); // 컴포넌트가 언마운트될 때 인터벌 제거
  // },
};
</script>

<style>
.gray-shade {
  background-color: #f8f3f3; /* 연한 회색 음영 */
}
.sortable {
  cursor: pointer;
}
</style>
