<template>
  <div class="loginwrapper">
    <div class="container">
      <form @submit.prevent="login">
        <div class="wrap">
          <img src="@/images/logo.png" alt="Logo" class="logo" />
          <div class="logotxt">스마트 오피스 솔루션</div>
          <input
            type="text"
            v-model="userid"
            id="userid"
            ref="userid"
            placeholder="아이디"
            TabIndex="1"
            autofocus
          />
          <input
            type="password"
            v-model="password"
            id="password"
            placeholder="비밀번호"
            ref="password"
            TabIndex="2"
          />
          <div class="id_wrap">
            <div>
              <input
                id="chkLogin"
                type="checkbox"
                v-model="autoLogin"
                name="chkLogin"
              /><label for="chkLogin">자동로그인</label>
            </div>
          </div>
          <button type="submit">로그인</button>
          <div class="line"></div>
          <span
            style="font-weight: 300; letter-spacing: 0.6em; font-size: 0.7em"
            >Smart Office</span
          >
          <div class="bottom"></div>
        </div>
        <div class="footer_tl">MizeCloud</div>
        <div class="footer_tr">
          <a href="http://mizeinc.co.kr/index.html" target="_blank"
            >Help | <img src="@/images/q_color.png"
          /></a>
        </div>
        <div class="footer_l">
          <a href="https://mizeinc.co.kr/introduce.html" target="_blank"
            ><img src="@/images/logo.png"
          /></a>
        </div>
        <div class="footer">Copyright ⓒ Mize Inc. All Rights Reserved.</div>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  data() {
    return {
      LoginInfo: [],
      userid: "",
      password: "",
      stylesLoaded: false, // 스타일 로드 상태를 저장할 변수
      autoLogin: false, // 자동 로그인 상태를 저장할 변수
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async login() {
      if (!this.userid) {
        this.toast.error("아이디를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.userid.focus();
        return;
      }
      if (!this.password) {
        this.toast.error("비밀번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.password.focus();
        return;
      }

      const user = {
        UserId: this.userid,
        Password: this.password,
      };

      try {
        const response = await apiClient.post("/api/Auth/login", user, {
          withCredentials: true,
        });

        if (response.status === 200) {
          const accessToken = response.data.user.AccessToken;

          // 자동로그인 체크박스 상태에 따라 로컬스토리지에 토큰 저장
          if (this.autoLogin) {
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("autoLogin", true);
          } else {
            localStorage.removeItem("autoLogin");
          }

          localStorage.setItem("userid", this.userid);
          localStorage.setItem("usertype", response.data.user.UserType);
          localStorage.setItem("companyid", response.data.user.CompanyId);
          localStorage.setItem("workplaceid", response.data.user.WorkplaceId);

          this.$router.push({ path: "/myinfo/management" });
        }
      } catch (error) {
        if (error.response) {
          // 요청이 서버에 도달했으나 응답을 받지 못한 경우
          console.error(`Error Status: ${error.response.status}`);
          console.error(`Error Message: ${error.response.data.message}`);
          this.toast.error(`${error.response.data.message}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        } else if (error.request) {
          // 요청이 서버에 도달하지 못한 경우
          console.error(`Request Error: ${error.request}`);
          this.toast.error("서버에 접근할 수 없습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        } else {
          console.error(`Error: ${error.message}`);
          this.toast.error(`${error.message}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async chkAutoLogin() {
      const autoLogin = localStorage.getItem("autoLogin");
      const accessToken = localStorage.getItem("accessToken");

      console.log("chkAutoLogin 함수 접근");
      console.log("autoLogin == " + autoLogin);
      console.log("accessToken == " + accessToken);
      if (autoLogin && accessToken) {
        console.log("토큰 인증 함수 접근");
        try {
          const response = await apiClient.post(
            "/api/Auth/verify-token",
            {},
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.$router.push({ path: "/reservation/management" });
          }
        } catch (error) {
          console.log("토큰 인증 함수 에러");
          if (error.response) {
            if (error.response.status === 401) {
              console.log("토큰 인증 함수 에러1");
              console.log(
                `Token expired or invalid: ${error.response.data.message}`
              );
              console.log("토큰 인증 함수 에러2");
              await this.refreshAccessToken();
            } else {
              console.log("토큰 인증 함수 에러3");
              console.error(`Error Status: ${error.response.status}`);
              console.error(`Error Message: ${error.response.data.message}`);
              this.logout();
            }
          } else {
            console.log("토큰 인증 함수 에러4");
            console.error(`Request Error: ${error.request}`);
            this.logout();
          }

          console.log("토큰 인증 함수 에러5");
        }
      } else {
        console.log("토큰 인증 함수 에러6");
        this.$router.push({ path: "/" });
      }
    },
    async refreshAccessToken() {
      try {
        const response = await apiClient.post(
          "/api/Auth/refresh-token",
          {},
          { withCredentials: true }
        );

        if (response.status === 200) {
          const accessToken = response.data.accessToken;
          localStorage.setItem("accessToken", accessToken);
          this.$router.push({ path: "/myinfo/management" });
        }
      } catch (error) {
        if (error.response) {
          // 401 등 오류 상태 코드 처리
          if (error.response.status === 401) {
            console.log(`Refresh Token Error: ${error.response.data.message}`);
          } else {
            console.error(`Error Status: ${error.response.status}`);
            console.error(`Error Message: ${error.response.data.message}`);
          }
        } else {
          console.error(`Request Error: ${error.request}`);
        }

        this.logout();
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    console.log("mounted 진입");
    this.chkAutoLogin();
  },
};
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
