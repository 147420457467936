<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_68.png" alt="" />예약관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <!-- <li>
          <router-link
            to="/reservation/meetingroom/status/list"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
          >
            회의실 예약목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/reservation/meetingroom/status/list"
            :class="{ on: selectedMenu === 1 }"
            @click="selectMenu(1)"
          >
            자율좌석 예약목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/reservation/publicroom/status/list"
            :class="{ on: selectedMenu === 2 }"
            @click="selectMenu(2)"
          >
            공용시설 예약목록
          </router-link>
        </li> -->
        <li v-for="(menu, index) in menuList" :key="menu.id">
          <router-link
            :to="getRoute(menu.productTypeName)"
            :class="{ on: selectedMenu === index }"
            @click="selectMenu(index, getRoute(menu.productTypeName))"
          >
            {{ menu.productTypeName }} 목록
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "Reservation_Left",
  data() {
    return {
      selectedMenu: null,
      menuList: [],
    };
  },
  computed: {
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    getRoute(name) {
      const routes = {
        "회의실": "/reservation/meetingroom/status/list",
        "자율좌석": "/reservation/freeseat/status/list",
        "공용시설": "/reservation/publicroom/status/list",
      };
      return routes[name] || "/";
    },
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", menuPath); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      const matchingMenu = this.menuList.find(
        (menu) => this.getRoute(menu.productTypeName?.trim()) === path
      );
      
      if (matchingMenu) {
        this.selectedMenu = this.menuList.indexOf(matchingMenu);
      } else {
        switch (path) {
          case "/reservation/management":
          case "/reservation/meetingroom/status/list":
          case "/reservation/meetingroom/status/view":
            this.selectedMenu = 0; // 회의실
            break;

          case "/reservation/freeseat/status/add":
          case "/reservation/freeseat/status/view":
            this.selectedMenu = 1; // 자율좌석
            break;
          
          case "/reservation/publicroom/status/add":
          case "/reservation/publicroom/status/view":
            this.selectedMenu = 2; // 공용시설
            break;

          default:
            this.selectedMenu = 0;
            break;
        }
      }
      this.$store.dispatch("updateSelectedMenu", "/reservation/management"); // Vuex 상태 업데이트
    },
    async fetchProductCategoryData() {
      try {
        const response = await apiClient.post("/api/Product/product-category-info", {
          LoginId: localStorage.getItem("userid"),
          SearchUserType: localStorage.getItem("usertype"),
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          
          const indexMapping = {
            "회의실": 0,
            "자율좌석": 1,
            "공용시설": 2,
          };

          const fetchedMenuList = (Array.isArray(data.data) ? data.data : []).map((menu) => ({
            ...menu,
            index: indexMapping[menu.productTypeName?.trim()] ?? -1,
          }));

          this.menuList = [...fetchedMenuList];
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      }
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.fetchProductCategoryData();
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
