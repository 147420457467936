<template>
  <div class="wrapper">
    <div class="container">
      <!-- 헤더 -->
      <keep-alive>
        <HeaderMenu />
      </keep-alive>
      <!-- //헤더 -->

      <!-- 바디 -->
      <div class="body">
        <div class="body_box">
          <!-- 바디레프트 -->
          <CompanyLeftMenu />
          <!-- //바디레프트 -->

          <!-- 바디라이트 -->
          <!-- 기본적으로 로그인하고 처음 홈에 들어왔을 때 뿌려질 content 는 방문관리 > 방문현황 -->
          <router-view />
          <!-- //바디라이트 -->
        </div>
      </div>
      <!-- //바디 -->

      <!-- 푸터 -->
      <FooterMenu />
      <!-- //푸터 -->
    </div>
  </div>
</template>

<script>
import HeaderMenu from "@/components/Layout/HeaderMenu.vue";
import CompanyLeftMenu from "@/components/Layout/Company_Left.vue";
import FooterMenu from "@/components/Layout/FooterMenu.vue";

export default {
  name: "CompanyManagement",
  components: {
    HeaderMenu,
    CompanyLeftMenu,
    FooterMenu,
  },
};
</script>

<style scoped></style>
