<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">직원 상세보기</div>
        <div class="description">
          직원의 상세정보를 조회하고 수정할 수 있습니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">회사정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      placeholder="회사명을 선택해주세요"
                      v-model="companyName"
                      ref="companyName"
                    />
                    <button type="button" class="keep" style="display: none">
                      선택
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업자명<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    v-model="workplaceName"
                    ref="workplaceName"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>아이디 (사원번호)<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      placeholder="아이디 (사원번호)를 입력해주세요"
                      v-model="userId"
                      ref="userId"
                    />
                    <div class="validation text_red" style="display: none">
                      이미 존재하는 아이디입니다.
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="w_250"
                    placeholder="비밀번호를 입력해주세요"
                    v-model="userPassword"
                    ref="userPassword"
                  />
                  <input
                    type="checkbox"
                    id="showPassword"
                    v-model="showPassword"
                  />
                  <label for="showPassword"> 비밀번호 보이기</label>
                </td>
              </tr>
              <tr>
                <th>이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                    v-model="userName"
                    ref="userName"
                  />
                </td>
              </tr>
              <tr>
                <th>재직 상태</th>
                <td>
                  <div class="inner_td">
                    <select class="w_250" v-model="activeStatus">
                      <option value="Y">재직</option>
                      <option value="N">퇴직</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>소속</th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250"
                      placeholder="소속을 선택해주세요"
                      v-model="deptName"
                      ref="deptName"
                    />
                    <button @click="showDeptPopup = true">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>직책</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="직책을 입력해주세요"
                    v-model="position"
                    ref="position"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <select v-model="phonePrefix">
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="tel"
                      class="w_55"
                      maxlength="4"
                      v-model="phoneMiddle"
                      @input="filterNumeric($event, 'phoneMiddle')"
                    />
                    -
                    <input
                      type="tel"
                      class="w_55"
                      maxlength="4"
                      v-model="phoneSuffix"
                      @input="filterNumeric($event, 'phoneSuffix')"
                    />
                  </div>
                </td>
              </tr>
              <tr style="display: none">
                <th>사진</th>
                <td class="td_address">
                  <div class="td_img">
                    <img src="@/images/no_img.png" alt="" />
                  </div>
                  <div>
                    <input
                      type="file"
                      class="w_250"
                      placeholder="선택된 파일 없음"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div
            class="tbl_search tbl_info"
            v-if="
              employeesignupInfo.length > 0 && employeesignupInfo[0].addItems
            "
          >
            <div class="part_title">추가정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr
                v-for="(item, itemIndex) in employeesignupInfo[0].addItems"
                :key="itemIndex"
              >
                <th>{{ item.addItemName }}</th>
                <td>
                  {{ item.addItemValue || "입력안함" }}
                  <!-- <input
                    type="text"
                    class="w_250 disabled_input"
                    v-model="item.addItemValue"
                  /> -->
                </td>
              </tr>
            </table>
          </div>

          <div class="tbl_search tbl_info">
            <div class="part_title">인증정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>카드(QR)</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: cardQrYN === 'Y' }"
                      @click="setCardQrStatus('Y')"
                    >
                      활성
                    </button>
                    <button
                      type="button"
                      :class="{ on: cardQrYN === 'N' }"
                      @click="setCardQrStatus('N')"
                    >
                      비활성
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" class="on" @click="saveEmployee">저장</a>
            <a href="###">삭제</a>
            <a type="button" @click="goToEmployeeList">목록</a>
          </div>
          <!-- 소속 선택 modal -->
          <div v-if="showDeptPopup" class="d_pop">
            <div class="d_pop_box">
              <div class="d_pop_top">
                <div class="title">소속 선택</div>
                <a href="###" @click="showDeptPopup = false">⨉</a>
              </div>
              <div class="d_pop_middle">
                <!-- 트리 구조 컴포넌트 -->
                <department-node
                  :nodes="departmentData"
                  @select-department="handleSelectDepartment"
                ></department-node>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentNode from "@/components/Layout/DepartmentNode.vue"; // 재귀 컴포넌트 import
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "EmployeeView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    DepartmentNode,
  },
  data() {
    return {
      employeeInfo: [],
      employeeRequestId: this.id,
      employeesignupInfo: [],
      userId: "",
      userPassword: "",
      userName: "",
      companyId: "",
      companyName: "",
      workplaceId: "",
      workplaceName: "",
      deptId: "",
      deptName: "",
      position: "",
      userPhone: "",
      userPhoto: "",
      activeStatus: "",
      cardQrYN: "",
      showPassword: false, // 비밀번호 보이기 체크박스 상태
      showDeptPopup: false, // 소속 선택 모달의 표시 여부
      departmentData: [], // 트리 구조로 사용할 데이터
    };
  },
  computed: {
    storeuserId() {
      return this.$store.getters.getUserid;
    },
    storeuserType() {
      return this.$store.getters.getUsertype;
    },
    storecompanyId() {
      return this.$store.getters.getCompanyid;
    },
    storeworkplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
  },
  mounted() {
    this.fetchEmployeeData();
    this.fetchAddItemData();
    this.fetchDepartmentData();
  },
  methods: {
    filterNumeric(event, model) {
      const input = event.target.value;
      // 숫자가 아닌 문자 제거
      const numericValue = input.replace(/\D/g, "");

      // v-model로 연결된 데이터에 필터링된 값 반영
      this[model] = numericValue;

      // 입력 필드 자체의 값을 직접 수정하여 필터링된 값만 남도록 처리
      event.target.value = numericValue;
    },
    handleSelectDepartment(selectedDepartment) {
      this.deptId = selectedDepartment.deptId;
      this.deptName = selectedDepartment.deptName;
      this.showDeptPopup = false; // 팝업 닫기
    },
    async fetchDepartmentData() {
      try {
        const response = await apiClient.post(
          "/api/Department/admin-my-depart-all-list",
          {
            UserId: this.storeuserId,
            UserType: this.storeuserType,
          }
        );

        if (response.status === 200) {
          this.departmentData = JSON.parse(response.data); // 트리 구조로 사용할 데이터
          console.log(this.departmentData);
        }
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    },
    setCardQrStatus(status) {
      this.cardQrYN = status;
    },
    async fetchAddItemData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/Employee/employee-signup-info",
          {
            RequestJoinId: this.id,
          }
        );
        if (response.status === 200) {
          console.log("------employeesignupInfo 원본------", response.data);

          // JSON 파싱 필요 여부 확인 후 적용
          const data =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;

          this.employeesignupInfo = Array.isArray(data.data) ? data.data : [];

          console.log("파싱 후 employeesignupInfo:", this.employeesignupInfo);

          this.isInitialLoad = false; // 초기 로드 완료
        }
      } catch (error) {
        console.error("Error employee signup info data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    async fetchEmployeeData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Employee/employee-info", {
          RequestJoinId: this.id,
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.employeeInfo = Array.isArray(data.data) ? data.data : [];

          this.employeeRequestId = this.employeeInfo[0].requestJoinId;
          this.userId = this.employeeInfo[0].userId;
          this.userPassword = this.employeeInfo[0].userPassword;
          this.userName = this.employeeInfo[0].userName;
          this.companyId = this.employeeInfo[0].companyId;
          this.companyName = this.employeeInfo[0].companyName;
          this.workplaceId = this.employeeInfo[0].workplaceId;
          this.workplaceName = this.employeeInfo[0].workplaceName;
          this.deptId = this.employeeInfo[0].deptId;
          this.deptName = this.employeeInfo[0].deptName;
          this.position = this.employeeInfo[0].position;
          this.userPhone = this.employeeInfo[0].userPhone;

          // 휴대폰 번호를 세 부분으로 나누기
          const phoneParts = this.userPhone.split("-");
          this.phonePrefix = phoneParts[0];
          this.phoneMiddle = phoneParts[1];
          this.phoneSuffix = phoneParts[2];

          this.userPhoto = this.employeeInfo[0].userPhoto;
          this.activeStatus = this.employeeInfo[0].activeStatus;
          this.cardQrYN = this.employeeInfo[0].cardQrYN;

          this.isInitialLoad = false; // 초기 로드 완료
        }
      } catch (error) {
        console.error("Error employee data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    goToEmployeeList() {
      this.$router.push({ path: "/employee/list" });
    },
    async saveEmployee() {
      // if (this.checkFields()) {
      this.userPhone = `${this.phonePrefix}-${this.phoneMiddle}-${this.phoneSuffix}`;

      console.log("★★--CompanyId--★★===" + this.companyId);
      console.log("★★--WorkplaceId--★★===" + this.workplaceId);
      console.log("★★--UserId--★★===" + this.userId);

      console.log("★★--UserName--★★===" + this.userName);
      console.log("★★--UserPassword--★★===" + this.userPassword);
      console.log("★★--ActiveStatus--★★===" + this.activeStatus);

      console.log("★★--DeptId--★★===" + this.deptId);
      console.log("★★--Position--★★===" + this.position);
      console.log("★★--UserPhone--★★===" + this.userPhone);
      console.log("★★--UserPhoto--★★===" + this.userPhoto);
      console.log("★★--CardQrYN--★★===" + this.cardQrYN);
      console.log("★★--LoginUserId--★★===" + localStorage.getItem("userid"));
      try {
        const response = await apiClient.post("/api/Employee/employee-edit", {
          CompanyId: this.companyId,
          WorkplaceId: this.workplaceId,
          UserId: this.userId,
          UserName: this.userName,
          UserPassword: this.userPassword,

          ActiveStatus: this.activeStatus,
          DeptId: this.deptId,
          Position: this.position,
          UserPhone: this.userPhone,
          UserPhoto: this.userPhoto,
          CardQrYN: this.cardQrYN,
          LoginUserId: localStorage.getItem("userid"),
          // 필요한 다른 필드 추가
        });
        if (response.status === 200) {
		    this.toast.success("직원 정보가 성공적으로 수정되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
		
          this.goToEmployeeList();
        }
      } catch (error) {
        console.error("Error saving user data:", error);
		    this.toast.error("직원 정보가 수정 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
      }
      // }
    },
  },
  // 파라미터를 받으려면 routes/index.js 파일에 :id 를 path 에 추가해줘야 함
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* 모달 스타일 */
.d_pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.d_pop_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
}
</style>
