<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">직원 등록</div>
        <div class="description">
          직원 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">회사정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250"
                      placeholder="회사명을 선택해주세요"
                    />
                    <button type="button" class="keep">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업자명<span class="text_red">*</span></th>
                <td>
                  <input type="text" class="w_250 disabled_input" />
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>아이디 (사원번호)<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="아이디 (사원번호)를 입력해주세요"
                    />
                    <div class="validation text_red">
                      이미 존재하는 아이디입니다.
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <input
                    type="password"
                    class="w_250"
                    placeholder="비밀번호를 입력해주세요"
                  />
                </td>
              </tr>
              <tr>
                <th>이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="이름을 입력해주세요"
                  />
                </td>
              </tr>
              <tr>
                <th>재직 상태</th>
                <td>
                  <div class="inner_td">
                    <select class="w_250">
                      <option>재직</option>
                      <option>퇴사</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>소속</th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 input에 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250"
                      placeholder="소속을 선택해주세요"
                    />
                    <button type="button">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>직책</th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="직책을 입력해주세요"
                  />
                </td>
              </tr>
              <tr>
                <th>휴대폰번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td mobile_number">
                    <select>
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input type="text" class="w_55" maxlength="4" />
                    -
                    <input type="text" class="w_55" maxlength="4" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>사진</th>
                <td class="td_address">
                  <div class="td_img">
                    <img src="@/images/no_img.png" alt="" />
                  </div>
                  <div>
                    <input
                      type="file"
                      class="w_250"
                      placeholder="선택된 파일 없음"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">인증정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>카드(QR)</th>
                <td>
                  <div class="inner_td">
                    <button type="button" class="on">활성</button>
                    <button type="button">비활성</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" class="on">등록</a>
            <a type="button" @click="goToEmployeeList">취소</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사/사업장 선택 modal -->
  <div id="Modal" class="d_pop" style="display: none">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">회사/사업장 선택</div>
        <a href="###">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select>
                      <option>회사명</option>
                      <option>사업자명</option>
                    </select>
                    <input
                      tyoe="text"
                      class="w_200"
                      placeholder="분류명을 입력해주세요"
                    />
                    <button type="button">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>회사명</th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>마이즈</td>
                <td>포스코타워점</td>
                <td><button class="keep">선택</button></td>
              </tr>
              <tr>
                <td>마이즈</td>
                <td>포스코타워점</td>
                <td><button class="keep">선택</button></td>
              </tr>
              <tr>
                <td>마이즈</td>
                <td>포스코타워점</td>
                <td><button class="keep">선택</button></td>
              </tr>
              <tr>
                <td>마이즈</td>
                <td>포스코타워점</td>
                <td><button class="keep">선택</button></td>
              </tr>
              <tr>
                <td>마이즈</td>
                <td>포스코타워점</td>
                <td><button class="keep">선택</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a href="###"
              ><img src="@/images/front_n.png" alt="처음페이지"
            /></a>
            <a href="###"><img src="@/images/prev_n.png" alt="이전페이지" /></a>
            <a href="###" class="on">1</a>
            <a href="###">2</a>
            <a href="###">3</a>
            <a href="###"><img src="@/images/next_n.png" alt="다음페이지" /></a>
            <a href="###"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //회사/사업장 선택 modal -->
</template>

<script>
export default {
  name: "EmployeeAdd",
  methods: {
    goToEmployeeList() {
      this.$router.push({ path: "/employee/list" });
    },
  },
};
</script>

<style scoped></style>
