<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 상세보기</div>
        <div class="description">공지사항을 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">공지대상 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>사용자형태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: userType === 'A' }"
                      @click="setUserType('A')"
                    >
                      전체
                    </button>
                    <button
                      type="button"
                      :class="{ on: userType === 'S' }"
                      @click="setUserType('S')"
                    >
                      관리자에게만
                    </button>
                    <button
                      type="button"
                      :class="{ on: userType === 'U' }"
                      @click="setUserType('U')"
                    >
                      사용자에게만
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>회사 및 사업장</th>
                <td>
                  <div class="inner_td eq_boxs">
                    <button
                      type="button"
                      :class="{ on: companyworkplaceSelect === 'A' }"
                      @click="setCompanyWorkplaceSelect('A')"
                    >
                      전체</button
                    ><button
                      type="button"
                      :class="{ on: companyworkplaceSelect === 'D' }"
                      @click="setCompanyWorkplaceSelect('D')"
                    >
                      세부지정
                    </button>
                  </div>
                  <div class="sepa"></div>
                  <div class="inner_td prelative">
                    <button @click="handleCompanySelection">회사선택</button>
                    <div class="checks">
                      <input
                        type="checkbox"
                        id="Company"
                        v-model="allCompaniesChecked"
                        @change="handleAllCompaniesChange"
                      />
                      <label for="Company"> 모든 회사</label>
                    </div>
                    <div
                      id="divSel0"
                      class="number"
                      v-show="companyworkplaceSelect === 'D'"
                    >
                      <span
                        >추가된 회사:
                        <span class="accent"
                          >총 {{ selectedCompanies.length }}개</span
                        ></span
                      >
                    </div>                    
                  </div>
                  <div id="divSel1" class="inner_boxs" v-show="companyworkplaceSelect === 'D'">
                    <div class="inner_box_column" 
                      v-for="(company, index) in selectedCompanies"
                      :key="index"
                    >
                      <div class="closebtn">
                        <a href="###" @click="removeCompany(index)">⨉</a>
                      </div>
                      <div class="checks innerbox_title">                        
                        <div class="title">{{ company.companyName }}</div>
                        <input
                          type="checkbox"
                          :id="'Company' + index"
                          v-model="company.allWorkplacesChecked"
                          @change="handleAllWorkplacesChange(index)"
                        />
                        <label :for="'Company' + index"> 모든 사업장</label>
                      </div>
                      <div class="tagbox" v-if="!company.allWorkplacesChecked">
                        <button @click="showWorkplaceModal(company.id)">
                          +사업장추가
                        </button>
                        <ul class="attendee_add group_choice tags">
                          <li
                            v-for="(workplace, wIndex) in company.workplaces"
                            :key="wIndex"
                          >
                            <div>
                              <span>{{ workplace.workplaceName }}</span>
                            </div>
                            <a
                              href="###"
                              @click="removeWorkplace(index, wIndex)"
                              >⨉</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">공지 작성</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>게시상태<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: postType === 'Y' }"
                      @click="setPostType('Y')"
                    >
                      게시
                    </button>
                    <button
                      type="button"
                      :class="{ on: postType === 'N' }"
                      @click="setPostType('N')"
                    >
                      게시안함
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상단고정<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: fixType === 'N' }"
                      @click="setFixType('N')"
                    >
                      상단고정안함
                    </button>
                    <button
                      type="button"
                      :class="{ on: fixType === 'Y' }"
                      @click="setFixType('Y')"
                    >
                      상단고정
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>제목<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_97p"
                    placeholder="제목을 입력해주세요"
                    v-model="noticeTitle"
                    ref="noticeTitle"
                  />
                </td>
              </tr>
              <tr>
                <th>내용<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <textarea
                      class="w_97p h_400"
                      placeholder="내용을 입력해주세요"
                      v-model="noticeContent"
                      ref="noticeContent"
                    ></textarea>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveNotice" v-if="isMatchedCreatedUserId">저장</button>
            <button type="button" @click="goToNoticeList">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isCompanyModalVisible ? 'block' : 'none' }"
    v-if="isCompanyModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">회사 선택</div>
        <a @click="hideCompanyModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_200"
                      placeholder="회사명을 입력해주세요"
                      v-model="SearchCompanyPopupKey"
                      @keyup.enter="searchCompany"
                    />
                    <button type="button" @click="searchCompany">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 10%" />
              <col />
              <col style="width: 19%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    v-model="isCheckedAllCompanyPopup"
                    @change="toggleSelectAllCompany($event)"
                  />
                </th>
                <th>회사명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="company in paginatedCompanies"
                :key="company.id"
                :class="{ disabled: isCompanySelected(company) }"
              >
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedCompaniesInModal.some(c => c.id === company.id)"
                    @change="toggleSelectCompany(company)"
                    :disabled="isCompanySelected(company)"
                  />
                </td>
                <td>{{ company.companyName }}</td>
                <td>
                  <button
                    @click="selectCompany(company)"
                    class="keep"
                    :disabled="isCompanySelected(company)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevCompnayPopupPageGroup"
              @click.prevent="prevCompanyPopupPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevCompnayPopupPage" @click.prevent="prevCompanyPopupPage">
              <img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="companyPopupPage in companyPopupPageGroup"
              :key="companyPopupPage"
              href="###"
              :class="{ on: currentCompanyPopupPage === companyPopupPage }"
              @click.prevent="companyPopupGoToPage(companyPopupPage)"
            >
              {{ companyPopupPage }}
            </a>
            <a href="###" v-if="hasNextCompanyPopupPage" @click.prevent="nextCompanyPopupPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextCompanyPopupPageGroup" @click.prevent="nextCompanyPopupPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="confirmCompanySelection">
          선택완료
        </button>
        <button type="button" @click="hideCompanyModal">취소</button>
      </div>
    </div>
  </div>
  <!-- //회사 선택 modal -->
  <!-- 사업장 선택 modal -->
  <div
    id="Modal2"
    class="d_pop"
    :style="{ display: isWorkplaceModalVisible ? 'block' : 'none' }"
    v-if="isWorkplaceModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">사업장 선택</div>
        <a href="###" @click="hideWorkplaceModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_200"
                      placeholder="사업장명을 입력해주세요"
                      v-model="SearchWorkplacePopupKey"
                      @keyup.enter="searchWorkplace"
                    />
                    <button type="button">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 10%" />
              <col />
              <col style="width: 19%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"                    
                    v-model="isCheckedAllWorkplacePopup"
                    @change="toggleSelectAllWorkplaces($event)"
                  />
                </th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workplace in paginatedWorkplaces"
                :key="workplace.workplaceId"
                :class="{ disabled: isWorkplaceSelected(workplace) }"
              >
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedWorkplacesInModal.some(w => w.workplaceId === workplace.workplaceId)"
                    @change="toggleSelectWorkplace(workplace)"
                    :disabled="isWorkplaceSelected(workplace)"
                  />
                </td>
                <td>{{ workplace.workplaceName }}</td>
                <td>
                  <button
                    @click="selectWorkplace(workplace)"
                    class="keep"
                    :disabled="isWorkplaceSelected(workplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevWorkplacePopupPageGroup"
              @click.prevent="prevWorkplacePopupPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevWorkplacePopupPage" @click.prevent="prevWorkplacePopupPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="workplacePopupPage in workplacePopupPageGroup"
              :key="workplacePopupPage"
              href="###"
              :class="{ on: currentWorkplacePopupPage === workplacePopupPage }"
              @click.prevent="workplacePopupGoToPage(workplacePopupPage)"
            >
              {{ workplacePopupPage }}
            </a>
            <a href="###" v-if="hasNextWorkplacePopupPage" @click.prevent="nextWorkplacePopupPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a
              href="###" v-if="hasNextWorkplacePopupPageGroup" @click.prevent="nextWorkplacePopupPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="confirmWorkplaceSelection">
          선택완료
        </button>
        <button type="button" @click="hideWorkplaceModal">취소</button>
      </div>
    </div>
  </div>
  <!-- //사업장 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient";
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingNoticeView",  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      noticeInfo: [],
      noticeDetailId: this.id,      
      userType: "", //사용자 형태 (전체 / 관리자에게만 / 사용자에게만)
      companyworkplaceSelect: "", // 회사 및 사업장 ('전체','세부지정')
      postType: "",       // 게시상태
      fixType: "",        // 상단고정
      noticeTitle: "",    // 제목
      noticeContent: "",  // 내용
      createdUserId: "",  // 작성자
      companies: [], // API 에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      isCompanyModalVisible: false, // 모달 표시 상태
      isWorkplaceModalVisible: false,
      isCheckedAllCompanyPopup: false,
      isCheckedAllWorkplacePopup: false,
      totalCompanys: 0, // 총 회사 수
      totalWorkplaces: 0, // 총 사업장 수
      isMatchedCreatedUserId:false,

      SearchCompanyPopupType: "companyName", // 검색 기준
      SearchCompanyPopupKey: "", // 검색 키워드
      pageCompanyPopupGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentCompanyPopupPage: 1, // 현재 페이지
      itemsCompanyPopupPerPage: 5, // 페이지 당 항목 수

      SearchWorkplacePopupType: "workplaceName", // 검색 기준
      SearchWorkplacePopupKey: "", // 검색 키워드
      pageWorkplacePopupGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentWorkplacePopupPage: 1, // 현재 페이지
      itemsWorkplacePopupPerPage: 2, // 페이지 당 항목 수

      allCompaniesChecked: false,
      allWorkplacesChecked: false,
      selectedCompanies: [], // 선택된 회사 목록
      selectedWorkplaces: [], // 선택된 사업장 목록 초기화
      selectedCompaniesInModal: [], // 모달에서 선택된 회사 목록
      selectedWorkplacesInModal: [], // 모달에서 선택된 사업장 목록
      currentCompanyId: null, // 현재 선택된 회사 ID
      selectedCompaniesByPage: [],
      selectedWorkplacesByPage: [],
    };
  },
  watch: {
    selectedCompaniesInModal() {
      this.updateSelectAllCompanyPopup();
    },
    selectedWorkplacesInModal() {
      this.updateSelectAllWorkplacePopup();
    },
  },
  computed: {
    totalCompanyPopupPages() {
      return Math.ceil(this.totalCompanys / this.itemsCompanyPopupPerPage);
    },
    totalWorkplacePopupPages() {
      return Math.ceil(this.totalWorkplaces / this.itemsWorkplacePopupPerPage);
    },
    paginatedCompanies() {
      if (!Array.isArray(this.companies)) {
        return [];
      }

      const start = 0;
      const end = this.itemsCompanyPopupPerPage;
      return this.companies.slice(start, end);
    },
    paginatedWorkplaces() {
      if (!Array.isArray(this.workplaces)) {
        return [];
      }

      const start = 0;
      const end = this.itemsWorkplacePopupPerPage;
      return this.workplaces.slice(start, end);
    },
    companyPopupPageGroup() {
      const start = Math.floor((this.currentCompanyPopupPage - 1) / this.pageCompanyPopupGroupSize) * this.pageCompanyPopupGroupSize + 1;
      const end = Math.min(start + this.pageCompanyPopupGroupSize - 1, this.totalCompanyPopupPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevCompnayPopupPageGroup() {
      return this.currentCompanyPopupPage > 1;
    },
    hasNextCompanyPopupPageGroup() {
      return this.currentCompanyPopupPage < this.totalCompanyPopupPages;
    },
    hasPrevCompnayPopupPage() {
      return this.currentCompanyPopupPage > 1;
    },
    hasNextCompanyPopupPage() {
      return this.currentCompanyPopupPage < this.totalCompanyPopupPages;
    },
    workplacePopupPageGroup() {
      const start = Math.floor((this.currentWorkplacePopupPage - 1) / this.pageWorkplacePopupGroupSize) * this.pageWorkplacePopupGroupSize + 1;
      const end = Math.min(start + this.pageWorkplacePopupGroupSize - 1, this.totalWorkplacePopupPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevWorkplacePopupPageGroup() {
      return this.currentWorkplacePopupPage > 1;
    },
    hasNextWorkplacePopupPageGroup() {
      return this.currentWorkplacePopupPage < this.totalWorkplacePopupPages;
    },
    hasPrevWorkplacePopupPage() {
      return this.currentWorkplacePopupPage > 1;
    },
    hasNextWorkplacePopupPage() {
      return this.currentWorkplacePopupPage < this.totalWorkplacePopupPages;
    },
  },
  methods: {    
    setFixType(status) {
      this.fixType = status;
    },
    setPostType(status) {
      this.postType = status;
    },
    setUserType(status) {
      this.userType = status;
    },
    setCompanyWorkplaceSelect(status) {
      this.companyworkplaceSelect = status;
      this.allCompaniesChecked = status === "A" ? "true" : "false";
    },
    checkCreatedUserId() {
      const loginUserId = localStorage.getItem('userid');
      this.isMatchedCreatedUserId = this.createdUserId === loginUserId;
    },
    async fetchNoticeData() {
      try {
        const response = await apiClient.post("/api/Notice/notice-info", {
          NoticeId: this.id,
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.noticeInfo = Array.isArray(data.data) ? data.data : [];
          this.userType = this.noticeInfo[0].userType;
          this.companyworkplaceSelect = this.noticeInfo[0].target;
          this.allCompaniesChecked = this.noticeInfo[0].allCompanyChecked;
          this.allWorkplacesChecked = this.noticeInfo[0].allWorkplaceChecked;
          this.postType = this.noticeInfo[0].postType;
          this.fixType = this.noticeInfo[0].fixType;
          this.noticeTitle = this.noticeInfo[0].title;
          this.noticeContent = this.noticeInfo[0].content;
          this.createdUserId = this.noticeInfo[0].createdUserId;
          this.checkCreatedUserId();
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    async fetchNoticeCompanyWorkplaceData() {
      try {
        const response = await apiClient.post("/api/Notice/notice-company-workplace-list", {
          NoticeId: this.id,
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.selectedCompanies = this.selectedCompaniesInModal = Array.isArray(data.data) ? data.data : [];
          const selectedForCurrentPage = this.selectedCompaniesByPage[this.currentCompanyPopupPage] || [];
          this.selectedCompaniesInModal = selectedForCurrentPage;
          this.isCheckedAllCompanyPopup = this.paginatedCompanies.length > 0 &&
          this.paginatedCompanies.every(company => selectedForCurrentPage.includes(company.id));          
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    handleItemsPerPageChange() {
      this.currentCompanyPopupPage = 1;
      this.companies = [];
      this.loadCompanies();
    },
    handleSearchKeyEnter() {
      this.currentCompanyPopupPage = 1;
      this.companies = [];
      this.loadCompanies();
    },
    handleAllCompaniesChange() {
      if (this.allCompaniesChecked) {
        this.setCompanyWorkplaceSelect("A");
        this.selectedCompanies = [];
      } else {
        this.setCompanyWorkplaceSelect("D");
      }
    },
    handleCompanySelection() {
      if (this.allCompaniesChecked === 'true') {
		    this.toast.error("모든 회사 체크박스를 해제하고 선택해주시기 바랍니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      } else {
        this.showCompanyModal();
      }
    },
    handleAllWorkplacesChange(index) {
      if (this.selectedCompanies[index].allWorkplacesChecked) {
        this.selectedCompanies[index].workplaces = [];
      }
    },
    goToNoticeList() {
      this.$router.push({ path: "/setting/notice/list" });
    },
    initCompanyPopup() {
      this.SearchCompanyPopupKey = '';  
      this.selectedCompaniesInModal = [];
      this.selectedCompaniesByPage = {};
      this.currentCompanyPopupPage = 1;
      this.isCheckedAllCompanyPopup = false;
      this.loadCompanies();
    },
    initWorkPlacePopup() {
      this.SearchWorkplacePopupKey = '';         
      this.selectedWorkplacesInModal = [];
      this.selectedWorkplacesByPage = {};
      this.currentWorkplacePopupPage = 1;
      this.isCheckedAllWorkplacePopup = false;
    },
    showCompanyModal() {
      this.isCompanyModalVisible = true;
    },
    hideCompanyModal() {
      this.isCompanyModalVisible = false;
      this.initCompanyPopup();
    },
    showWorkplaceModal(companyId) {      
      this.isWorkplaceModalVisible = true;
      this.loadWorkplaces(companyId);
    },
    hideWorkplaceModal() {
      this.isWorkplaceModalVisible = false;
      this.initWorkPlacePopup();
    },
    confirmCompanySelection() {
      const allSelectedCompaniesInModal = Object.values(this.selectedCompaniesByPage).flat();

      const newSelectedCompanies = allSelectedCompaniesInModal.filter(company => 
          !this.selectedCompanies.some(selectedCompany => selectedCompany.id === company.id)
      );

      this.selectedCompanies = [
        ...this.selectedCompanies,
        ...newSelectedCompanies,
      ];
      
      this.hideCompanyModal();
    },    
    confirmWorkplaceSelection() {
      const companyIndex = this.selectedCompanies.findIndex(
        (company) => company.id === this.currentCompanyId
      );

      if (companyIndex !== -1) {
        if (!Array.isArray(this.selectedCompanies[companyIndex].workplaces)) {
          this.selectedCompanies[companyIndex].workplaces = [];
        }

        this.selectedWorkplacesInModal.forEach((workplace) => {
          if (
            !this.selectedCompanies[companyIndex].workplaces.some(
              (w) => w.workplaceId === workplace.workplaceId
            )
          ) {
            this.selectedCompanies[companyIndex].workplaces.push({
              workplaceId: workplace.workplaceId,
              workplaceName: workplace.workplaceName,
            });
          }
        });
      }

      this.selectedWorkplaces = [];
      this.hideWorkplaceModal();
    },
    isCompanySelected(company) {
      return this.selectedCompanies.some(
        (selectedCompany) => selectedCompany.id === company.id
      );
    },    
    isWorkplaceSelected(workplace) {
      const companyIndex = this.selectedCompanies.findIndex(
        (company) => company.id === this.currentCompanyId
      );
      
      if (companyIndex !== -1) {
        const selectedCompany = this.selectedCompanies[companyIndex];

        if (Array.isArray(selectedCompany.workplaces)) {
          return selectedCompany.workplaces.some(
            (w) => w.workplaceId === workplace.workplaceId
          );
        } else {
          console.log(`selectedCompany workplaces is missing.`);
        }
      }
      return false;
    },
    restorePageSelection() {
      const page = this.currentWorkplacePopupPage;
      const currentPageSelected = this.selectedWorkplacesByPage[page] || [];
      currentPageSelected.forEach(workplace => {
        const exists = this.selectedWorkplacesInModal.some(w => w.workplaceId === workplace.workplaceId);
        if (!exists) {
          this.selectedWorkplacesInModal.push(workplace);
        }
      });
      
      this.updateSelectAllWorkplacePopup();
    },    
    companyPopupGoToPage(page) {
      this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
      this.currentCompanyPopupPage = page;
      this.companies = [];
      this.loadCompanies();
    },
    async workplacePopupGoToPage(page) {
      this.currentWorkplacePopupPage = page;
      this.workplaces = [];
      await this.loadWorkplaces(this.currentCompanyId);
      this.restorePageSelection();
    },
    nextCompanyPopupPage() {
      if (this.currentCompanyPopupPage < this.totalCompanyPopupPages) {
        this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
        this.currentCompanyPopupPage++;
        this.isCheckedAllCompanyPopup = false;
        this.loadCompanies();
      }
    },
    prevCompanyPopupPage() {
      if (this.currentCompanyPopupPage > 1) {
        this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
        this.currentCompanyPopupPage--;
        this.isCheckedAllCompanyPopup = false;
        this.loadCompanies();
      }
    },
    nextCompanyPopupPageGroup() {
      this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
      this.isCheckedAllCompanyPopup = false;
      this.currentCompanyPopupPage = this.totalCompanyPopupPages;
      this.loadCompanies();
    },
    prevCompanyPopupPageGroup() {
      this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
      this.currentCompanyPopupPage = 1;
      this.loadCompanies();
    },
    nextWorkplacePopupPage() {
      if (this.currentWorkplacePopupPage < this.totalWorkplacePopupPages) {
        this.selectedWorkplacesByPage[this.currentWorkplacePopupPage] = [...this.selectedWorkplacesInModal];    
        this.currentWorkplacePopupPage++;
        this.isCheckedAllWorkplacePopup = false;
        this.loadWorkplaces(this.currentCompanyId);
        this.restorePageSelection();
      }
    },
    prevWorkplacePopupPage() {
      if (this.currentWorkplacePopupPage > 1) {
        this.selectedWorkplacesByPage[this.currentWorkplacePopupPage] = [...this.selectedWorkplacesInModal];
        this.currentWorkplacePopupPage--;
        this.isCheckedAllWorkplacePopup = false;
        this.loadWorkplaces(this.currentCompanyId);
        this.restorePageSelection();
      }
    },
    nextWorkplacePopupPageGroup() {
      this.selectedWorkplacesByPage[this.currentWorkplacePopupPage] = [...this.selectedWorkplacesInModal];
      this.isCheckedAllWorkplacePopup = false;
      this.currentWorkplacePopupPage = this.totalWorkplacePopupPages;
      this.loadWorkplaces(this.currentCompanyId);
      this.restorePageSelection();
    },
    prevWorkplacePopupPageGroup() {
      this.selectedWorkplacesByPage[this.currentWorkplacePopupPage] = [...this.selectedWorkplacesInModal];
      this.isCheckedAllWorkplacePopup = false;
      this.currentWorkplacePopupPage = 1;
      this.loadWorkplaces(this.currentCompanyId);
      this.restorePageSelection();
    },
    async searchCompany() {
      this.currentCompanyPopupPage = 1;
      this.companies = [];
      this.loadCompanies();
    },
    async searchWorkplace() {
      this.currentWorkplacePopupPage = 1;
      this.workplaces = [];
      this.loadWorkplaces(this.currentCompanyId);
    },
    selectCompany(company) {
      const companyIndex = this.selectedCompanies.findIndex(
        (selectedCompany) => selectedCompany.id === company.id
      );

      if (companyIndex === -1) {
        this.selectedCompanies.push({
          ...company,
          workplaces: [],
          allWorkplacesChecked: false,
        });
      }

      this.hideCompanyModal();
    },
    removeCompany(index) {
      this.selectedCompanies.splice(index, 1);
    },    
    selectWorkplace(workplace) {
      const companyIndex = this.selectedCompanies.findIndex(
        (company) => company.id === this.currentCompanyId
      );

      if (companyIndex !== -1) {
        if (!Array.isArray(this.selectedCompanies[companyIndex].workplaces)) {
          this.selectedCompanies[companyIndex].workplaces = [];
        }

        if (
          !this.selectedCompanies[companyIndex].workplaces.some(
            (w) => w.workplaceId === workplace.workplaceId
          )
        ) {          
          this.selectedCompanies[companyIndex].workplaces.push({
            workplaceId: workplace.workplaceId,
            workplaceName: workplace.workplaceName,
          });
        }
      }

      this.hideWorkplaceModal();
    },
    removeWorkplace(companyIndex, workplaceIndex) {
      const confirmed = confirm(`삭제하시겠습니까?`);
      if (confirmed) {
        this.selectedCompanies[companyIndex].workplaces.splice(
          workplaceIndex,
          1
        );
      }
    },
    toggleSelectAllCompany(event) {
      const page = this.currentCompanyPopupPage;
      
      if (event.target.checked) {
        this.isCheckedAllCompanyPopup = true;
        const companies = this.paginatedCompanies;
        this.selectedCompaniesInModal = [...this.selectedCompaniesInModal, ...companies];
        this.selectedCompaniesInModal = [...new Map(this.selectedCompaniesInModal.map(company => [company.id, company])).values()];
        this.selectedCompaniesByPage[page] = companies;
      } else {
        const companyIds = this.paginatedCompanies.map(company => company.id);        
        this.selectedCompaniesInModal = this.selectedCompaniesInModal.filter(
          company => !companyIds.includes(company.id)
        );
        this.selectedCompaniesByPage[page] = [];
      }
    },
    toggleSelectAllWorkplaces(event) {
      const page = this.currentWorkplacePopupPage;
      const workplaces = this.paginatedWorkplaces;
            
      if (event.target.checked) {
        this.isCheckedAllWorkplacePopup = true;
        const workplaceIdsSet = new Set(this.selectedWorkplacesInModal.map(workplace => workplace.id));

        workplaces.forEach(workplace => {
          if (!workplaceIdsSet.has(workplace.workplaceId)) {
            this.selectedWorkplacesInModal.push(workplace);
          }
        });    

        this.selectedWorkplacesByPage[page] = [...this.paginatedWorkplaces];
      } else {
        this.isCheckedAllWorkplacePopup = false;
        const workplaceIds = this.paginatedWorkplaces.map(workplace => workplace.workplaceId);  
        this.selectedWorkplacesInModal = this.selectedWorkplacesInModal.filter(
          workplace => !workplaceIds.includes(workplace.workplaceId)
        );

        this.selectedWorkplacesByPage[page] = [];
      }

      this.selectedWorkplacesInModal = Object.values(this.selectedWorkplacesByPage).flat();
    },
    toggleSelectCompany(company) {
      const index = this.selectedCompaniesInModal.findIndex(c => c.id === company.id);
      if (index === -1) {
        this.selectedCompaniesInModal.push(company);
      } else {
        this.selectedCompaniesInModal.splice(index, 1);
      }

      this.selectedCompaniesByPage[this.currentCompanyPopupPage] = [...this.selectedCompaniesInModal];
      this.updateSelectAllCompanyPopup();
    },
    toggleSelectWorkplace(workplace) {
      const index = this.selectedWorkplacesInModal.findIndex(w => w.workplaceId  === workplace.workplaceId);
      if (index === -1) {
        this.selectedWorkplacesInModal.push(workplace);
      } else {
        this.selectedWorkplacesInModal.splice(index, 1);
      }

      this.selectedWorkplacesByPage[this.currentWorkplacePopupPage] = [...this.selectedWorkplacesInModal];
      this.updateSelectAllWorkplacePopup();
    },
    updateSelectAllCompanyPopup() {
      const companyIdsOnCurrentPage = this.paginatedCompanies.map(company => company.id);
      const selectedCompanyIdsOnPage = this.selectedCompaniesInModal
        .filter(company => companyIdsOnCurrentPage.includes(company.id))
        .map(company => company.id);
      this.isCheckedAllCompanyPopup = selectedCompanyIdsOnPage.length > 0 && companyIdsOnCurrentPage.length === selectedCompanyIdsOnPage.length;
    },
    updateSelectAllWorkplacePopup() {      
      const workplaceIdsOnCurrentPage = this.paginatedWorkplaces.map(workplace => workplace.workplaceId);      
      const allSelected = workplaceIdsOnCurrentPage.every(workplaceId =>
        this.selectedWorkplacesInModal.some(workplace => workplace.workplaceId === workplaceId)
      );
      this.isCheckedAllWorkplacePopup = workplaceIdsOnCurrentPage.length > 0 && allSelected;
    },
    async loadCompanies() {
      try {
        const searchParam = {
          SearchType: this.SearchCompanyPopupType,
          SearchKey: this.SearchCompanyPopupKey,
          OrderType: this.OrderType,
          PageSize: this.itemsCompanyPopupPerPage,
          Page: this.currentCompanyPopupPage,
        };

        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);          
          this.companies = Array.isArray(data.data) ? data.data : [];
          this.totalCompanys = data.meta.totalCount || 0;
          const selectedForCurrentPage = this.selectedCompaniesByPage[this.currentCompanyPopupPage] || [];
          this.selectedCompaniesInModal = selectedForCurrentPage;
          this.isCheckedAllCompanyPopup = this.paginatedCompanies.length > 0 &&
          this.paginatedCompanies.every(company => selectedForCurrentPage.includes(company.id));
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    async loadWorkplaces(companyId = this.currentCompanyId) {
      this.currentCompanyId = companyId;
      
      try {
        const searchParam = {
          SearchType: this.SearchWorkplacePopupType,
          SearchKey: this.SearchWorkplacePopupKey,
          SearchActiveStatus: this.SearchActiveStatus,
          OrderType: this.OrderType,
          PageSize: this.itemsWorkplacePopupPerPage,
          Page: this.currentWorkplacePopupPage,
          CompanyId: this.currentCompanyId,
        };
        
        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          this.totalWorkplaces = data.meta.totalCount || 0;
          this.isWorkplaceModalVisible = true;
        }
      } catch (error) {
        console.error("Error loading workplaces:", error);
      }
    },
    async saveNotice() {
      if (this.checkFields()) {
        const formattedData = {
          noticeId: this.id,
          userType: this.userType,
          postType: this.postType,
          fixType: this.fixType,
          target: this.companyworkplaceSelect,
          allCompanyChecked: this.allCompaniesChecked === true ? 'Y' : 'N',
          title: this.noticeTitle,
          content: this.noticeContent,
          companies: this.selectedCompanies.map((company) => ({
            id: company.id,
            workplaceIds: company.workplaces.map((wp) => wp.workplaceId),
            allWorkplacesChecked: company.allWorkplacesChecked,
          })),
          loginUserId: localStorage.getItem("userid")
        };
        
        try {
          const response = await apiClient.post("/api/Notice/notice-edit", formattedData);

          if (response.status === 200) {
            this.toast.success("공지사항이 성공적으로 저장되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToNoticeList();
          }
        } catch (error) {
          console.error("Error saving notice:", error);
          this.toast.error("공지사항 저장 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (this.companyworkplaceSelect === "D") {
        if (this.selectedCompanies.length === 0) {
          this.toast.error("회사를 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.handleCompanySelection();
          return;
        }
        
        const companyWithoutWorkplaces = this.selectedCompanies.some((company) => {
          const workplaces = company.workplaces || [];
          return !company.allWorkplacesChecked && workplaces.length === 0;
        });
        
        const companiesWithoutWorkplaceIds = this.selectedCompanies.filter((company) => {
            const workplaces = company.workplaces || [];
            return !company.allWorkplacesChecked && workplaces.length === 0;
          }).map((company) => company.id);
        
        if (companyWithoutWorkplaces) {	
          this.toast.error("사업장이 선택되지 않은 회사가 존재합니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.showWorkplaceModal(companiesWithoutWorkplaceIds[0]);
          return;
        }
      }
      if (!this.noticeTitle) {
		    this.toast.error("제목을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.noticeTitle.focus();
        return false;
      }

      if (!this.noticeContent) {
		    this.toast.error("내용을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$refs.noticeContent.focus();
        return false;
      }

      return true;
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.loadCompanies(); 
    this.fetchNoticeData();
    this.fetchNoticeCompanyWorkplaceData();
  },
}; 
</script>

<style scoped></style>
